import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { memo, useCallback, useMemo, useState } from "react";
import { CellComponent, ColumnDefinition } from "tabulator-tables";
import {
  formatTaxonPrefixingParent,
  getTaxonById,
} from "../../../../../../../../../api/compute/Taxon";
import { decodePeerId } from "../../../../../../../../../api/utils";
import {
  TrendratingTable,
  reactFormatter,
} from "../../../../../../../../../components/table/TrendratingTable";
import { useFormatter } from "../../../../../../../../../hooks/useFormatter";
import { useTaxonomyByType } from "../../../../../../../../../hooks/useTaxonomyByType";
import styles from "../InstrumentsTableForAnalytics/InstrumentTableForAnalytics.module.scss";
import { turnSegmentInLabel } from "../MarketsETFTableTCR/MarketsETFTableTCR";

type DispersionByTableProps = {
  data: any;
  segment:
    | "WWW"
    | "1 Industry"
    | "3 Sector"
    | "Area"
    | "Region"
    | "Country"
    | "4 Subsector";
  percentage: 25 | 10 | 5;
  navigateDownPeer: (peerId: string) => void;
  onTableSort: (value: { dir: "desc" | "asc"; field: string }[]) => void;
  type: "Stock" | "ETF";
};

type CustomNameCellProps = {
  cell?: CellComponent;
  customCellEventHandler?: Function;
  segment:
    | "WWW"
    | "1 Industry"
    | "3 Sector"
    | "Area"
    | "Region"
    | "Country"
    | "4 Subsector";
  taxonomiesMapX: any;
  taxonomiesMapY: any;
  type: "Stock" | "ETF";
  rootNodeX: any;
  rootNodeY: any;
};

const fromSegmentToTitle = (
  segment:
    | "WWW"
    | "1 Industry"
    | "3 Sector"
    | "Area"
    | "Region"
    | "Country"
    | "3 Level"
    | "4 Subsector",
  type: "ETF" | "Stock"
) => {
  const dict = {
    WWW: { ETF: "World Wide", Stock: "World Wide" },
    "1 Industry": { ETF: "Asset CLass", Stock: "All Sectors" },
    "3 Sector": { ETF: "Specialties", Stock: "All Industries" },
    "4 Subsector": { ETF: "Theme", Stock: "All Sub-Industries" },
    Area: { ETF: "Areas", Stock: "Areas" },
    Region: { ETF: "Regions", Stock: "Regions" },
    Country: { ETF: "Markets", Stock: "Markets" },
    "3 Level": { ETF: "Size", Stock: "All Sizes" },
  };

  const label = dict?.[segment]?.[type];

  return label ?? segment;
};

const intervalTitle = {
  25: {
    top: "Top 25%",
    mid: "Mid 50%",
    bottom: "Bottom 25%",
  },
  10: {
    top: "Top 10%",
    mid: "Mid 80%",
    bottom: "Bottom 10%",
  },
  5: {
    top: "Top 5%",
    mid: "Mid 90%",
    bottom: "Bottom 5%",
  },
};

const formatCells = (
  cell: CellComponent,
  range: "top" | "middle" | "bottom",
  formatterFunction
) => {
  const data = cell.getData();
  const avg = data?.["dispersion"]?.[range]?.["average"] ?? null;

  const result = formatterFunction.custom("number", {
    options: {
      isPercentage: true,
      notAvailable: {
        input: null,
        output: "-",
      },
    },
    output: "HTML",
    value: avg,
    valueHelper: null,
  });

  return result;
};

const percentageSorter = (
  a,
  b,
  aRow,
  bRow,
  property: "top" | "middle" | "bottom"
) => {
  const aData = aRow.getData();
  const bData = bRow.getData();

  if (
    aData?.dispersion?.[property]?.average >
    bData?.dispersion?.[property]?.average
  ) {
    return 1;
  } else if (
    aData?.dispersion?.[property]?.average <
    bData?.dispersion?.[property]?.average
  ) {
    return -1;
  }

  return 0;
};

const formatName = (
  cell: CellComponent,
  segment,
  taxonomiesMapX,
  taxonomiesMapY,
  rootNodeX,
  rootNodeY,
  type: "ETF" | "Stock"
) => {
  const data = cell.getData();
  let name = (data as any).name;

  if (name === "__ROOT__") {
    // if getRow property is in cell component it means that cell is of type CellComponent otherwise is of type RowComponent
    const row = "getRow" in cell ? cell?.getRow() : cell;
    const rowHtml = row?.getElement();
    rowHtml!.style.fontSize = "1.2em";
    rowHtml!.style.fontWeight = "bold";
    rowHtml!.style.backgroundColor = "rgba(255, 192, 1, 0.2)";

    return `${fromSegmentToTitle(segment, type)}`;
  }

  if ("peer" in data) {
    const peer = decodePeerId((data as any).peer.id);

    switch (segment) {
      case "1 Industry":
      case "3 Sector":
      case "4 Subsector": {
        const nameTag = peer.what;
        const taxonomyName =
          taxonomiesMapY?.[nameTag]?.["name"] ?? nameTag ?? "";

        if (nameTag === rootNodeY) {
          const row = "getRow" in cell ? cell?.getRow() : cell;
          const rowHtml = row?.getElement();

          if (rowHtml) {
            rowHtml!.style.fontSize = "1.2em";
            rowHtml!.style.fontWeight = "bold";
            rowHtml!.style.backgroundColor = "rgba(255, 192, 1, 0.2)";
          }

          return `All ${turnSegmentInLabel(segment, type)}`;
        }

        if (type === "ETF") {
          if (segment === "1 Industry" || segment === "3 Sector") {
            name =
              segment === "1 Industry"
                ? taxonomyName
                : formatTaxonPrefixingParent(
                    getTaxonById(nameTag, [taxonomiesMapY], 0),
                    [taxonomiesMapY],
                    "3 Sector"
                  );
          } else if (segment === "4 Subsector") {
            name = formatTaxonPrefixingParent(
              getTaxonById(nameTag, [taxonomiesMapY], 0),
              [taxonomiesMapY],
              "4 Subsector"
            );
          }
        } else {
          return taxonomyName;
        }

        break;
      }

      case "Area":
      case "Country":
      case "Region":
      case "WWW":
        const nameTag = peer.where;
        const taxonomyName = taxonomiesMapX?.[nameTag]?.["name"];

        if (taxonomyName === rootNodeX) {
          const row = "getRow" in cell ? cell?.getRow() : cell;
          const rowHtml = row?.getElement();
          rowHtml!.style.fontSize = "1.2em";
          rowHtml!.style.fontWeight = "bold";
          rowHtml!.style.backgroundColor = "rgba(255, 192, 1, 0.2)";
          return `All ${turnSegmentInLabel(segment, type)}`;
        }

        name =
          segment === "Region"
            ? formatTaxonPrefixingParent(
                getTaxonById(nameTag, [taxonomiesMapX], 0),
                [taxonomiesMapX],
                "Region"
              )
            : taxonomyName;

        break;

      case "3 Level":
        const dict = {
          microLarge: "All Sizes",
          micro: "Micro",
          small: "Small",
          mid: "Mid",
          large: "Large",
        };

        name = dict?.[peer.zDimension] ?? peer.zDimension ?? "";

        if (name === dict.microLarge) {
          const row = "getRow" in cell ? cell?.getRow() : cell;
          const rowHtml = row?.getElement();
          rowHtml!.style.fontSize = "1.2em";
          rowHtml!.style.fontWeight = "bold";
          rowHtml!.style.backgroundColor = "rgba(255, 192, 1, 0.2)";
        }

        break;
    }

    return name;
  }

  return "";
};

const DispersionByTable = memo(
  ({
    data,
    segment,
    percentage,
    navigateDownPeer,
    onTableSort,
    type,
  }: DispersionByTableProps) => {
    const formatter = useFormatter();
    const { taxonomiesMapY, taxonomiesMapX, rootNodeX, rootNodeY } =
      useTaxonomyByType(type === "ETF" ? "ETF" : "stock");

    const columns: ColumnDefinition[] = [
      {
        title: fromSegmentToTitle(segment, type),
        field: "name",
        formatter: reactFormatter(
          <CustomNameCell
            segment={segment}
            type={type}
            customCellEventHandler={navigateDownPeer}
            taxonomiesMapX={taxonomiesMapX}
            taxonomiesMapY={taxonomiesMapY}
            rootNodeX={rootNodeX}
            rootNodeY={rootNodeY}
          />
        ),
        widthGrow: 3,
      },
      {
        title: intervalTitle[percentage]["top"],
        field: "top",
        headerHozAlign: "center",
        hozAlign: "center",
        formatter: (cell) => formatCells(cell, "top", formatter),
        sorter: (a, b, aRow, bRow) => percentageSorter(a, b, aRow, bRow, "top"),
        widthGrow: 1,
      },
      {
        title: intervalTitle[percentage]["mid"],
        field: "middle",
        headerHozAlign: "center",
        hozAlign: "center",
        formatter: (cell) => formatCells(cell, "middle", formatter),
        sorter: (a, b, aRow, bRow) =>
          percentageSorter(a, b, aRow, bRow, "middle"),
        widthGrow: 1,
      },
      {
        title: intervalTitle[percentage]["bottom"],
        field: "bottom",
        headerHozAlign: "center",
        hozAlign: "center",
        formatter: (cell) => formatCells(cell, "bottom", formatter),
        sorter: (a, b, aRow, bRow) =>
          percentageSorter(a, b, aRow, bRow, "bottom"),
        widthGrow: 1,
      },
    ];

    const tableEventsGateway = useCallback(
      (event) => {
        if (event.type === "dataSorted") {
          const sorter = event.value.sorters;
          const sorterPropertiesList = sorter.map((element) => ({
            dir: element.dir,
            field: element.field,
          }));
          onTableSort(sorterPropertiesList);
        }
      },
      [onTableSort]
    );

    const dataSorted = useMemo(() => {
      const sorted = data;
      sorted.sort((a, b) => (a.name > b.name ? 1 : -1));
      return sorted;
    }, [data]);

    return (
      <div style={{ flex: 1, minHeight: 0 }}>
        <TrendratingTable
          disableDefaultRowClick
          data={dataSorted}
          columns={columns}
          options={{
            selectableRows: false,
            ajaxSorting: false,
          }}
          autoResize={false}
          eventCallback={tableEventsGateway}
        />
      </div>
    );
  }
);

export default DispersionByTable;

const CustomNameCell = ({
  cell,
  customCellEventHandler,
  segment,
  taxonomiesMapX,
  taxonomiesMapY,
  rootNodeX,
  rootNodeY,
  type,
}: CustomNameCellProps) => {
  const data = useMemo(() => cell?.getData(), [cell]);
  const name = useMemo(
    () =>
      formatName(
        cell as any,
        segment,
        taxonomiesMapX,
        taxonomiesMapY,
        rootNodeX,
        rootNodeY,
        type
      ),
    [cell, rootNodeX, rootNodeY, segment, taxonomiesMapX, taxonomiesMapY, type]
  );
  const [showIcon, setShowIcon] = useState(false);

  return (
    <div
      style={{ display: "flex" }}
      onMouseEnter={() => setShowIcon(true)}
      onMouseLeave={() => setShowIcon(false)}
    >
      {name}

      <div className={styles.custom__cell__icon}>
        {showIcon && (
          <div
            className={styles.custom__cell__icon__background}
            onClick={() =>
              customCellEventHandler && customCellEventHandler(data)
            }
          >
            <KeyboardDoubleArrowDownIcon
              className={styles.custom__cell__icon__background__icon}
            />
          </div>
        )}
      </div>
    </div>
  );
};
