import { Box, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo } from "react";
import { useImmer } from "use-immer";
import Modal from "../../../../../components/Modal/Modal";
import { useEventBus } from "../../../../../hooks/useEventBus";
import styles from "./DialogSaveComponent.module.scss";

type DialogSaveComponentProps = {
  item: { name: string };
  style?: any;
  dialogType: string;
  onSave: Function | null;
  onSaveAs: Function | null;
  onRename: Function | null;
  errorFeedback?: Error;
  reset?: any;
  hide: Function;
  message?: string;
  title?: string;
  handleError?: (err, setDialogMessage) => void;
  children?: JSX.Element | JSX.Element[];
};

type ComponentLayout = {
  saveField: null | boolean;
  saveAsField: null | boolean;
  renameField: null | boolean;
};

export function DialogSaveComponent({
  item,
  dialogType,
  onSave,
  onSaveAs,
  onRename,
  errorFeedback,
  reset,
  hide,
  message,
  title,
  handleError,
  children,
}: DialogSaveComponentProps) {
  const panelDefaultState = useMemo(
    () => ({
      save: false,
      saveAs: { isOpen: false, isDisabled: false },
      rename: { isOpen: false, isDisabled: false },
    }),
    []
  );
  const layout: ComponentLayout = {
    saveField: null,
    saveAsField: null,
    renameField: null,
  };

  const [componentLayout, updateComponentLayout] = useImmer(layout);
  const [panelState, updatePanelState] = useImmer(panelDefaultState);
  const [error, updateError] = useImmer("");
  const [renameInput, updateRenameInput] = useImmer("");
  const [saveAsInput, updateSaveAsInput] = useImmer("");
  const { dispatch } = useEventBus();

  const handleErrors = useCallback(
    (error) => {
      const updateMessage = (message) =>
        updateError((draft) => (draft = message));
      if (handleError) {
        handleError(error, updateMessage);
      }
    },
    [handleError, updateError]
  );

  const isSingleActionDialog = () => {
    let actions: boolean[] = [];

    if (onSave != null) {
      actions.push(true);
    }

    if (onSaveAs != null) {
      actions.push(true);
    }

    if (onRename != null) {
      actions.push(true);
    }

    if (actions.length === 1) {
      return true;
    } else {
      return false;
    }
  };

  const clearInput = useCallback(
    (input: "rename" | "saveAs") => {
      if (input === "rename") {
        updateRenameInput("");
      } else if (input === "saveAs") {
        updateSaveAsInput("");
      }
    },
    [updateRenameInput, updateSaveAsInput]
  );

  const panelHandler = (
    panel: "saveAs" | "rename",
    action: "open" | "close"
  ) => {
    if (action === "open") {
      if (panel === "saveAs") {
        updatePanelState((draft) => {
          draft.save = true;
          draft.rename = { isOpen: false, isDisabled: true };
          draft.saveAs = { isOpen: true, isDisabled: false };
        });
      } else if (panel === "rename") {
        updatePanelState((draft) => {
          draft.save = true;
          draft.saveAs = { isOpen: false, isDisabled: true };
          draft.rename = { isOpen: true, isDisabled: false };
        });
      }
    } else if (action === "close") {
      if (panel === "saveAs") {
        updatePanelState((draft) => {
          draft.save = false;
          draft.rename = { isOpen: false, isDisabled: false };
          draft.saveAs = { isOpen: false, isDisabled: false };
        });
      } else if (panel === "rename") {
        updatePanelState((draft) => {
          draft.save = false;
          draft.saveAs = { isOpen: false, isDisabled: false };
          draft.rename = { isOpen: false, isDisabled: false };
        });
      }
    }
  };

  const resetPanelState = useCallback(() => {
    updatePanelState((draft) => (draft = panelDefaultState));
    clearInput("rename");
    clearInput("saveAs");
  }, [clearInput, panelDefaultState, updatePanelState]);

  useEffect(() => {
    if (reset !== undefined) {
      resetPanelState();
    }

    if (onSave != null) {
      updateComponentLayout((draft) => {
        draft.saveField = true;
      });
    }

    if (onSaveAs != null) {
      // If the item to save is a new one the only action that is possible is the save as
      // so we show the panel already open.

      updateComponentLayout((draft) => {
        draft.saveAsField = true;
      });
      if (!onSave && !onRename) {
        updatePanelState((draft) => {
          draft.save = true;
          draft.rename = { isOpen: false, isDisabled: true };
          draft.saveAs = { isOpen: true, isDisabled: false };
        });
      }
    }

    if (onRename != null) {
      // If the item to save is a new one the only action that is possible is the save as
      // so we show the panel already open.
      updateComponentLayout((draft) => {
        draft.renameField = true;
      });
      if (!onSave && !onSaveAs) {
        updatePanelState((draft) => {
          draft.save = true;
          draft.saveAs = { isOpen: false, isDisabled: true };
          draft.rename = { isOpen: true, isDisabled: false };
        });
      }
    }
  }, [
    onRename,
    onSave,
    onSaveAs,
    reset,
    resetPanelState,
    updateComponentLayout,
    updatePanelState,
  ]);

  useEffect(() => {
    updateError((draft) => (draft = errorFeedback?.message ?? ""));
  }, [errorFeedback, updateError]);

  const inputOnchangeHandler = (event, input: "saveAs" | "rename") => {
    if (input === "saveAs") {
      updateSaveAsInput((draft) => (draft = event.target.value));
    } else if (input === "rename") {
      updateRenameInput((draft) => (draft = event.target.value));
    }
  };

  const isFieldValid = (value: string) => {
    let isValid = false;
    // eslint-disable-next-line no-useless-escape
    var regex = /^[a-zA-Z0-9&\-\s]+$/;

    if (value.length >= 3) {
      isValid = true;
      updateError((draft) => (draft = ""));
    } else if (value === item.name) {
      updateError(
        (draft) => (draft = "The name is the same as the previous one.")
      );
    } else if (value.length < 3) {
      updateError(
        (draft) => (draft = "The name must contain at least 3 characters.")
      );
    }

    if (!regex.test(value)) {
      updateError(
        (draft) => (draft = "The name contains not allowed characters")
      );
      isValid = false;
    }

    return isValid;
  };

  const onConfirmSave = async () => {
    if (onSave !== null) {
      try {
        await onSave();
        dispatch("resource-saved");
      } catch (error) {
        handleErrors(error);
      }
    }
  };

  const onConfirmSaveAs = async () => {
    if (onSaveAs != null && isFieldValid(saveAsInput)) {
      try {
        await onSaveAs(saveAsInput);
        dispatch("resource-created");
      } catch (error) {
        handleErrors(error);
      }
    }
  };

  const onConfirmRename = async () => {
    if (onRename != null && isFieldValid(renameInput)) {
      try {
        await onRename(renameInput);
        dispatch("resource-saved");
      } catch (error) {
        handleErrors(error);
      }
    }
  };

  const closePanelHandler = (panelToClose: "saveAs" | "rename") => {
    if (isSingleActionDialog()) {
      onCloseDialog();
    }
    panelHandler(panelToClose, "close");
  };

  const onClickSaveAs = () => {
    panelHandler("saveAs", "open");
  };

  const onClickRename = () => {
    panelHandler("rename", "open");
  };

  const onCloseDialog = () => {
    hide();
    resetPanelState();
  };

  return (
    <Modal
      closeIcon={false}
      onClose={() => hide()}
      closeOnClickAway
      headerConfig={{
        hasBackground: true,
        headerContent: title ?? dialogType,
      }}
      buttonsEnalbed
      buttons={[
        {
          name: "Cancel",
          variant: "cancel",
          callback: () => hide(),
        },
      ]}
    >
      <div className="menu menu--dialog save__dialog__wrapper">
        <Box display={"flex"} flexDirection={"column"} gap={1}>
          {error!.length ? (
            <Typography sx={{ color: "red" }}>{error}</Typography>
          ) : null}
          {message && <Typography>{message}</Typography>}
        </Box>
        {children}
        {componentLayout.saveField != null ? (
          <>
            <div className={`menu__item ${panelState.save && "item-disabled"}`}>
              <div className="menu__item__item-wrapper">
                <button
                  className={styles.dialogPrimaryBtn}
                  onClick={onConfirmSave}
                >
                  Save
                </button>
                <p>{`Save ${item.name}`}</p>
              </div>
            </div>
            <div className="menu__separator shorter__separator"></div>
          </>
        ) : null}
        {componentLayout.saveAsField != null ? (
          <>
            <div
              className={`menu__item ${
                panelState.saveAs.isDisabled && "item-disabled"
              }`}
            >
              {!panelState.saveAs.isOpen ? (
                <div className="menu__item__item-wrapper">
                  <button
                    className={styles.dialogSecondaryBtn}
                    id="title-button"
                    onClick={onClickSaveAs}
                  >
                    Save as
                  </button>
                  <p>{`Save as new ${dialogType}`}</p>
                </div>
              ) : (
                <div className=" menu__item__input__wrapper" id="save-as">
                  <p>{`Save as new ${dialogType}`}</p>
                  <input
                    value={saveAsInput}
                    placeholder="Insert Name"
                    className="form__field-text"
                    data-dojo-type="dijit/form/TextBox"
                    type="text"
                    autoFocus={true}
                    onChange={(event) => inputOnchangeHandler(event, "saveAs")}
                  />
                  <Box display={"flex"}>
                    <button className={styles.button} onClick={onConfirmSaveAs}>
                      Save
                    </button>
                    <button
                      className={styles.buttonClose}
                      onClick={() => closePanelHandler("saveAs")}
                    >
                      Cancel
                    </button>
                  </Box>
                </div>
              )}
            </div>
          </>
        ) : null}
        {componentLayout.saveAsField && componentLayout.renameField ? (
          <div className="menu__separator shorter__separator"></div>
        ) : null}
        {componentLayout.renameField != null ? (
          <>
            <div
              className={`menu__item ${
                panelState.rename.isDisabled && "item-disabled"
              }`}
            >
              {!panelState.rename.isOpen ? (
                <div className="menu__item__item-wrapper">
                  <button
                    id="title-button"
                    className={styles.dialogSecondaryBtn}
                    onClick={onClickRename}
                  >
                    Rename
                  </button>
                  <p> Rename {item.name}</p>
                </div>
              ) : (
                <div className=" menu__item__input__wrapper" id="rename">
                  <p>Rename {item.name}</p>
                  <input
                    value={renameInput}
                    placeholder="Insert Name"
                    className="form__field-text"
                    onChange={(event) => inputOnchangeHandler(event, "rename")}
                    type="text"
                    autoFocus={true}
                  />
                  <Box display={"flex"}>
                    <button className={styles.button} onClick={onConfirmRename}>
                      Save
                    </button>
                    <button
                      className={styles.buttonClose}
                      onClick={() => closePanelHandler("rename")}
                    >
                      Cancel
                    </button>
                  </Box>
                </div>
              )}
            </div>
          </>
        ) : null}
      </div>
    </Modal>
  );
}
