import { Box } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Strategies } from "../../api/compute/Strategies";
import { useEnvironment } from "../../hooks/useEnvironment";
import { Strategy } from "../../types/Api";
import Modal from "../Modal/Modal";
import { StrategySummary } from "./StrategySummary";

type StrategySummaryModalProps = {
  strategyId?: number;
  onClose: () => void;
};

export function StrategySummaryModal({
  strategyId,
  onClose,
}: StrategySummaryModalProps) {
  const environment = useEnvironment();
  const appSetup = useMemo(() => environment.get("setup"), [environment]);
  const strategiesAPI = useMemo(() => new Strategies(appSetup), [appSetup]);

  const [strategy, setStrategy] = useState<Strategy>();

  const getStrategy = useCallback(async () => {
    const strategy = await strategiesAPI.getById(strategyId?.toString());
    setStrategy(strategy);
  }, [strategiesAPI, strategyId]);

  useEffect(() => {
    if (strategyId) {
      getStrategy();
    }
  }, [getStrategy, strategyId]);

  const modalTitle = useMemo(() => {
    if (strategy) {
      return (
        <Box display={"flex"} gap={1} alignItems={"center"}>
          {strategy.name}
          {strategy.isReadOnly && (
            <span className="sharedObjectIndicator sharedObjectIndicator--small"></span>
          )}
        </Box>
      );
    }

    return <></>;
  }, [strategy]);

  const onCloseDialog = useCallback(() => {
    setStrategy(undefined);
    onClose();
  }, [onClose]);

  return strategy ? (
    <Modal
      headerConfig={{ headerContent: modalTitle, hasBackground: true }}
      onClose={onCloseDialog}
      closeIcon={false}
    >
      <StrategySummary strategy={strategy} />
    </Modal>
  ) : (
    <></>
  );
}
