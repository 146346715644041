import { Box, Card, CardContent } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Properties } from "../../../../../../../api/Properties";
import { Instruments } from "../../../../../../../api/compute/Instruments";
import { extractForDataIngestion } from "../../../../../../../api/compute/commons";
import { AlertsSelect } from "../../../../../../../components/AlertsSelect/AlertsSelect";
import { InstrumentsTable } from "../../../../../../../components/InstrumentsTable/InstrumentsTable";
import RatingCheckbox from "../../../../../../../components/RatingCheckbox/RatingCheckbox";
import { clone } from "../../../../../../../deepClone";
import { useEnvironment } from "../../../../../../../hooks/useEnvironment";
import { config } from "../../../../../config-ts";
import { PortfolioAnalyzeStorage } from "../../../../../storage/PortfolioAnalyzeStorage";
import ReportButton from "../../../../../widgets/app-infrastructure/workflowBar/actions/report/ReportButton";
import { FilterCard } from "../../../../screening/FilterBar/FilterRowContraints";
import styles from "./TabHoldings.module.scss";
import { useBroadcast } from "../../../../../../../hooks/useBroadcast";
import { Export } from "../../../../../components/app-infrastructure/workflowBar/actions/export/Export";

type TabHoldingsProps = {
  dataManager: PortfolioAnalyzeStorage;
};

type WidgetProps = {
  widgetKey: "RangeRate" | "RangeAlert";
  label: string;
  alertUrlParam?: string;
  onValueChange: (value, type: "rating" | "alerts") => void;
};

let columnsRef: any = [];
let rangesRef: any = { rating: undefined, alerts: undefined };
let itemsPerPageRef = 25;

const alertTags = {
  "today-upgrades": "upgrades_today",
  "last-week-upgrades": "upgrades_last_5_days",
  "last-month-upgrades": "upgrades_last_20_days",
  "today-downgrades": "downgrades_today",
  "last-week-downgrades": "downgrades_last_5_days",
  "last-month-downgrades": "downgrades_last_20_days",
  "movers-up": "positive_movers",
  "movers-down": "negative_movers",
};

export function TabHoldings({ dataManager }: TabHoldingsProps) {
  const [sorter, setSorter] = useState({ field: "marketcap", rev: true });
  const [holdings, setHoldings] = useState([]);
  const [dataTotalCount, setDataTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentFilters, setCurrentFilters] = useState<{
    rating: any;
    alerts: any;
  }>({ rating: undefined, alerts: undefined });
  const [currentColumns, setCurrentColumns] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [portfolioInfo, setPortfolioInfo] = useState<{
    name: string;
    isReadOnly: boolean;
  }>();

  const environment = useEnvironment();
  const configuration = useMemo(() => {
    const analysisList =
      environment.get("account")["product"]["configuration"]["analysis_list"][
        "overview_tabs"
      ];

    return analysisList.find((item) => item.id === "holdings");
  }, [environment]);
  const filtersConfiguration = useMemo(() => {
    const widgetConfig: any = {};

    const widgetsSetup = configuration?.widgets?.viewerFilter?.table?.[0] ?? [];

    for (const widget of widgetsSetup) {
      widgetConfig[widget.display.widget] = {
        label: widget.label,
        key: widget.display.widget,
      };
    }

    return widgetConfig;
  }, [configuration]);

  const params = useParams();
  const urlAlertParam = useMemo(() => params.alert, [params.alert]);
  const currentListId = useMemo(() => params.id, [params.id]);

  const sortField = useMemo(() => sorter.field, [sorter.field]);
  const sortDir = useMemo(
    () => (sorter.rev === false ? "desc" : "asc"),
    [sorter.rev]
  );

  const isReadOnly = useMemo(
    () => portfolioInfo?.isReadOnly ?? true,
    [portfolioInfo?.isReadOnly]
  );
  const portfolioName = useMemo(
    () => portfolioInfo?.name ?? "",
    [portfolioInfo?.name]
  );

  const instrumentsAPI = useMemo(
    () => new Instruments(environment.get("setup")),
    [environment]
  );
  const propertiesAPI = useMemo(
    () =>
      new Properties({ properties: environment.get("properties").properties }),
    [environment]
  );

  const positionsToday = useRef();

  const positions = useCallback(async () => {
    if (positionsToday.current) {
      return positionsToday.current;
    }

    if (!currentListId) {
      console.log(
        "Expecting a list Id and got:" + currentListId + " cannot retrive data"
      );

      return [];
    }

    let response = await dataManager.get("holdings");

    positionsToday.current = response.positions;
    setPortfolioInfo({ isReadOnly: response.isReadOnly, name: response.name });

    return positionsToday.current!;
  }, [currentListId, dataManager]);

  const ratingValueToPayload = useCallback((value) => {
    const segments: any = [];

    const rcMap = {
      A: 2,
      B: 1,
      C: -1,
      D: -2,
    };

    let areAllSelected = true;

    for (const key in value) {
      if (value[key] === true) {
        segments.push(rcMap[key]);
      } else {
        areAllSelected = false;
      }
    }

    if (!areAllSelected && segments.length > 0) {
      const min = Math.min(...segments);
      const max = Math.max(...segments);

      return [{ dimension: "rc", segments: [{ max, min }] }];
    }

    return undefined;
  }, []);

  const alertTagToPayload = useCallback((tag) => {
    switch (tag) {
      default:
        break;
      case "upgrades_today": {
        return [
          {
            dimension: "direction",
            segments: [{ max: 4, min: 0 }],
          },
          {
            dimension: "lr",
            segments: [{ max: 0, min: 0 }],
          },
        ];
      }

      case "upgrades_last_5_days": {
        return [
          {
            dimension: "direction",
            segments: [{ max: 4, min: 0 }],
          },
          {
            dimension: "lr",
            segments: [{ max: 4, min: 0 }],
          },
        ];
      }

      case "upgrades_last_20_days": {
        return [
          {
            dimension: "direction",
            segments: [{ max: 4, min: 0 }],
          },
          {
            dimension: "lr",
            segments: [{ max: 19, min: 0 }],
          },
        ];
      }

      case "upgrades_last_60_days": {
        return [
          {
            dimension: "direction",
            segments: [{ max: 4, min: 0 }],
          },
          {
            dimension: "lr",
            segments: [{ max: 59, min: 0 }],
          },
        ];
      }

      case "downgrades_today": {
        return [
          {
            dimension: "direction",
            segments: [{ max: 0, min: -4 }],
          },
          {
            dimension: "lr",
            segments: [{ max: 0, min: 0 }],
          },
        ];
      }

      case "downgrades_last_5_days": {
        return [
          {
            dimension: "direction",
            segments: [{ max: 0, min: -4 }],
          },
          {
            dimension: "lr",
            segments: [{ max: 4, min: 0 }],
          },
        ];
      }

      case "downgrades_last_20_days": {
        return [
          {
            dimension: "direction",
            segments: [{ max: 0, min: -4 }],
          },
          {
            dimension: "lr",
            segments: [{ max: 19, min: 0 }],
          },
        ];
      }

      case "downgrades_last_60_days": {
        return [
          {
            dimension: "direction",
            segments: [{ max: 0, min: -4 }],
          },
          {
            dimension: "lr",
            segments: [{ max: 59, min: 0 }],
          },
        ];
      }

      case "positive_movers": {
        return [
          {
            dimension: "rc",
            segments: [
              {
                max: null,
                min: 0,
              },
            ],
          },
          {
            dimension: "px",
            segments: [
              {
                max: 0,
                min: 0,
              },
            ],
          },
          {
            dimension: "lr",
            segments: [
              {
                max: null,
                min: 19,
              },
            ],
          },
        ];
      }

      case "negative_movers": {
        return [
          {
            dimension: "rc",
            segments: [
              {
                max: 0,
                min: null,
              },
            ],
          },
          {
            dimension: "px",
            segments: [
              {
                max: 0,
                min: 0,
              },
            ],
          },
          {
            dimension: "lr",
            segments: [
              {
                max: null,
                min: 19,
              },
            ],
          },
        ];
      }
    }
  }, []);

  const getHoldings = useCallback(
    async (columns, payload) => {
      setLoading(true);
      columnsRef = columns;
      setCurrentColumns(columns);

      try {
        let response: any = await instrumentsAPI.screening(payload);

        if (response) {
          setDataTotalCount(response.dataTotalCount);

          const fields: any = [];

          let backendField = "";

          for (const column of columns) {
            backendField = propertiesAPI._get(
              column.field,
              null,
              "auto",
              "backendProperty"
            );

            fields.push({ date: null, property: backendField });
          }

          response = await instrumentsAPI.fetch({
            type: "security",
            symbols: response.data,
            properties: fields,
          });

          const holdings = response?.data;

          const positionsToday: any = await positions();

          const positionIndex = positionsToday.reduce((prev, current) => {
            prev[current.symbol] = current.weight;

            return prev;
          }, {});

          for (const security of holdings) {
            security["weight"] = positionIndex[security.symbol];
          }

          setHoldings(holdings);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [instrumentsAPI, positions, propertiesAPI]
  );

  const prepareRangesPayload = useCallback(
    async (value, target: "rating" | "alerts") => {
      const positionsToday: any = await positions();

      const symbols = positionsToday.map((item) => item.symbol);

      const payload = {
        filters: [
          {
            dimension: "symbol",
            segments: symbols,
          },
        ],
        sort: [
          {
            dimension: sorter.field,
            rev: sorter.rev,
          },
        ],
        page: {
          page: 1,
          rows: itemsPerPage,
        },
      };

      const ranges: any = [];

      if (target === "rating") {
        const ratingPayload = ratingValueToPayload(value);

        if (ratingPayload != null) {
          ranges.push(...ratingPayload);
        }

        if (currentFilters.alerts != null) {
          const alertsPayload = alertTagToPayload(currentFilters.alerts);

          if (alertsPayload) {
            ranges.push(...alertsPayload);
          }
        }
      } else {
        const alertsPayload = alertTagToPayload(value);

        if (alertsPayload != null) {
          ranges.push(...alertsPayload);
        }

        if (currentFilters.rating != null) {
          const ratingPayload = ratingValueToPayload(currentFilters.rating);

          if (ratingPayload) {
            ranges.push(...ratingPayload);
          }
        }
      }

      if (ranges.length) {
        payload["ranges"] = ranges;
      }

      return payload;
    },
    [
      alertTagToPayload,
      currentFilters.alerts,
      currentFilters.rating,
      itemsPerPage,
      positions,
      ratingValueToPayload,
      sorter.field,
      sorter.rev,
    ]
  );

  const onFiltersChange = useCallback(
    async (value, type: "rating" | "alerts") => {
      setCurrentFilters((prev) => {
        const copy = clone(prev);

        copy[type] = value;

        rangesRef = copy;
        return copy;
      });

      try {
        const payload = await prepareRangesPayload(value, type);
        const requireInjection = sorter.field === "weight";

        if (requireInjection) {
          payload.sort = [{ dimension: "marketcap", rev: false }];
          const positionsToday = await positions();
          const symbolsAndWeight = extractForDataIngestion(
            positionsToday,
            "symbol",
            "weight"
          );
          var sortPropertyId = currentListId + ":weight";
          payload["injestion"] = {
            data: symbolsAndWeight,
            field: sortPropertyId,
            type: "number",
          };
          payload["sort"].splice(0, 0, {
            dimension: sortPropertyId,
            rev: sorter.rev,
          });
        }

        if (payload) {
          await getHoldings(currentColumns, payload);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [
      currentColumns,
      currentListId,
      getHoldings,
      positions,
      prepareRangesPayload,
      sorter.field,
      sorter.rev,
    ]
  );

  const getHoldingsOnLanding = useCallback(
    async (columns) => {
      try {
        const positionsToday: any = await positions();

        const symbols = positionsToday.map((item) => item.symbol);

        const payload = {
          filters: [
            {
              dimension: "symbol",
              segments: symbols,
            },
          ],
          sort: [
            {
              dimension: "marketcap",
              rev: true,
            },
          ],
          page: {
            page: 1,
            rows: itemsPerPage,
          },
        };

        if (urlAlertParam) {
          const tag = alertTags[urlAlertParam];

          if (tag) {
            payload["ranges"] = alertTagToPayload(tag);
          }
        }

        getHoldings(columns, payload);
      } catch (error) {
        console.log(error);
      }
    },
    [alertTagToPayload, getHoldings, itemsPerPage, positions, urlAlertParam]
  );

  const getCurrentFilters = useCallback(() => {
    const ranges: any = [];

    if (rangesRef.alerts != null) {
      const alertsPayload = alertTagToPayload(rangesRef.alerts);

      if (alertsPayload) {
        ranges.push(...alertsPayload);
      }
    }

    if (rangesRef.rating != null) {
      const ratingPayload = ratingValueToPayload(rangesRef.rating);

      if (ratingPayload) {
        ranges.push(...ratingPayload);
      }
    }

    return ranges;
  }, [alertTagToPayload, ratingValueToPayload]);

  const changePagination = useCallback(
    async (page, rows?) => {
      try {
        const positionsToday: any = await positions();

        const symbols = positionsToday.map((item) => item.symbol);

        const payload = {
          filters: [
            {
              dimension: "symbol",
              segments: symbols,
            },
          ],
          sort: [
            {
              dimension: sorter.field,
              rev: sorter.rev,
            },
          ],
          page: {
            page,
            rows: rows ?? itemsPerPage,
          },
        };

        const requireInjection = sorter.field === "weight";

        if (requireInjection) {
          payload.sort = [{ dimension: "marketcap", rev: false }];

          const symbolsAndWeight = extractForDataIngestion(
            positionsToday,
            "symbol",
            "weight"
          );
          var sortPropertyId = currentListId + ":weight";
          payload["injestion"] = {
            data: symbolsAndWeight,
            field: sortPropertyId,
            type: "number",
          };
          payload["sort"].splice(0, 0, {
            dimension: sortPropertyId,
            rev: sorter.rev,
          });
        }

        const ranges = getCurrentFilters();

        if (ranges && ranges.length) {
          payload["ranges"] = ranges;
        }

        getHoldings(currentColumns, payload);
      } catch (error) {
        console.log(error);
      }
    },
    [
      currentColumns,
      currentListId,
      getCurrentFilters,
      getHoldings,
      itemsPerPage,
      positions,
      sorter.field,
      sorter.rev,
    ]
  );

  const onChangeItemsPerPage = useCallback(
    async (items) => {
      setItemsPerPage(items);
      itemsPerPageRef = items;
      changePagination(1, items);
    },
    [changePagination]
  );

  const changeColumns = useCallback(
    async (columns) => {
      columnsRef = columns;
      setCurrentColumns(columns);

      try {
        const positionsToday: any = await positions();

        const symbols = positionsToday.map((item) => item.symbol);

        const payload = {
          filters: [
            {
              dimension: "symbol",
              segments: symbols,
            },
          ],
          sort: [
            {
              dimension: sorter.field,
              rev: sorter.rev,
            },
          ],
          page: {
            page: 1,
            rows: itemsPerPage,
          },
        };

        const requireInjection = sorter.field === "weight";

        if (requireInjection) {
          payload.sort = [{ dimension: "marketcap", rev: false }];

          const symbolsAndWeight = extractForDataIngestion(
            positionsToday,
            "symbol",
            "weight"
          );
          var sortPropertyId = currentListId + ":weight";
          payload["injestion"] = {
            data: symbolsAndWeight,
            field: sortPropertyId,
            type: "number",
          };
          payload["sort"].splice(0, 0, {
            dimension: sortPropertyId,
            rev: sorter.rev,
          });
        }

        const ranges = getCurrentFilters();

        if (ranges && ranges.length) {
          payload["ranges"] = ranges;
        }

        getHoldings(columns, payload);
      } catch (error) {
        console.log(error);
      }
    },
    [
      currentListId,
      getCurrentFilters,
      getHoldings,
      itemsPerPage,
      positions,
      sorter.field,
      sorter.rev,
    ]
  );

  const sort = useCallback(
    async ({ field, direction }) => {
      setSorter({ field, rev: direction === "desc" });
      const requireInjection = field === "weight";

      try {
        const positionsToday: any = await positions();

        const symbols = positionsToday.map((item) => item.symbol);

        const payload = {
          filters: [
            {
              dimension: "symbol",
              segments: symbols,
            },
          ],
          sort: [
            {
              dimension: field,
              rev: direction === "desc",
            },
          ],
          page: {
            page: 1,
            rows: itemsPerPageRef,
          },
        };

        if (requireInjection) {
          payload.sort = [{ dimension: "marketcap", rev: false }];

          const symbolsAndWeight = extractForDataIngestion(
            positionsToday,
            "symbol",
            "weight"
          );
          var sortPropertyId = currentListId + ":weight";
          payload["injestion"] = {
            data: symbolsAndWeight,
            field: sortPropertyId,
            type: "number",
          };
          payload["sort"].splice(0, 0, {
            dimension: sortPropertyId,
            rev: direction === "desc",
          });
        }

        const ranges = getCurrentFilters();

        if (ranges && ranges.length) {
          payload["ranges"] = ranges;
        }

        getHoldings(columnsRef, payload);
      } catch (error) {
        console.log(error);
      }
    },
    [currentListId, getCurrentFilters, getHoldings, positions]
  );

  const navigate = useNavigate();
  const { t } = useTranslation();

  const goToEdit = useCallback(() => {
    const uri = `/app/analysis/lists/${currentListId}/edit/`;

    // *************************** USAGE ***************************
    var usage = window.App.usage;
    var info = {
      action: "LANDING",
      actionParam: currentListId,
      function: "PORTFOLIO_EDIT",
    };
    usage.record(info);
    // *************************** USAGE ***************************

    navigate(uri);
  }, [currentListId, navigate]);

  const tableWrap = useMemo(() => {
    const get = (key: "columns" | "constraints" | "pagination" | "sortBy") => {
      switch (key) {
        case "columns":
          return currentColumns.map((col: any) => ({
            label: col.title ?? col.label ?? "",
            property: col.field,
          }));
        case "pagination":
          return { page: 1, rows: 3000 };
        case "sortBy":
          return { property: sortField, descending: sortDir === "asc" };
        case "constraints": {
          const ranges = getCurrentFilters();

          if (ranges && ranges.length) {
            return { ranges };
          }

          return {};
        }
      }
    };

    return { table: { get } };
  }, [currentColumns, getCurrentFilters, sortDir, sortField]);

  const { broadcast } = useBroadcast();

  const manageWorkflow = useCallback(() => {
    const exportFileName = portfolioName + ".csv";
    let actions: any = [];
    let action: any = null;

    if (!isReadOnly) {
      action = {
        componentJSX: (
          <li className="menu__item" onClick={goToEdit}>
            {t("Edit")}
          </li>
        ),
      };

      actions.push(action);
    }

    action = {
      componentJSX: (
        <Export
          rankingCache={undefined}
          fileName={exportFileName}
          list={{ id: parseInt(currentListId!) }}
          widgets={tableWrap}
        />
      ),
    };

    actions.push(action);

    action = {
      componentJSX: (
        <ReportButton
          page={"analysisList"}
          target={{ id: parseInt(currentListId!) }}
          rankingCache={null}
          title={portfolioName ?? ""}
          usage={window.App.usage}
          widgets={tableWrap}
        />
      ),
    };

    actions.push(action);

    var message = {
      from: "analysisList",
      content: {
        actions,
      },
    };

    broadcast(config["channels"]["workflow"]["input"], message);
  }, [
    broadcast,
    currentListId,
    goToEdit,
    isReadOnly,
    portfolioName,
    t,
    tableWrap,
  ]);

  useEffect(() => manageWorkflow(), [manageWorkflow]);

  return (
    <Box className={styles.main}>
      <Box className={styles.filterRow}>
        {"RangeRate" in filtersConfiguration && (
          <Widget
            onValueChange={onFiltersChange}
            label={filtersConfiguration?.["RangeRate"]?.label}
            widgetKey={filtersConfiguration["RangeRate"].key}
            alertUrlParam={urlAlertParam}
          />
        )}

        {"RangeAlert" in filtersConfiguration && (
          <Widget
            onValueChange={onFiltersChange}
            label={filtersConfiguration["RangeAlert"].label}
            widgetKey={filtersConfiguration["RangeAlert"].key}
            alertUrlParam={urlAlertParam}
          />
        )}
      </Box>
      <Card sx={{ height: "92%" }}>
        <CardContent sx={{ height: "100%", display: "flex" }}>
          <InstrumentsTable
            tableData={holdings}
            tableProps={{
              disableDefaultRowClick: true,
              tooltip: { actions: { info: { enabled: true } } },
              autoResize: false,
              sorting: {
                field: sortField,
                direction: sortDir,
              },
              options: {
                ajaxSorting: false,
              },
            }}
            tools={{
              configurator: {
                hasToSkipLastApplied: false,
                defaultTemplateNameBase: "DEFAULT_PORTFOLIO",
                configurations: configuration.widgets.viewer.table,
                securityType: "security",
                isSaveLastUsedConfigurationColumnsEnabled: true,
              },
              pagination: {
                dataTotalCount,
                itemsPerPage: itemsPerPage,
                changePage: changePagination,
              },
              rowsNumberSelect: {
                label: "Securities per page",
                onChangeItemsPerPage: onChangeItemsPerPage,
                initialValue: itemsPerPage,
              },
              viewAsListButton: true,
            }}
            getInitColumns={getHoldingsOnLanding}
            onColumnsChange={changeColumns}
            useAutoSort={false}
            handleSortManually={sort}
            loading={loading}
          />
        </CardContent>
      </Card>
    </Box>
  );
}

const Widget = ({
  widgetKey,
  label,
  alertUrlParam,
  onValueChange,
}: WidgetProps) => {
  const updateRating = useCallback(
    (value) => {
      onValueChange(value, "rating");
    },
    [onValueChange]
  );

  const widgets = useMemo(
    () => ({
      RangeRate: (
        <FilterCard label={label}>
          <RatingCheckbox stateGetter={updateRating} />
        </FilterCard>
      ),
      RangeAlert: (
        <AlertsWidget
          urlParamPreset={alertUrlParam}
          label={label}
          onValueChange={(value) => onValueChange(value, "alerts")}
        />
      ),
    }),
    [alertUrlParam, label, onValueChange, updateRating]
  );

  return widgets?.[widgetKey] ?? <></>;
};

const AlertsWidget = ({ label, onValueChange, urlParamPreset }) => {
  const [title, setTitle] = useState(label);

  const updateLabel = useCallback(
    (value) => {
      if (value.includes("upgrades") || value.includes("downgrades")) {
        setTitle(value.includes("upgrades") ? "Upgrades" : "Downgrades");
      } else {
        setTitle(label);
      }
    },
    [label]
  );

  const urlAlertToTag = useCallback(
    (alertStr) => {
      const tag = alertTags?.[alertStr] ?? undefined;

      if (tag) {
        updateLabel(tag);
      }

      return tag;
    },
    [updateLabel]
  );

  const initValue = useMemo(() => {
    if (urlParamPreset) {
      return urlAlertToTag(urlParamPreset);
    }

    return undefined;
  }, [urlAlertToTag, urlParamPreset]);

  const changeAlert = useCallback(
    (value) => {
      updateLabel(value);

      onValueChange(value);
    },
    [onValueChange, updateLabel]
  );

  return (
    <FilterCard label={title}>
      <AlertsSelect
        setValue={changeAlert}
        showTitle={false}
        initValue={initValue}
      />
    </FilterCard>
  );
};
