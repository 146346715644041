import {
  Box,
  Button,
  MenuItem,
  Select,
  Skeleton,
  Typography,
} from "@mui/material";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { handleTitle } from "../../../../../Utility/DocumentTitleHanlder";
import { Strategies } from "../../../../../api/compute/Strategies";
import { SystematicProducts } from "../../../../../api/compute/SystematicProducts";
import Modal from "../../../../../components/Modal/Modal";
import { useBroadcast } from "../../../../../hooks/useBroadcast";
import { useEnvironment } from "../../../../../hooks/useEnvironment";
import { useEventBus } from "../../../../../hooks/useEventBus";
import { SystematicProduct } from "../../../../../types/Api";
import { Remove } from "../../../components/app-infrastructure/workflowBar/actions/remove/Remove";
import { config } from "../../../config-ts";
import { LoadingCallbacks } from "../../../storage/CombinedStrategiesStorage";
import { Product } from "../../../storage/SystematicPortfoliosStorage";
import { messageError, messageSuccess } from "../../../utils";
import { useActionModal } from "../../../utils/useActionModal";
import ReportButton from "../../../widgets/app-infrastructure/workflowBar/actions/report/ReportButton";
import { Loader } from "../../strategies/builder/Loader";
import {
  LoaderContext,
  STRATEGIES_LOADER_EVENTS,
} from "../../strategies/builder/StrategiesBuilder";
import Result from "../../strategies/builder/editors/Advanced/Result/Result";
import { Dashboards } from "../../strategies/builder/editors/Advanced/Result/tabs/Insights/Insights";
import { SystematicPortfolioContext } from "../SystematicPortfolios";
import { ExportChartAction } from "../actions/ExportChartAction";
import { DialogEdit } from "../dialog/DialogEdit";
import styles from "./ProductPage.module.scss";

type ProductPageProps = {
  productId: string;
};

export function ProductPage({ productId }: ProductPageProps) {
  const storage = useContext(SystematicPortfolioContext);
  const { setShowModal, setModalTitle, setContent } = useActionModal();
  const [resultTab, setResultTab] = useState<any>();
  const [workflow, setWorflow] = useState("s0");
  const [product, setProduct] = useState<Product>();
  const [resultCtx, setResultCtx] = useState<any>();
  const [loadingNavigatorOpts, setLoadingNavigatorOpts] = useState(false);
  const [systematicProductMap, setSystematicProductMap] = useState<{
    [id: number]: SystematicProduct;
  }>({});
  const navigate = useNavigate();
  const environment = useEnvironment();
  const appSetup = useMemo(() => environment.get("setup"), [environment]);
  const smsAPI = useMemo(() => new SystematicProducts(appSetup), [appSetup]);
  const strategiesAPI = useMemo(() => new Strategies(appSetup), [appSetup]);

  const closeModal = useCallback(() => setShowModal(false), [setShowModal]);

  useEffect(() => {
    if (product) {
      const info = product?.info();
      handleTitle({ name: info?.name, type: info?.type });
    }
  }, [product]);

  const { broadcast } = useBroadcast();

  useEffect(() => {
    if (resultTab) {
      switch (resultTab) {
        case "productOverview": {
          setWorflow("s0");

          break;
        }
        case "chart": {
          setWorflow("s1");

          break;
        }
        case "productHoldings": {
          // No action needed but refresh the state is required to update workflow value
          // Handled by the ProductHoldings tab
          setWorflow("s7");
          break;
        }
        case "productAllocation": {
          setWorflow("s3");

          break;
        }
        case "historicalAllocation": {
          // No action needed but refresh the state is required to update workflow value
          // Handled by the Historical tab
          setWorflow("s8");

          break;
        }
        case "productKeyFacts": {
          setWorflow("s5");
        }
      }
    }
  }, [resultTab]);

  const goToRebalance = useCallback(() => {
    if (productId) {
      const url = `/app/systematic-portfolios/${productId}/rebalance/`;

      closeModal();
      navigate(url);
    }
  }, [closeModal, navigate, productId]);

  const showRebalance = useCallback(() => {
    const productInfo = product?.info();

    if (productInfo) {
      setModalTitle(`Rebalance ${productInfo.name}`);
      setContent(
        <Box>
          <Box display={"flex"} flexDirection={"column"} gap={2}>
            <Typography>
              The {productInfo.name} will rebalance the underlying portfolio{" "}
              <strong>{productInfo.historicalPortfolioName}</strong>.
            </Typography>

            <Typography sx={{ color: "red" }}>
              Before rebalancing, please verify that the portfolio holdings and
              weights are up-to-date. To update the portfolio, import it into
              the historical portfolios section.
            </Typography>
          </Box>

          <Box display={"flex"} gap={2} p={1} justifyContent={"flex-end"}>
            <Button variant="contained" onClick={goToRebalance}>
              Rebalance
            </Button>
            <Button variant="tr_button_cancel" onClick={closeModal}>
              Cancel
            </Button>
          </Box>
        </Box>
      );
      setShowModal(true);
    }
  }, [
    closeModal,
    goToRebalance,
    product,
    setContent,
    setModalTitle,
    setShowModal,
  ]);

  const getProduct = useCallback(async () => {
    let product = storage.getProduct(parseInt(productId));

    if (!product) {
      await storage.setProduct(parseInt(productId));
      product = storage.getProduct(parseInt(productId));
    }

    if (product) {
      setProduct(product);
    }
  }, [productId, storage]);

  const getProducts = useCallback(async () => {
    setLoadingNavigatorOpts(true);
    try {
      const productsList = await storage.getProducts();

      const products = await storage.smsAPI.fetch({
        ids: productsList ?? [],
        properties: ["ownerId", "name"],
      });

      if (products) {
        const productsMap = (products as any).reduce((prev, current) => {
          current["isReadOnly"] =
            environment.setup?.account.user?.id !== current["ownerId"];
          prev[current.id] = current;

          return prev;
        }, {});

        setSystematicProductMap(productsMap);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingNavigatorOpts(false);
    }
  }, [environment.setup?.account.user?.id, storage]);

  useEffect(() => {
    getProduct();

    return () => setProduct(undefined);
  }, [getProduct]);

  useEffect(() => {
    if (product) {
      const systematicPortfolio = product.info();
      const benchmark = product.getBenchmark();

      const context = {
        strategy: {
          params: {
            strategy: {
              currency: systematicPortfolio.currency,
            },
          },
          name: systematicPortfolio.name,
          currency: systematicPortfolio.currency,
        },
      };

      if (benchmark) {
        context.strategy.params.strategy["benchmark"] = {
          name: benchmark?.name ?? "",
        };

        context["strategyInstrumentBenchmark"] = {
          name: benchmark?.name ?? "",
        };
      }

      setResultCtx(context);
    }

    return () => {
      setResultCtx(undefined);
    };
  }, [product]);

  const { dispatch, on, remove } = useEventBus();

  const loadedProduct = useMemo(() => {
    const productInfo = product?.info();

    if (productInfo) {
      return productInfo.name;
    }

    return "";
  }, [product]);

  const loadProduct = useCallback(
    (id) => {
      navigate(`/app/systematic-portfolios/${id}`);
      window.location.reload();
    },
    [navigate]
  );

  const isCurrentProductReadOnly = useMemo(() => {
    if (product) {
      const productInfo = product?.info();
      const isReadOnly = productInfo?.isReadOnly;

      return isReadOnly;
    }

    return false;
  }, [product]);

  const saveAsNewProduct = useCallback(
    async (event) => {
      const newProductInfo = event.value;
      const HPOS = await product?.getHistoricalPortfolio();
      let productStrategy = await strategiesAPI.fetch(
        [newProductInfo.basic.strategyId],
        ["object"]
      );

      productStrategy = productStrategy.data?.[0]?.rows?.[0]?.object;

      const strategyGranulaty =
        productStrategy?.params?.backtesting?.reviewGranularity ?? null;

      const newProductGranularity =
        strategyGranulaty != null ? strategyGranulaty : null;

      const HPOSCopy: any = [];

      for (const POS of HPOS.POS) {
        HPOSCopy.push(POS);
      }

      let feedback = "";

      const autorebalance = newProductInfo.basic.autoRebalance;
      const benchmark = newProductInfo.basic.benchmark;
      const currency = newProductInfo.basic.currency;
      const historicalPortfolio = HPOSCopy;
      const inceptionValue = newProductInfo.priceLevel.initialValue;
      const reviewGranularity = newProductGranularity;
      const expenseRatio = newProductInfo.basic.expenseRatio;
      const strategyId = newProductInfo.basic.strategyId;
      const name = newProductInfo.basic.name;

      if (
        autorebalance != null &&
        currency != null &&
        inceptionValue != null &&
        reviewGranularity != null &&
        strategyId != null &&
        name != null &&
        name.length > 3
      ) {
        try {
          const createdProduct = await storage.smsAPI.wrapInputAndCreate(
            autorebalance,
            benchmark,
            currency,
            historicalPortfolio,
            inceptionValue,
            name,
            reviewGranularity,
            expenseRatio,
            strategyId
          );

          feedback = `<strong>${createdProduct.name}</strong> has been updated.`;
          setShowModal(false);
          storage.invalidateCache();
          const [channel, msg] = messageSuccess(feedback);
          broadcast(channel as string, msg);
          navigate(`/app/systematic-portfolios/${createdProduct.id}`);
        } catch (error) {
          console.log(error);
          feedback = `Cannot update ${newProductInfo.basic.name} due to an unknown error.`;
          setShowModal(false);

          const [channel, msg] = messageError(feedback);
          broadcast(channel as string, msg);
          navigate(`/app/systematic-portfolios`);
        }
      }
    },
    [broadcast, navigate, product, setShowModal, storage, strategiesAPI]
  );

  const productEdit = useCallback(
    async (e) => {
      const params = e.value;
      let feedback = "";

      try {
        const response = await smsAPI.update(params);
        feedback = `<strong>${response.name}</strong> has been updated.`;
        setShowModal(false);
        storage.invalidateProduct(response.id);
        storage.invalidateCache();
        const [channel, msg] = messageSuccess(feedback);
        broadcast(channel as string, msg);
        getProduct();
      } catch (error) {
        console.log(error);
        feedback = `Cannot update ${params.basic.name} due to an unknown error.`;
        setShowModal(false);
        const [channel, msg] = messageError(feedback);
        broadcast(channel as string, msg);
      }
    },
    [broadcast, getProduct, setShowModal, smsAPI, storage]
  );

  const [showProgressBar, setShowProgressBar] = useState(false);

  const progressListener = useCallback((status) => {
    setShowProgressBar(true);
  }, []);
  const progressBarInit = useCallback(
    (loaderFn: LoadingCallbacks, strategyName: string, isBenchmark = false) => {
      if (!loaderFn) {
        console.warn(
          "No loading behaviour is specified so the app not crash but cannot show the progress bar"
        );

        return;
      }

      let eventSourceId =
        strategiesAPI.generateBusId() + "_" + Math.floor(Math.random() * 1000);

      if (isBenchmark) {
        eventSourceId += "-2";
      }

      setTimeout(() =>
        strategiesAPI.status(eventSourceId, (chunk) => {
          if ("status" in chunk && chunk.status === "start") {
            loaderFn.startNew({
              min: chunk.d,
              max: chunk.de,
              point: chunk.d,
              id: eventSourceId,
              name: strategyName,
            });
          } else if (!("status" in chunk)) {
            loaderFn.update(eventSourceId, chunk.d);
          } else if ("status" in chunk && chunk.status === "stop") {
            loaderFn.complete(eventSourceId);
          }
        })
      );

      return eventSourceId;
    },
    [strategiesAPI]
  );
  const loadingBehaviours = useMemo(
    () => ({
      startNew: (obj) => dispatch(STRATEGIES_LOADER_EVENTS.newProgram, obj),
      update: (id, point) =>
        dispatch(STRATEGIES_LOADER_EVENTS.updateProgram, { id, point }),
      complete: (obj) =>
        dispatch(STRATEGIES_LOADER_EVENTS.completeProgram, obj),
    }),
    [dispatch]
  );

  const productEditBacktesting = useCallback(
    async (event) => {
      if (event.stopPropagation) {
        event.stopPropagation();
      }

      var product = event.value;
      let message = `Cannot update the backtesting of ${product.name} due to unknown error`;

      try {
        const strategy = await strategiesAPI.getById(product.strategyId);
        const eventSourceId = progressBarInit(loadingBehaviours, strategy.name);

        // strategiesAPI.status(eventSourceId, ()=>setShowModal(true));

        strategy.params.busId = eventSourceId;

        var params = {
          product: product,
          strategy: strategy,
          listeners: {
            progress: progressListener,
          },
        };
        setShowProgressBar(true);
        const result = await smsAPI.updateAllocations(params);
        message = `Backtesting of <strong>${result.name}</strong> has been updated.`;
        setShowProgressBar(false);
        storage.invalidateCache();
        storage.invalidateProduct(result.id);

        const [channel, msg] = messageSuccess(message);
        broadcast(channel as string, msg);

        getProduct();
        window.location.reload();
      } catch (error) {
        console.log(error);
        setShowModal(false);
        const [channel, msg] = messageError(message);
        broadcast(channel as string, msg);
      }
    },
    [
      broadcast,
      getProduct,
      loadingBehaviours,
      progressBarInit,
      progressListener,
      setShowModal,
      smsAPI,
      storage,
      strategiesAPI,
    ]
  );

  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showSaveAsNewDialog, setShowSaveAsNewDialog] = useState(false);

  const openEditDialog = useCallback(() => {
    setShowEditDialog(true);
  }, []);

  const openSaveAsNewDialog = useCallback(() => {
    setShowSaveAsNewDialog(true);
  }, []);

  const closeSaveAsNewDialog = useCallback(() => {
    setShowSaveAsNewDialog(false);
  }, []);

  const feedbackRemove = useCallback(
    (e) => {
      const product = e.value;

      const [channel, msg] = messageSuccess(
        `<strong>${product.name}</strong> has been deleted.`
      );
      broadcast(channel as string, msg);
      navigate("/app/systematic-portfolios");
    },
    [broadcast, navigate]
  );

  useEffect(() => {
    const actions: any = [];
    let action: any = null;

    const systematicPortfolio = product?.info();

    switch (workflow) {
      case "s0": {
        action = {
          componentJSX: (
            <li
              className={"menu__item"}
              onClick={() => navigate("/app/systematic-portfolios")}
            >
              Back to list
            </li>
          ),
        };

        actions.push(action);

        action = {
          componentJSX: (
            <ReportButton
              page={"systematicPortfolios"}
              target={systematicPortfolio}
              title={`Create a PDF report for ${systematicPortfolio?.name}`}
              usage={window.App.usage}
              additionalOnClick={() => {
                var usage = window.App.usage;
                var info = {
                  action: "REPORT",
                  actionParam: systematicPortfolio?.["id"],
                  function: "SYSTEMATIC_PORTFOLIOS",
                };
                usage.record(info);
              }}
            />
          ),
        };

        actions.push(action);

        if (!systematicPortfolio?.isReadOnly) {
          action = {
            componentJSX: (
              <li
                className={"menu__item"}
                onClick={/*showRebalance*/ goToRebalance}
              >
                Rebalance
              </li>
            ),
          };

          actions.push(action);
        }

        if (!systematicPortfolio?.isReadOnly) {
          action = {
            componentJSX: (
              <li className={"menu__item"} onClick={openEditDialog}>
                Edit
              </li>
            ),
          };

          actions.push(action);
        } else {
          action = {
            componentJSX: (
              <li className={"menu__item"} onClick={openSaveAsNewDialog}>
                Edit
              </li>
            ),
          };

          actions.push(action);
        }

        if (!systematicPortfolio?.isReadOnly) {
          action = {
            componentJSX: (
              <Remove
                item={systematicPortfolio}
                label={"Delete"}
                onRemoveDone={feedbackRemove}
              />
            ),
          };

          actions.push(action);
        }

        break;
      }

      case "s1": {
        action = {
          componentJSX: (
            <ExportChartAction
              storage={product!}
              target={systematicPortfolio!}
            />
          ),
        };

        actions.push(action);

        action = {
          componentJSX: (
            <ReportButton
              page={"systematicPortfolios"}
              target={systematicPortfolio}
              title={`Create a PDF report for ${systematicPortfolio?.name}`}
              usage={window.App.usage}
              additionalOnClick={() => {
                var usage = window.App.usage;
                var info = {
                  action: "REPORT",
                  actionParam: systematicPortfolio?.["id"],
                  function: "SYSTEMATIC_PORTFOLIOS",
                };
                usage.record(info);
              }}
            />
          ),
        };

        actions.push(action);
        // }

        break;
      }

      case "s5":
      case "s3": {
        if (!systematicPortfolio?.isReadOnly) {
          action = {
            componentJSX: (
              <li className={"menu__item"} onClick={showRebalance}>
                Rebalance
              </li>
            ),
          };

          actions.push(action);
        }

        break;
      }

      case "s4": {
        break;
      }
    }

    var message = {
      from: "systematicProducts",
      content: {
        actions: actions,
      },
    };

    broadcast(config["channels"]["workflow"]["input"], message);
  }, [
    broadcast,
    environment,
    feedbackRemove,
    goToRebalance,
    navigate,
    openEditDialog,
    openSaveAsNewDialog,
    product,
    showRebalance,
    workflow,
  ]);

  const resultTabs: any = useMemo(
    () => [
      "productOverview",
      "productChart",
      "productHoldings",
      "productAllocation",
      "historicalAllocation",
      "productKeyFacts",
    ],
    []
  );

  const emptyDashboard: Dashboards = useMemo(() => {
    return {
      V1: undefined,
      V2: undefined,
      catalog: undefined,
      status: "loading",
    };
  }, []);

  return product && resultCtx ? (
    <Box height={"97%"}>
      {showEditDialog && (
        <Modal
          hasOverlay={!showProgressBar}
          bodyCustomClass={styles.modal}
          closeIcon={false}
          customCss={{ display: showProgressBar ? "none" : "block" }}
        >
          <DialogEdit
            closeDialog={() => setShowEditDialog(false)}
            product={product?.info()}
            onDialogOk={productEdit}
            onUpdateBacktesting={productEditBacktesting}
          />
        </Modal>
      )}
      {showSaveAsNewDialog && (
        <Modal
          hasOverlay={!showProgressBar}
          bodyCustomClass={styles.modal}
          closeIcon={false}
          customCss={{ display: showProgressBar ? "none" : "block" }}
        >
          <DialogEdit
            saveAsNew
            closeDialog={closeSaveAsNewDialog}
            product={product?.info()}
            onDialogOk={saveAsNewProduct}
          />
        </Modal>
      )}
      <Box>
        <Box className={styles.navigatorWidgetBox}>
          <Typography variant="subtitle1">
            {loadedProduct}{" "}
            {isCurrentProductReadOnly ? (
              <span className="sharedObjectIndicator sharedObjectIndicator--small"></span>
            ) : (
              <></>
            )}
          </Typography>
          <Select
            size="small"
            variant="outlined"
            defaultValue={"load"}
            placeholder="Load"
            onOpen={getProducts}
          >
            <MenuItem disabled sx={{ display: "none" }} value={"load"}>
              Load
            </MenuItem>
            {loadingNavigatorOpts ? (
              <DropdownSkeleton />
            ) : (
              <Box>
                {Object.values(systematicProductMap)
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((product) => (
                    <MenuItem
                      key={uuidv4()}
                      value={product.id}
                      onClick={() => loadProduct(product.id)}
                    >
                      <Box display={"flex"} alignItems={"center"} gap={"4px"}>
                        <Typography>{product.name}</Typography>
                        {product.isReadOnly && (
                          <span className="sharedObjectIndicator sharedObjectIndicator--small"></span>
                        )}
                      </Box>
                    </MenuItem>
                  ))}
              </Box>
            )}
          </Select>
        </Box>
      </Box>
      <LoaderContext.Provider
        value={{
          availableEvents: STRATEGIES_LOADER_EVENTS,
          on,
          remove,
          dispatch,
        }}
      >
        <Result
          hideRationaleBtn={true}
          getCurrentTab={setResultTab}
          value={resultCtx}
          runManager={product}
          tabs={resultTabs}
          initialTab="productOverview"
          dashboard={emptyDashboard}
        />
        {showProgressBar && (
          <Box
            width={"100%"}
            flex={1}
            sx={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}
          >
            <Loader />
          </Box>
        )}
      </LoaderContext.Provider>
    </Box>
  ) : (
    <></>
  );
}

const DropdownSkeleton = () => {
  return (
    <div>
      <Typography component="div" variant={"caption"}>
        <Skeleton />
      </Typography>
      <Typography component="div" variant={"caption"}>
        <Skeleton />
      </Typography>
      <Typography component="div" variant={"caption"}>
        <Skeleton />
      </Typography>
      <Typography component="div" variant={"caption"}>
        <Skeleton />
      </Typography>
      <Typography component="div" variant={"caption"}>
        <Skeleton />
      </Typography>
    </div>
  );
};
