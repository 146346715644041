import CheckIcon from "@mui/icons-material/Check";
import FilterListIcon from "@mui/icons-material/FilterList";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Collapse,
  FormControlLabel,
  Radio,
  Typography,
} from "@mui/material";
import {
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ColumnComponent } from "tabulator-tables";
import { v4 as uuidv4 } from "uuid";
import { Report } from "../../../../../../../../../../api/report/Report";
import { DropdownMenu } from "../../../../../../../../../../components/DropdownMenu/DropdownMenu";
import Modal from "../../../../../../../../../../components/Modal/Modal";
import { TrendratingTable } from "../../../../../../../../../../components/table/TrendratingTable";
import { deepClone } from "../../../../../../../../../../deepClone";
import { useEnvironment } from "../../../../../../../../../../hooks/useEnvironment";
import { useFormatter } from "../../../../../../../../../../hooks/useFormatter";
import { Formatter } from "../../../../../../../../../../trendrating/utils/Formatter";
import { AppEnvironment } from "../../../../../../../../../../types/Defaults";
import { escapeEntity } from "../../../../../../../../../trendrating-report/generator/Generator";
import { themeBlueAndGrey } from "../../../../../../../../widgets/dialog/report/themeBlueAndGrey-ts";
import styles from "./Insights.module.scss";
import { DashboardV2, InsightsExtended } from "./InsightsExtended";

type TabValue = "US" | "EU" | "R_D_P";

export type Dashboards = {
  V1: Dashboard | undefined;
  V2: DashboardV2 | undefined;
  catalog: CatalogDashboard | undefined;
  status: "loading" | "error" | "success";
};

type Sorter = { field: string; rev: boolean };

export type InsightsState = {
  selectedInsightsVersion: "V1" | "V2";
  usedIn: "RISE" | "STRATEGIES";
  currentTab: "EU" | "US" | "R_D_P";
  selectedStrategy: { type: "V1" | "V2" | "CATALOG"; id: string } | undefined;
  insightsV1State: {
    multiFactorTableSorter: Sorter;
    singleFactorTableSorter: Sorter;
    trStrategiesTableSorter: Sorter;
    bestByTableSorter: Sorter;
    scrollToTargetId: string | undefined;
    bestByRadioValue: string;
    bestByActiveCheckbox: string[];
    handleRowClick: (strategyId: number) => void;
    overideStrategy?: (strategyId: number) => void;
  };
  insightsV2State: {
    activeFilters: any;
    selectedColumn: string;
    sorter: Sorter;
    hidedFields: string[];
    handleRowClick: (stategyJson, name) => void;
  };
  catalogState: {
    activeFilters: any;
    sorter: Sorter;
    handleRowClick: (strategyId: number) => void;
    overideStrategy?: (strategyId: number) => void;
  };
};

type InsightsContextType = {
  state: InsightsState;
  set: (value: InsightsState) => void;
  get: () => InsightsState;
};

type ServerAnalyticResponse = {
  [key: string]: number;
};

export type CatalogDashboard = {
  name: string;
  id: number;
  date: string;
  type: "strategy";
  priceAnalytics: {
    stats: {
      "r#null,null,FULL,null,null,FULL,H,YEARLY": ServerAnalyticResponse;
      "r#null,null,FULL,null,null,FULL,DIFF,YEARLY": ServerAnalyticResponse;
      "r#null,null,FULL,YEARLY,1,TD,H": ServerAnalyticResponse;
      "r#null,null,FULL,YEARLY,1,TD,DIFF": ServerAnalyticResponse;
      "sharpe#null,null,FULL,null,null,null,H": ServerAnalyticResponse;
      "avg_md_Y#null,null,FULL,null,null,FULL,H": ServerAnalyticResponse;
    };
  };
  hPosAnalytics: {
    stats: {
      "turnover#NULL,NULL,FULL,NULL,NULL,FULL,YEARLY": ServerAnalyticResponse;
    };
  };
  info: {
    name: string;
    id: number;
    "group-market": string;
    "group-theme": string;
    "group-size": string;
    "group-rebalance": "monthly" | "quarterly";
    "group-status": "recents" | "previous";
    "group-product": string;
    "group-longShort": "long" | "short";
    synteticId: string;
  };
}[];

type Dashboard =
  | {
      date: string;
      name: "string";
      type: string;
      priceAnalytics: { stats: any };
      info: {
        id: number;
        L1: string | null;
        L1Direction: string;
        L2: string | null;
        L2Direction: string | null;
        label: string[];
        levels: string;
        market: string;
        synteticId: string;
        benchmark: string;
      };
    }[]
  | undefined;
type InsightsType = {
  askBeforeInsightsRedirect?: boolean;
  dashboards: Dashboards;
};

export const InsightsContext = createContext<InsightsContextType>(undefined!);

const ANALYTICS_DICT = {
  year_3_perf_DIFF: "r#null,null,FULL,YEARLY,3,SOLAR,DIFF,YEARLY",
  year_3_perf_H: "r#null,null,FULL,YEARLY,3,SOLAR,H,YEARLY",
  year_10_perf_DIFF: "r#null,null,FULL,null,null,FULL,DIFF,YEARLY",
  year_10_perf_H: "r#null,null,FULL,null,null,FULL,H,YEARLY",
  ytd: "r#null,null,FULL,YEARLY,1,TD,H",
  ytd_DIFF: "r#null,null,FULL,YEARLY,1,TD,DIFF",
  sharpeRatio: "sharpe#null,null,FULL,null,null,null,H",
};

const getRawAnalytic = (value) => {
  const key = getFactor(value);

  return ANALYTICS_DICT[key];
};

const getFactor = (value) => {
  const tabValueDict = {
    "3_years_return": "year_3_perf_H",
    "10_years_return": "year_10_perf_H",
    YTD_return: "ytd",
    sharpe: "sharpeRatio",
  };

  return tabValueDict[value];
};

export const withRedirectWarnigModal = (Component) => {
  return (props) => {
    const [showModal, setShowModal] = useState(false);
    const [clickHandler, setClickHandler] = useState<any>();

    const closeInfoDialog = useCallback(() => {
      setShowModal(false);
    }, []);

    const handleClickOk = useCallback(() => {
      // If the handler is passed as prop execute it
      if (props?.onClickOk) {
        props.onClickOk(closeInfoDialog);
      } else {
        clickHandler();
        closeInfoDialog();
      }
    }, [clickHandler, closeInfoDialog, props]);

    return (
      <>
        {showModal && (
          <Modal
            closeIcon={false}
            headerConfig={{ headerContent: "Info", hasBackground: true }}
            onClose={closeInfoDialog}
          >
            <Box display={"flex"} flexDirection={"column"} gap={2}>
              <Typography>
                Are you sure you want to leave without save the current changes
                on the strategy?
              </Typography>
              <Box display={"flex"} gap={1} justifyContent={"flex-end"}>
                <Button variant="contained" onClick={handleClickOk}>
                  Yes
                </Button>
                <Button variant="tr_button_cancel" onClick={closeInfoDialog}>
                  No
                </Button>
              </Box>
            </Box>
          </Modal>
        )}
        <Component
          {...props}
          setShowModal={setShowModal}
          setModalOkClickHandler={setClickHandler}
        />
      </>
    );
  };
};

const InsightsContent = ({
  askBeforeInsightsRedirect,
  dashboard,
  setShowModal,
  tab,
  setStrategyIdToRedirect,
  setTabValue,
  tabValue,
}) => {
  const ctx = useContext(InsightsContext);
  const environment = useEnvironment();

  const getUIParams = useCallback(() => {
    const currentConfiguration = ctx.get().insightsV1State;
    return {
      tab,
      sorters: {
        two: currentConfiguration.multiFactorTableSorter,
        one: currentConfiguration.singleFactorTableSorter,
        three: currentConfiguration.trStrategiesTableSorter,
        best: currentConfiguration.bestByTableSorter,
      },
      isBestBy: tabValue === "bestBy",
      currentSubTabValue: currentConfiguration.bestByRadioValue,
      activeCheckboxes: currentConfiguration.bestByActiveCheckbox,
    };
  }, [ctx, tab, tabValue]);

  const pdf = useMemo(() => {
    const setup = environment.get("setup");

    return new PDF(dashboard, setup, getUIParams);
  }, [dashboard, environment, getUIParams]);

  const strategies = useMemo(() => {
    const strategies: {
      oneFactor: Dashboard;
      multiFactor: Dashboard;
      trStrategies: Dashboard;
    } = {
      oneFactor: [],
      multiFactor: [],
      trStrategies: [],
    };

    if (dashboard) {
      for (const strategy of dashboard) {
        // TODO da rimuovere assolutamente quando torna Sandro
        if (strategy?.info?.id !== 24280 && strategy?.info?.id !== 26334) {
          if (strategy?.info?.market === tab) {
            if (strategy?.info?.levels === "one") {
              strategies.oneFactor!.push(strategy);
            } else if (strategy?.info?.levels === "two") {
              strategies.multiFactor!.push(strategy);
            } else if (strategy?.info?.levels === "three") {
              strategies.trStrategies!.push(strategy);
            }
          }
        }
      }
    }

    return strategies;
  }, [dashboard, tab]);

  const tabs = useMemo(() => {
    return [
      { label: "Single Fundamental", value: "one" },
      { label: "Combined Fundamentals", value: "two" },
      { label: "Optimized", value: "three" },
      { label: "Top 10", value: "bestBy" },
    ];
  }, []);

  const downLoadPdf = useCallback(async () => {
    pdf.print();
  }, [pdf]);

  const onTableRowClick = useCallback(
    (strategyId, tableHash) => {
      if (askBeforeInsightsRedirect) {
        setStrategyIdToRedirect(strategyId);
        setShowModal(true);
      } else {
        const newState = deepClone(ctx.get());
        newState.insightsV1State.scrollToTargetId = tableHash;
        newState.selectedStrategy = { type: "V1", id: strategyId };
        ctx.set(newState);

        if (tableHash == null) {
          setTabValue("one");
        } else if (tableHash === "multifactorTable") {
          setTabValue("two");
        } else if (tableHash === "trStrategiesTable") {
          setTabValue("three");
        } else if (tableHash === "bestBy") {
          setTabValue("bestBy");
        }

        ctx.get().insightsV1State.handleRowClick(strategyId);
      }
    },
    [
      askBeforeInsightsRedirect,
      ctx,
      setShowModal,
      setStrategyIdToRedirect,
      setTabValue,
    ]
  );

  const scrollableBoxRef = useRef<HTMLDivElement>(null);

  const scrollToRightTable = useCallback(() => {
    const globalState = ctx.get();
    const anchor = globalState.insightsV1State.scrollToTargetId;

    if (anchor) {
      const anchorEl = document.getElementById(anchor);
      if (anchorEl) {
        const offset = 200;
        const topPosition = anchorEl.offsetTop - offset;
        const el = scrollableBoxRef?.current;
        if (el != null) {
          el.scrollTo({ top: topPosition });
        }
      }
    }
  }, [ctx]);

  const changeAnchorValue = useCallback(
    (event, value) => {
      setTabValue(value);

      const nodeIds = {
        one: "singleFactorTable",
        two: "multifactorTable",
        three: "trStrategiesTable",
        bestBy: "bestBy",
      };

      const newState = deepClone(ctx.get());
      newState.insightsV1State.scrollToTargetId = nodeIds[value];

      ctx.set(newState);

      scrollToRightTable();
    },
    [ctx, scrollToRightTable, setTabValue]
  );

  useEffect(() => {
    if (scrollableBoxRef.current) {
      scrollToRightTable();
    }
  }, [scrollToRightTable]);
  return (
    <>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <CustomTabs
          changeTab={changeAnchorValue}
          activeTab={tabValue}
          tabs={tabs}
        />
        {ctx.get().usedIn !== "RISE" && (
          <Box display={"flex"} justifyContent={"flex-end"}>
            <Button onClick={downLoadPdf}>Download PDF</Button>
          </Box>
        )}
      </Box>
      {tabValue !== "bestBy" ? (
        <Box
          className={styles.main__card__content__tables}
          ref={scrollableBoxRef}
          id={"scrollableBoxInsightsTab"}
        >
          <Section
            data={strategies}
            onTableRowClick={onTableRowClick}
            scrollToRightTable={scrollToRightTable}
            level={"one"}
            tab={tab}
            mt={0}
          />
          <Section
            data={strategies}
            onTableRowClick={onTableRowClick}
            scrollToRightTable={scrollToRightTable}
            level={"two"}
            tab={tab}
            mt={2}
          />
          <Section
            data={strategies}
            onTableRowClick={onTableRowClick}
            scrollToRightTable={scrollToRightTable}
            level={"three"}
            tab={tab}
            mt={2}
          />
        </Box>
      ) : (
        <BestByView
          dashboard={dashboard}
          currentTab={tabValue}
          handleTableRowClick={onTableRowClick}
        />
      )}
    </>
  );
};

const InsightsV1 = withRedirectWarnigModal(InsightsContent);
const InsightsV2 = withRedirectWarnigModal(InsightsExtended);

export const Insights = memo(
  ({ askBeforeInsightsRedirect = false, dashboards }: InsightsType) => {
    const dashboard = useMemo(() => dashboards.V1, [dashboards.V1]);
    const dashboardV2 = useMemo(() => dashboards.V2, [dashboards.V2]);
    const isLoadingContent = useMemo(
      () => dashboards.status === "loading",
      [dashboards.status]
    );
    const loadingSuccess = useMemo(
      () => dashboards.status === "success",
      [dashboards.status]
    );
    const ctx = useContext(InsightsContext);
    const [insightsV2Key, setInsightsV2Key] = useState(Date.now());

    const defaultShowV2 = useMemo(() => {
      const state = ctx.get();

      return state.selectedInsightsVersion === "V2";
    }, [ctx]);

    const defaultTab = useMemo(() => {
      const currentState = deepClone(ctx.get());
      return currentState.currentTab ?? "US";
    }, [ctx]);

    const [tab, setTab] = useState<TabValue>(defaultTab);

    const defaultTabValue = useMemo(() => {
      const state = ctx.get();
      const startTab = state.insightsV1State.scrollToTargetId;

      if (startTab) {
        switch (startTab) {
          case "singleFactorTable":
            return "one";
          case "multifactorTable":
            return "two";
          case "trStrategiesTable":
            return "three";
          default:
            return startTab;
        }
      }

      return "one";
    }, [ctx]);

    const [tabValue, setTabValue] = useState<string>(defaultTabValue);
    const [showExtended, setShowExtended] = useState(defaultShowV2);

    const isEuBtnActive = useMemo(
      () => tab === "EU" && !showExtended,
      [showExtended, tab]
    );
    const isUsBtnActive = useMemo(
      () => tab === "US" && !showExtended,
      [showExtended, tab]
    );

    const isAsiaBtnActive = useMemo(() => tab === "R_D_P", [tab]);

    const showDetailedView = useCallback(() => {
      // This trigger style changes
      setTab("US");

      const newState = ctx.get();
      newState.selectedInsightsVersion = "V2";
      ctx.set(newState);
      setShowExtended(true);
    }, [ctx]);

    const removeAnchorTag = useCallback(() => {
      setTabValue("one");

      const newState = deepClone(ctx.get());
      newState.insightsV1State.scrollToTargetId = undefined;

      ctx.set(newState);
    }, [ctx]);

    const refreshV2Content = useCallback(() => {
      setInsightsV2Key(Date.now());
    }, []);

    const storeCurrentTab = useCallback(
      (tab) => {
        const currentState = deepClone(ctx.get());

        currentState.currentTab = tab;

        ctx.set(currentState);
      },
      [ctx]
    );

    const handleTabChange = useCallback(
      (event, value) => {
        storeCurrentTab(value);

        const newState = deepClone(ctx.get());
        newState.selectedInsightsVersion = "V1";
        ctx.set(newState);

        removeAnchorTag();
        setTab(value);
        setShowExtended(false);
      },
      [ctx, removeAnchorTag, storeCurrentTab]
    );

    const [strategyIdToRedirect, setStrategyIdToRedirect] = useState<any>(null);

    const overrideCurrentStrategy = useCallback(
      (closeDialog) => {
        closeDialog();
        const override = ctx.get().insightsV1State.overideStrategy;
        if (override) {
          override(strategyIdToRedirect);
        }
      },
      [ctx, strategyIdToRedirect]
    );

    const handeleDropdownCountryClick = useCallback(
      (id, market) => {
        const currentState = ctx.get();
        currentState.insightsV2State.activeFilters = {};

        currentState.insightsV2State.activeFilters["country"] = [market];
        currentState.insightsV2State.activeFilters["sector"] = [id];
        currentState.insightsV2State.hidedFields = ["country"];

        ctx.set(currentState);
        refreshV2Content();
        showDetailedView();
      },
      [ctx, refreshV2Content, showDetailedView]
    );

    const goToMoreSection = useCallback(() => {
      // Reset filters
      const currentState = deepClone(ctx.get());
      currentState.insightsV2State.activeFilters = undefined;
      currentState.insightsV2State.hidedFields = [];

      ctx.set(currentState);

      refreshV2Content();
      showDetailedView();
    }, [ctx, refreshV2Content, showDetailedView]);

    return (
      <Box className={styles.main}>
        {dashboard != null && loadingSuccess && (
          <Card className={styles.main__card}>
            <CardContent className={styles.main__card__content}>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                {ctx.get().usedIn !== "RISE" && (
                  <Typography
                    sx={{ fontSize: "1.2em", textTransform: "uppercase" }}
                  >
                    Performance analysis of different strategies
                  </Typography>
                )}
                <Box display={"flex"} gap={1} alignItems={"center"}>
                  <Typography>Investment Universe: </Typography>
                  <Box display={"flex"} gap={1} alignItems={"center"}>
                    <CountryBtn
                      handleDropDownClick={(id) =>
                        handeleDropdownCountryClick(id, "US")
                      }
                      countryCode="US"
                      changeCountry={handleTabChange}
                      isActive={isUsBtnActive}
                      dashboardStrategies={dashboardV2?.strategies}
                    />
                    <CountryBtn
                      countryCode="EU"
                      isActive={isEuBtnActive}
                      changeCountry={handleTabChange}
                      handleDropDownClick={(id) =>
                        handeleDropdownCountryClick(id, "R_D_E")
                      }
                      dashboardStrategies={dashboardV2?.strategies}
                    />
                    <CountryBtn
                      countryCode="R_D_P"
                      isActive={isAsiaBtnActive}
                      changeCountry={handleTabChange}
                      handleDropDownClick={(id) =>
                        handeleDropdownCountryClick(id, "R_D_P")
                      }
                      dashboardStrategies={dashboardV2?.strategies}
                    />
                    {ctx.get().usedIn !== "RISE" && (
                      <MoreBtn
                        key={uuidv4()}
                        onBtnClick={goToMoreSection}
                        showExtended={showExtended && tab !== "R_D_P"}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
              {showExtended ? (
                <InsightsV2
                  askBeforeRedirect={askBeforeInsightsRedirect}
                  key={insightsV2Key}
                  loading={isLoadingContent}
                  dashboard={dashboardV2}
                />
              ) : (
                <InsightsV1
                  onClickOk={overrideCurrentStrategy}
                  askBeforeInsightsRedirect={askBeforeInsightsRedirect}
                  dashboard={dashboard}
                  tab={tab}
                  setTab={setTab}
                  setStrategyIdToRedirect={setStrategyIdToRedirect}
                  tabValue={tabValue}
                  setTabValue={setTabValue}
                />
              )}
            </CardContent>
          </Card>
        )}
      </Box>
    );
  }
);

type SectionProps = {
  level: "one" | "two" | "three";
  tab: "EU" | "US";
  data: {
    oneFactor: Dashboard;
    multiFactor: Dashboard;
    trStrategies: Dashboard;
  };
  onTableRowClick: Function;
  scrollToRightTable: Function;
  mt: number;
};

const Section = ({
  level,
  tab,
  data,
  onTableRowClick,
  scrollToRightTable,
  mt,
}: SectionProps) => {
  const [showSummary, setShowSummary] = useState(false);
  const strategyType: any = useMemo(() => {
    const type = {
      one: "oneFactor",
      two: "multiFactor",
      three: "trStrategies",
    };

    return type[level];
  }, [level]);

  const tableLabel = useMemo(() => {
    switch (strategyType) {
      case "oneFactor":
        return "Single Fundamental";
      case "multiFactor":
        return "Combined Fundamentals";
      case "trStrategies":
        return "Optimized Strategies";
    }
  }, [strategyType]);

  const toggleSummaryVisibility = useCallback(() => {
    setShowSummary(!showSummary);
  }, [showSummary]);

  return (
    <Box display={"flex"} flexDirection={"column"} marginTop={mt}>
      <Box display={"flex"} gap={1} alignItems={"center"} pt={1}>
        <Typography
          fontSize={"1em"}
          className={styles.tableBox__card__content__tableTitle}
        >
          {tableLabel}
        </Typography>
        <HelpIcon onClick={toggleSummaryVisibility} active={showSummary} />
      </Box>
      <Collapse in={showSummary}>
        <Summary tab={tab} level={level} />
      </Collapse>
      <FactorTable
        strategyType={strategyType}
        data={data}
        onTableRowClick={onTableRowClick}
        scrollToRightTable={scrollToRightTable}
      />
    </Box>
  );
};

type SummayProps = {
  level: "one" | "two" | "three";
  tab: "EU" | "US";
};

const Summary = ({ level, tab }: SummayProps) => {
  const universe = useMemo(() => {
    const text = {
      one: `${tab} 600 Largest Cap - Domestic Stocks`,
      two: `${tab} 600 Largest Cap - Domestic Stocks`,
      three:
        tab === "EU"
          ? "EU Large and Mid  Domestic Common Stocks"
          : "US Large Domestic Common Stocks",
    };

    return text[level];
  }, [level, tab]);

  const title = useMemo(
    () => ({
      one: "Strategies using one fundamental rule",
      two: "Strategies using two fundamental rules",
      three: "Strategies combining fundamentals and trend validation filters",
    }),
    []
  );

  const selectionRules = useMemo(
    () => ({
      one: {
        description: "one fundamental parameter",
        value: "selecting the best value stocks (lowest ratios)",
        growth: "selecting the best growth stocks (highest growth rate)",
        holdings: "The strategies select 50 stocks and use a monthly revision.",
      },
      two: {
        description: "combination of two fundamental parameters",
        value: "selecting the best value stocks (lowest ratios)",
        growth: "selecting the best growth stocks ( highest growth rate)",
        holdings: "The strategies select 50 stocks and use a monthly revision.",
      },
      three: {
        description:
          "combination of fundamental parameters and positive trend confirmation using Trendrating methodology",
        value: "selecting the best value stocks (lowest ratios)",
        growth: "selecting the best growth stocks (highest growth rate)",
        holdings:
          "The strategies select stocks displaying the best fundamentals and a confirmed uptrend, using a monthly revision.",
      },
    }),
    []
  );

  return (
    <Box display={"flex"} gap={1} mt={1}>
      <Box flex={1}>
        <Typography sx={{ fontSize: "1.1em!important" }}>
          {title[level]}
        </Typography>
        <table className={styles.main__card__content__resumeTable}>
          <tbody>
            <tr>
              <td
                style={{
                  textTransform: "uppercase",
                  color: "#2a7090",
                }}
              >
                Investment Universe
              </td>
              <td>{universe}</td>
            </tr>
            <tr>
              <td
                style={{
                  textTransform: "uppercase",
                  color: "#2a7090",
                }}
              >
                Benchmark
              </td>
              <td>
                {tab === "US" ? "S&P 500 Index" : "STOXX Europe 600 Index"}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  paddingLeft: 0,
                  fontWeight: "normal",
                  textTransform: "uppercase",
                  color: "#2a7090",
                }}
              >
                Selection rules:
              </td>
              <td>{selectionRules[level].description}</td>
            </tr>
            <tr>
              <td>
                <span style={{ paddingLeft: "10px" }}>For Value</span>
              </td>
              <td>{selectionRules[level].value}</td>
            </tr>
            <tr>
              <td>
                <span style={{ paddingLeft: "10px" }}>For Growth</span>
              </td>
              <td>{selectionRules[level].growth}</td>
            </tr>
            <tr>
              <td colSpan={2}>{selectionRules[level].holdings}</td>
            </tr>
          </tbody>
        </table>
      </Box>
      <Box
        height={"100%"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        flex={1}
        p={1}
      >
        <Card sx={{ boxShadow: 2 }}>
          <CardContent>
            <Box display={"flex"} flexDirection={"column"} gap={2}>
              <Box gap={1} display={"flex"} alignItems={"center"}>
                <CheckIcon sx={{ fontSize: "0.9vw" }} color="primary" />
                <Typography>
                  Compare the performance of different stock selection
                  strategies
                </Typography>
              </Box>
              <Box gap={1} display={"flex"} alignItems={"center"}>
                <CheckIcon sx={{ fontSize: "0.9vw" }} color="primary" />

                <Typography>Discover what can best maximize returns</Typography>
              </Box>
              <Box gap={1} display={"flex"} alignItems={"center"}>
                <CheckIcon sx={{ fontSize: "0.9vw" }} color="primary" />

                <Typography>Select one and run a test</Typography>
              </Box>
              <Box gap={1} display={"flex"} alignItems={"center"}>
                <CheckIcon sx={{ fontSize: "0.9vw" }} color="primary" />

                <Typography>
                  Customize the strategy as you like and save it
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

type CountryBtnProps = {
  changeCountry: (event, value: "EU" | "US" | "R_D_P") => void;
  countryCode: "EU" | "US" | "R_D_P";
  isActive: boolean;
  handleDropDownClick: (id: string) => void;
  dashboardStrategies: any;
};

const MoreBtn = ({ onBtnClick, showExtended }) => {
  const btnClass = useMemo(() => {
    let baseClass = `${styles.country__btn}`;

    if (showExtended) {
      baseClass += ` ${styles.country__btn__active}`;
    }

    return baseClass;
  }, [showExtended]);

  return (
    <Box role="button" onClick={onBtnClick} className={btnClass} p={1}>
      <Box className={styles.country__btn__content}>
        <FilterListIcon />
        <Typography>{"Markets"}</Typography>
      </Box>
    </Box>
  );
};

const CountryBtn = ({
  changeCountry,
  handleDropDownClick,
  countryCode,
  isActive,
  dashboardStrategies,
}: CountryBtnProps) => {
  const ctx = useContext(InsightsContext);
  const onBtnClick = useCallback(
    (event) => {
      changeCountry(event, countryCode);
    },
    [changeCountry, countryCode]
  );

  const btnClass = useMemo(() => {
    let baseClass = `${styles.country__btn}`;

    if (isActive) {
      baseClass += ` ${styles.country__btn__active}`;
    }

    return baseClass;
  }, [isActive]);

  const environment = useEnvironment();
  const taxonomies = useMemo(
    () => environment.get("rawTaxonomies"),
    [environment]
  );
  const taxonFields = useMemo(
    () => environment.get("taxonomyFields"),
    [environment]
  );

  const countryTaxonomies = useMemo(
    () => taxonomies[taxonFields["security"]["country"]],
    [taxonFields, taxonomies]
  );

  const nodes = useMemo(() => {
    const sectorTaxonomies = taxonomies[taxonFields["security"]["icb"]];

    const availableSectorsIds = {};

    for (const strategy of dashboardStrategies) {
      if (strategy["tags"]?.["sector"] && strategy["tags"]?.["sector"].length) {
        for (const id of strategy["tags"]["sector"]) {
          availableSectorsIds[id] = true;
        }
      }
    }

    const nodes: { id: string; name: string }[] = [];
    let node: any = null;

    for (const key in availableSectorsIds) {
      node = sectorTaxonomies?.[key] ?? null;

      if (node.type === "1 Industry") {
        nodes.push({ id: key, name: node?.name ?? "" });
      }
    }

    nodes.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      } else if (a.name < b.name) {
        return -1;
      }

      return 0;
    });

    return nodes;
  }, [dashboardStrategies, taxonFields, taxonomies]);

  return (
    <Box role="button" onClick={onBtnClick} className={btnClass}>
      <Box className={styles.country__btn__content}>
        <img alt="market-flag" src={`/img/flags/${countryCode}.png`} />
        <Typography>
          {countryTaxonomies[countryCode !== "EU" ? countryCode : "R_D_E"]
            ?.name ?? ""}
        </Typography>
      </Box>
      {ctx.get().usedIn !== "RISE" && (
        <DropdownMenu
          options={nodes.map((item) => ({ label: item.name, value: item.id }))}
          handleDropDownClick={handleDropDownClick}
        >
          <Box borderLeft={"1px solid grey"}>
            <MoreVertIcon />
          </Box>
        </DropdownMenu>
      )}
    </Box>
  );
};

type HelpIconProps = {
  onClick: () => void;
  active: boolean;
};

const HelpIcon = ({ onClick, active }: HelpIconProps) => {
  return (
    <Box
      onClick={onClick}
      className={`${styles.helpIcon} ${
        active ? styles["helpIcon__active"] : ""
      }`}
    >
      <span>?</span>
    </Box>
  );
};

type FactorTableProps = {
  data: {
    oneFactor: Dashboard;
    multiFactor: Dashboard;
    trStrategies: Dashboard;
  };
  onTableRowClick: Function;
  scrollToRightTable: Function;
  strategyType: "oneFactor" | "multiFactor" | "trStrategies";
};

const FactorTable = memo(
  ({
    data,
    onTableRowClick,
    scrollToRightTable,
    strategyType,
  }: FactorTableProps) => {
    const ctx = useContext(InsightsContext);
    const benchmark = useMemo(
      () => data?.oneFactor?.[0]?.info?.benchmark ?? "Benchmark",
      [data]
    );

    const tableId = useMemo(() => {
      switch (strategyType) {
        case "oneFactor":
          return "singleFactorTable";
        case "multiFactor":
          return "multifactorTable";
        case "trStrategies":
          return "trStrategiesTable";
      }
    }, [strategyType]);

    const getDefaultSorter = useCallback(() => {
      const globalState = ctx.get();
      const sorters = {
        multiFactor: globalState.insightsV1State.multiFactorTableSorter,
        oneFactor: globalState.insightsV1State.singleFactorTableSorter,
        trStrategies: globalState.insightsV1State.trStrategiesTableSorter,
      };

      const sorter = sorters[strategyType];

      return {
        field: sorter.field,
        direction: sorter.rev === false ? "desc" : "asc",
      };
    }, [ctx, strategyType]);

    const getAnalytic = useCallback(
      (obj: any, key: keyof typeof ANALYTICS_DICT) => {
        const analyticObj =
          obj?.priceAnalytics?.stats?.[ANALYTICS_DICT?.[key]] ?? null;

        if (analyticObj) {
          return (Object.values(analyticObj)?.[0] as number) ?? null;
        }

        return null;
      },
      []
    );

    const getName = useCallback(
      (strategy) => {
        const useLabelTag =
          "label" in strategy.info && Array.isArray(strategy.info.label);

        if (useLabelTag) {
          return strategy.info.label.join("</br>");
        } else {
          switch (strategyType) {
            case "oneFactor":
              return `${strategy?.info?.L1Direction ?? ""} ${
                strategy?.info?.L1 ?? ""
              }`;

            case "multiFactor":
              return `${strategy?.info?.L1Direction ?? ""} ${
                strategy?.info?.L1 ?? ""
              }__${strategy?.info?.L2Direction ?? ""} ${
                strategy?.info?.L2 ?? ""
              }`;

            case "trStrategies":
              if (Array.isArray(strategy.info.label)) {
                return "";
              }

              return strategy?.info?.label ?? "";

            default:
              return "";
          }
        }
      },
      [strategyType]
    );

    const getType = useCallback((strategy) => {
      let type = "";

      if (strategy?.info?.type != null && strategy?.info?.type.length > 0) {
        type = strategy.info.type.join(" + ");
      }

      return type;
    }, []);

    const wrapData = useCallback(() => {
      const rows: {
        name: string;
        id: number | null;
        synthId: string;
        year_3_perf_H: number | null;
        year_3_perf_DIFF: number | null;
        year_10_perf_H: number | null;
        year_10_perf_DIFF: number | null;
        ytd: number | null;
        ytd_DIFF: number | null;
        sharpeRatio: number | null;
        type: string;
      }[] = [];

      const strategies = data[strategyType];

      for (const strategy of strategies!) {
        rows.push({
          name: getName(strategy),
          id: strategy?.info?.id,
          synthId: strategy?.info?.synteticId,
          type: getType(strategy),
          year_3_perf_H: getAnalytic(strategy, "year_3_perf_H"),
          year_3_perf_DIFF: getAnalytic(strategy, "year_3_perf_DIFF"),
          year_10_perf_H: getAnalytic(strategy, "year_10_perf_H"),
          year_10_perf_DIFF: getAnalytic(strategy, "year_10_perf_DIFF"),
          ytd: getAnalytic(strategy, "ytd"),
          ytd_DIFF: getAnalytic(strategy, "ytd_DIFF"),
          sharpeRatio: getAnalytic(strategy, "sharpeRatio"),
        });
      }

      return rows;
    }, [data, getAnalytic, getName, getType, strategyType]);

    const formattedData = useMemo(() => wrapData(), [wrapData]);
    const formatter = useFormatter();

    const nameFormatter = useCallback(
      (cell) => {
        const data = cell.getData();
        const name = data["name"];

        //#region - highlighting the current strategy
        const currentState = ctx.get();

        let id = data?.["id"] ?? null;
        if (id) {
          let selectedStrategyId: string | number | null =
            currentState.selectedStrategy?.id ?? null;
          if (selectedStrategyId != null) {
            selectedStrategyId = parseInt(selectedStrategyId);
            if (id === selectedStrategyId) {
              const row = cell.getRow();
              row.getElement().style.backgroundColor = "rgba(255, 192, 1, 0.2)";
            }
          }
        }
        //#endregion

        const nameSplitted = name.split("__");

        if (nameSplitted?.[1] != null) {
          return `${nameSplitted[0]}</br>${nameSplitted[1]}`;
        }

        return nameSplitted[0];
      },
      [ctx]
    );

    const renderCell = useCallback(
      (cell) => {
        const value = cell.getValue();
        const field = cell.getField();

        if (field === "sharpeRatio") {
          return formatter.custom("number", {
            options: {
              notAvailable: {
                input: null,
                output: "",
              },
              decimals: 4,
            },
            output: "HTML",
            value: cell.getValue(),
          });
        } else {
          let innerHTML = "";

          innerHTML = formatter.custom("number", {
            options: {
              isPercentage: true,
              notAvailable: {
                input: null,
                output: "",
              },
            },
            output: "HTML",
            value: value,
            valueHelper: {
              normalizationThreshold: 1,
            },
          });

          return innerHTML;
        }
      },
      [formatter]
    );

    const prepareHeaderClickTapListener = useCallback(
      (eventCallback?: Function) => {
        return function headerClickTapListener(
          e: UIEvent,
          headerColumn: ColumnComponent
        ) {
          const sorters = headerColumn.getTable().getSorters();

          let actualDirection =
            headerColumn.getDefinition().headerSortStartingDir;
          if (
            sorters.length > 0 &&
            sorters[0].field === headerColumn.getField()
          ) {
            actualDirection = sorters[0].dir === "asc" ? "desc" : "asc";
          }

          if (eventCallback) {
            eventCallback({
              type: "sort",
              value: {
                direction: actualDirection,
                field: headerColumn.getField(),
              },
            });
          }
        };
      },
      []
    );

    const sorterCallback = useCallback(
      (event) => {
        const sorter = event.value;
        const newState = deepClone(ctx.get());

        if (strategyType === "multiFactor") {
          newState.insightsV1State.multiFactorTableSorter = {
            field: sorter.field,
            rev: sorter.direction === "desc",
          };
        } else if (strategyType === "oneFactor") {
          newState.insightsV1State.singleFactorTableSorter = {
            field: sorter.field,
            rev: sorter.direction === "desc",
          };
        } else if (strategyType === "trStrategies") {
          newState.insightsV1State.trStrategiesTableSorter = {
            field: sorter.field,
            rev: sorter.direction === "desc",
          };
        }

        ctx.set(newState);
      },
      [ctx, strategyType]
    );

    const columns: any = useMemo(
      () =>
        ctx.get().usedIn !== "RISE"
          ? [
              {
                field: "type",
                title: "Type",
                widthGrow: 1,
                hozAlign: "left",
                headerClick: prepareHeaderClickTapListener(sorterCallback),
              },
              {
                field: "name",
                title: "Selection Criteria",
                formatter: nameFormatter,
                widthGrow: 1.5,
                headerClick: prepareHeaderClickTapListener(sorterCallback),
              },
              {
                field: "synthId",
                title: "ID",
                widthGrow: 0.5,
                hozAlign: "left",
                headerClick: prepareHeaderClickTapListener(sorterCallback),
              },
              {
                title: "10 YEARS ANNUALIZED RETURN",
                headerHozAlign: "center",
                columns: [
                  {
                    field: "year_10_perf_H",
                    title: "Strategy",
                    formatter: renderCell,
                    sorter: "number",
                    headerClick: prepareHeaderClickTapListener(sorterCallback),
                  },

                  {
                    field: "year_10_perf_DIFF",
                    title: `Excess Return vs </br> ${benchmark}`,
                    headerHozAlign: "right",
                    sorter: "number",
                    headerClick: prepareHeaderClickTapListener(sorterCallback),
                    formatter: renderCell,
                  },
                ],
              },
              {
                title: "3 YEARS ANNUALIZED RETURN",
                columns: [
                  {
                    field: "year_3_perf_H",
                    title: "Strategy",
                    formatter: renderCell,
                    headerHozAlign: "right",
                    sorter: "number",
                    headerClick: prepareHeaderClickTapListener(sorterCallback),
                  },

                  {
                    field: "year_3_perf_DIFF",
                    title: `Excess Return vs </br> ${benchmark}`,
                    formatter: renderCell,
                    headerHozAlign: "right",
                    sorter: "number",
                    headerClick: prepareHeaderClickTapListener(sorterCallback),
                  },
                ],
              },
              {
                title: "YTD RETURN",
                columns: [
                  {
                    field: "ytd",
                    title: "Strategy",
                    formatter: renderCell,
                    headerHozAlign: "right",
                    sorter: "number",
                    headerClick: prepareHeaderClickTapListener(sorterCallback),
                  },

                  {
                    field: "ytd_DIFF",
                    title: `Excess Return vs </br> ${benchmark}`,
                    headerHozAlign: "right",
                    sorter: "number",
                    headerClick: prepareHeaderClickTapListener(sorterCallback),
                    formatter: renderCell,
                  },
                ],
              },
              {
                title: "",

                columns: [
                  {
                    field: "sharpeRatio",
                    title: "Sharpe Ratio",
                    formatter: renderCell,
                    headerHozAlign: "right",
                    sorter: "number",
                    headerClick: prepareHeaderClickTapListener(sorterCallback),
                  },
                ],
              },
            ]
          : [
              {
                field: "type",
                title: "Type",
                widthGrow: 1,
                hozAlign: "left",
                headerClick: prepareHeaderClickTapListener(sorterCallback),
              },
              {
                field: "name",
                title: "Selection Criteria",
                formatter: nameFormatter,
                widthGrow: 1.5,
                headerClick: prepareHeaderClickTapListener(sorterCallback),
              },
              {
                field: "synthId",
                title: "ID",
                widthGrow: 0.5,
                hozAlign: "left",
                headerClick: prepareHeaderClickTapListener(sorterCallback),
              },
              {
                title: "10 YEARS ANNUALIZED RETURN",
                headerHozAlign: "center",
                columns: [
                  {
                    field: "year_10_perf_H",
                    title: "Strategy",
                    formatter: renderCell,
                    sorter: "number",
                    headerClick: prepareHeaderClickTapListener(sorterCallback),
                  },

                  {
                    field: "year_10_perf_DIFF",
                    title: `Excess Return vs </br> ${benchmark}`,
                    headerHozAlign: "right",
                    sorter: "number",
                    headerClick: prepareHeaderClickTapListener(sorterCallback),
                    formatter: renderCell,
                  },
                ],
              },
            ],
      [
        benchmark,
        ctx,
        nameFormatter,
        prepareHeaderClickTapListener,
        renderCell,
        sorterCallback,
      ]
    );

    const tableEventsHandlres = useCallback(
      (event) => {
        if (event.type === "rowClick") {
          const data = event.value.getData();
          const anchors = {
            oneFactor: undefined,
            multiFactor: "multifactorTable",
            trStrategies: "trStrategiesTable",
          };
          const tableAnchor = anchors[strategyType];
          onTableRowClick(data.id, tableAnchor);
        }

        if (event.type === "tableBuilt") {
          scrollToRightTable();
        }
      },
      [onTableRowClick, scrollToRightTable, strategyType]
    );

    return (
      <Box className={styles.tableBox} gap={1} marginTop={1}>
        <Card className={styles.tableBox__card} sx={{ boxShadow: 3 }}>
          <CardContent className={styles.tableBox__card__content}>
            <Box display={"flex"} gap={1} flexDirection={"column"} id={tableId}>
              <Box display={"flex"} flexDirection={"column"}>
                <Typography>
                  Click on the row to open the relative strategy.
                </Typography>
              </Box>
              <TrendratingTable
                columns={columns}
                data={formattedData}
                disableDefaultRowClick
                eventCallback={tableEventsHandlres}
                autoResize={false}
                sorting={getDefaultSorter()}
                options={{
                  ajaxSorting: false,
                }}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    );
  }
);

class PDF {
  rawData: Dashboard;
  environment: AppEnvironment;
  getWysiwyg: () => {
    tab: "EU" | "US" | "R_D_P";
    isBestBy: boolean;
    currentSubTabValue: string;
    activeCheckboxes: string[];
    sorters: {
      one: { field: string; rev: boolean };
      two: { field: string; rev: boolean };
      three: { field: string; rev: boolean };
      best: { field: string; rev: boolean };
    };
  };
  formatter: Formatter;
  reportAPI: Report;
  widgets: any;

  constructor(
    jsonData: Dashboard,
    environment: AppEnvironment,
    getUIParams: () => {
      tab: "EU" | "US" | "R_D_P";
      isBestBy: boolean;
      currentSubTabValue: string;
      activeCheckboxes: string[];
      sorters: {
        one: { field: string; rev: boolean };
        two: { field: string; rev: boolean };
        three: { field: string; rev: boolean };
        best: { field: string; rev: boolean };
      };
    }
  ) {
    this.rawData = jsonData;
    this.environment = environment;
    this.getWysiwyg = getUIParams;
    this.formatter = new Formatter(this.environment);
    this.reportAPI = new Report(this.environment);
    let benchmarkName = "";
    const market = this.getWysiwyg().tab;

    for (const strategy of jsonData!) {
      if ((strategy?.info?.market ?? null) === market) {
        benchmarkName = escapeEntity(strategy?.info?.benchmark ?? "Benchmark");
        break;
      }
    }

    this.widgets = {
      title: {
        data: {
          text: "Strategy Insights",
        },
        type: "title",
      },
      subtitle: {
        data: {
          text: "Europe",
        },
        type: "title2",
      },
      pageBreak: {
        data: null,
        type: "pageBreak",
      },
      space: {
        data: {
          text: "<br/><br/>",
        },
        type: "text",
      },
      tableTitle: {
        data: {
          text: "SCREENING",
        },
        type: "header1",
      },
      table: {
        data: {
          colWidths: [null, 4, null, null, null, null, null, null, null],
          body: [[]],
          head: [
            [
              { style: null, value: "" },
              { style: null, value: "" },
              { style: null, value: "" },
              { style: null, value: "10 Years" },
              { style: null, value: "" },
              { style: null, value: "3 Years" },
              { style: null, value: "" },
              { style: null, value: "YTD" },
              { style: null, value: "" },
              { style: null, value: "" },
            ],
            [
              {
                style: null,
                value: "Type",
              },
              {
                style: null,
                value: "Name",
              },
              {
                style: null,
                value: "ID",
              },
              {
                style: null,
                value: "Annualized return",
              },
              {
                style: null,
                value: "Excess Return vs " + benchmarkName,
              },
              {
                style: null,
                value: "Annualized return",
              },
              {
                style: null,
                value: "Excess Return vs " + benchmarkName,
              },
              {
                style: null,
                value: "Return",
              },
              {
                style: null,
                value: "Excess Return vs " + benchmarkName,
              },
              {
                style: null,
                value: "Sharpe Ratio",
              },
            ],
          ],
        },
        type: "table",
      },
      disclaimer: {
        data: {
          text: "",
        },
        type: "text",
      },
    };
  }

  getInvestmentUniverse() {
    const taxonomies = this.environment.taxonomies;
    const fields = this.environment.taxonomyFields;
    let tab: any = this.getWysiwyg().tab;

    if (tab === "EU") {
      tab = "R_D_E";
    }

    const country =
      taxonomies?.[fields?.["security"]?.["country"]]?.[tab]?.name;

    if (country) {
      return ` - ${country}`;
    }

    return "";
  }

  getUserPreference() {
    const preferences =
      this.environment.account.user?.preferences?.preferences ?? null;

    let userPreference = preferences?.report?.general ?? null;
    if (userPreference == null) {
      // Prepare default values
      userPreference = {
        disclaimer: null,
        logo: null,
        theme: null,
      };
    }
    userPreference["theme"] = themeBlueAndGrey;

    return userPreference;
  }

  async preparePrintParams() {
    let hasCover = false;
    let title = "Insights on key fundamental parameters";

    const fileName = "Trendrating - " + title.replace("/", "_") + ".pdf";

    const params = {
      content: await this.buildReportContent(),
      cover: hasCover,
      fileName: fileName,
      meta: {
        author: "Trendrating",
        title: title,
      },
      orientation: "portrait",
      headerConfig: {
        logo: "small",
        date: true,
      },
      userPreference: this.getUserPreference(),
    };

    return params;
  }

  async buildReportContent() {
    const content: any = [];

    this.addSpace(content);
    this.addTitle(content);
    this.addSpace(content);
    this.addTables(content);
    this.addSpace(content);
    await this.addDisclaimer(content);

    return content;
  }

  addPageBreak(content) {
    content.push(this.widgets.pageBreak);
  }

  addSpace(content) {
    content.push(deepClone(this.widgets.space));
  }

  addTitle(content) {
    const titleWget = deepClone(this.widgets.title);

    titleWget.data.text += this.getInvestmentUniverse();

    content.push(titleWget);
  }

  addSubTitle(content) {
    content.push(deepClone(this.widgets.subtitle));
  }

  addTableTitle(content, label) {
    const tableTitleWget = deepClone(this.widgets.tableTitle);

    tableTitleWget.data.text = label;

    content.push(deepClone(tableTitleWget));
  }

  addTables(content) {
    // TODO da rimuovere assolutamente quando torna Sandro
    const data = this.rawData?.filter(
      (strategy) => strategy?.info?.id !== 24280 && strategy?.info?.id !== 26334
    );
    const wysiwyg = this.getWysiwyg();

    const strategies = {};

    if (data) {
      if (wysiwyg.isBestBy) {
        let factor = null;
        const currentTab = wysiwyg.currentSubTabValue;

        factor = getRawAnalytic(currentTab);

        const sortedStrategies = this.sortByFactor(
          wysiwyg.tab,
          wysiwyg.activeCheckboxes,
          factor
        );

        const sliced = sortedStrategies.slice(0, 10);

        strategies["best"] = [];

        for (const strategy of sliced) {
          strategies["best"].push(this.wrapData(strategy));
        }
      } else {
        let level: any = null;

        for (const strategy of data) {
          level = strategy.info.levels;

          if (strategy.info.market === wysiwyg.tab) {
            if (level in strategies) {
              strategies[level].push(this.wrapData(strategy));
            } else {
              strategies[level] = [];

              strategies[level].push(this.wrapData(strategy));
            }
          }
        }
      }

      let tableBody: any = [];
      let row: any = [];
      let tableWget: any = null;

      // For each table add an header and a Table widget
      for (const key in strategies) {
        if (key === "one") {
          this.addTableTitle(content, "Single Fundamental");
        } else if (key === "two") {
          this.addTableTitle(content, "Combined Fundamentals");
        } else if (key === "three") {
          this.addTableTitle(content, "Optimized Strategies");
        } else if (key === "best") {
          let title = "Top 10 by ";

          let factor: any = null;
          const currentTab = wysiwyg.currentSubTabValue;

          factor = getFactor(currentTab);

          switch (factor) {
            case "year_3_perf_H":
              title += "3 Years Annualized Returns";
              break;
            case "year_10_perf_H":
              title += "10 Years Annualized Returns";
              break;
            case "ytd":
              title += "YTD Returns";
              break;
            case "sharpeRatio":
              title += "Sharpe Ratio";
          }

          this.addTableTitle(content, title);
        }

        this.applySort(strategies[key], key);
        tableWget = deepClone(this.widgets.table);

        tableBody = [];

        for (const strategy of strategies[key]) {
          row = [];

          row.push({ style: null, value: strategy.type });
          row.push({ style: null, value: this.nameFormatter(strategy.name) });
          row.push({ style: null, value: strategy?.synthId ?? "" });
          row.push({
            style: null,
            value: this.formatPerf(strategy.year_10_perf_H),
          });
          row.push({
            style: null,
            value: this.formatPerf(strategy.year_10_perf_DIFF),
          });
          row.push({
            style: null,
            value: this.formatPerf(strategy.year_3_perf_H),
          });
          row.push({
            style: null,
            value: this.formatPerf(strategy.year_3_perf_DIFF),
          });
          row.push({
            style: null,
            value: this.formatPerf(strategy.ytd),
          });
          row.push({
            style: null,
            value: this.formatPerf(strategy.ytd_DIFF),
          });
          row.push({
            style: null,
            value: this.formatSharpe(strategy.sharpeRatio),
          });

          tableBody.push([...row]);
        }

        tableWget.data.body = [...tableBody];

        content.push(deepClone(tableWget));
        if (key === "two") {
          this.addPageBreak(content);
        }
      }
    }
  }

  async addDisclaimer(content) {
    const disclaimerWget = deepClone(this.widgets.disclaimer);

    let disclaimerText: any = this.getUserPreference().disclaimer;

    if (disclaimerText == null) {
      disclaimerText = await this.reportAPI.disclaimer();
    }

    disclaimerWget.data.text = disclaimerText;

    content.push(disclaimerWget);
  }

  getAnalytic(obj: any, key: keyof typeof ANALYTICS_DICT) {
    const analyticObj =
      obj?.priceAnalytics?.stats?.[ANALYTICS_DICT?.[key]] ?? null;

    if (analyticObj) {
      return (Object.values(analyticObj)?.[0] as number) ?? null;
    }

    return null;
  }

  sortByFactor(currentTabMarket, activeCheckboxes, factor) {
    const filteredByMarket: any = [];
    const currentMarket = currentTabMarket;

    for (const strategy of this.rawData!) {
      if (strategy.info.market === currentMarket) {
        if (activeCheckboxes.length > 0) {
          if (activeCheckboxes.indexOf(strategy.info.levels) !== -1) {
            filteredByMarket.push(deepClone(strategy));
          }
        } else {
          filteredByMarket.push(deepClone(strategy));
        }
      }
    }

    filteredByMarket.sort((a, b) => {
      a = Object.values(a.priceAnalytics.stats[factor])?.[0];
      b = Object.values(b.priceAnalytics.stats[factor])?.[0];

      if (a > b) {
        return -1;
      } else if (a < b) {
        return 1;
      }

      return 0;
    });

    return filteredByMarket;
  }

  nameFormatter(name) {
    //#endregion

    const nameSplitted = name.split("__");

    if (nameSplitted?.[1] != null) {
      return `${nameSplitted[0]} ${nameSplitted[1]}`;
    }

    return nameSplitted[0];
  }

  formatPerf(value) {
    return this.formatter.custom("number", {
      options: {
        isPercentage: true,
        notAvailable: {
          input: null,
          output: "",
        },
      },
      output: "HTML",
      value: value,
    });
  }

  formatSharpe(value) {
    return this.formatter.custom("number", {
      options: {
        notAvailable: {
          input: null,
          output: "",
        },
        decimals: 4,
      },
      output: "HTML",
      value: value,
    });
  }

  getName(strategy) {
    const useLabelTag =
      "label" in strategy.info && Array.isArray(strategy.info.label);

    if (useLabelTag) {
      return strategy.info.label.join("<br/>");
    } else {
      switch (strategy.info.levels) {
        case "one":
          return `${strategy?.info?.L1Direction ?? ""} ${
            strategy?.info?.L1 ?? ""
          }`;

        case "two":
          return `${strategy?.info?.L1Direction ?? ""} ${
            strategy?.info?.L1 ?? ""
          }__${strategy?.info?.L2Direction ?? ""} ${strategy?.info?.L2 ?? ""}`;

        case "three":
          if (Array.isArray(strategy.info.label)) {
            return "";
          }

          return strategy?.info?.label ?? "";

        default:
          return "";
      }
    }
  }

  getType(strategy) {
    let type = "";

    if (strategy?.info?.type != null && strategy?.info?.type.length > 0) {
      type = strategy.info.type.join(" + ");
    }

    return type;
  }

  wrapData(strategy) {
    const row: {
      name: string;
      synthId: string;
      year_3_perf_H: number | null;
      year_3_perf_DIFF: number | null;
      year_10_perf_H: number | null;
      year_10_perf_DIFF: number | null;
      ytd: number | null;
      ytd_DIFF: number | null;
      sharpeRatio: number | null;
      type: string;
    } = {
      name: "",
      synthId: "",
      year_3_perf_H: null,
      year_3_perf_DIFF: null,
      year_10_perf_H: null,
      year_10_perf_DIFF: null,
      ytd: null,
      ytd_DIFF: null,
      sharpeRatio: null,
      type: "",
    };

    row["name"] = this.getName(strategy);
    row["type"] = this.getType(strategy);
    row["synthId"] = strategy.info.synteticId;
    row["year_3_perf_H"] = this.getAnalytic(strategy, "year_3_perf_H");
    row["year_3_perf_DIFF"] = this.getAnalytic(strategy, "year_3_perf_DIFF");
    row["year_10_perf_H"] = this.getAnalytic(strategy, "year_10_perf_H");
    row["year_10_perf_DIFF"] = this.getAnalytic(strategy, "year_10_perf_DIFF");
    row["ytd"] = this.getAnalytic(strategy, "ytd");
    row["ytd_DIFF"] = this.getAnalytic(strategy, "ytd_DIFF");
    row["sharpeRatio"] = this.getAnalytic(strategy, "sharpeRatio");

    return row;
  }

  async print() {
    try {
      const params = await this.preparePrintParams();

      const report = this.reportAPI;

      const response = await report.print(params);
      this.download(response, params.fileName);
    } catch (error: any) {
      console.log(error);
    }
  }

  download(response, fileName) {
    const blob = new Blob([response], {
      type: "application/pdf",
    });

    let downloadLink: HTMLAnchorElement | null = null;

    if ((navigator as any).msSaveBlob) {
      // IE 11
      downloadLink = document.createElement("a");
      downloadLink.setAttribute("href", "#" + fileName);
      document.body.appendChild(downloadLink);

      downloadLink.addEventListener(
        "click",
        function (event) {
          (navigator as any).msSaveBlob(blob, fileName);
        },
        false
      );
    } else {
      downloadLink = document.createElement("a");

      const attributes = [
        { attr: "class", value: "a11y" },
        { attr: "href", value: window.URL.createObjectURL(blob) },
        { attr: "target", value: "_blank" },
      ];

      attributes.forEach((attribute) => {
        downloadLink?.setAttribute(attribute.attr, attribute.value);
      });

      document.body.appendChild(downloadLink);

      downloadLink.click();
      downloadLink.remove();
    }
  }

  applySort(strategies, strategiesLevel) {
    let sortField = "name";
    let direction = "desc";

    const wysiwyg = this.getWysiwyg();

    const sorter = wysiwyg?.["sorters"]?.[strategiesLevel] ?? null;

    if (sorter) {
      sortField = sorter.field;
      direction = sorter.rev === true ? "desc" : "asc";
    }

    strategies.sort((a, b) => {
      if (a[sortField] > b[sortField]) {
        return direction === "desc" ? 1 : -1;
      } else if (a[sortField] < b[sortField]) {
        return direction === "desc" ? -1 : 1;
      }

      return 0;
    }, []);
  }
}

const CustomTabs = ({ tabs, activeTab, changeTab }) => {
  const isActive = useCallback(
    (tabValue) => {
      return activeTab === tabValue;
    },
    [activeTab]
  );
  return (
    <Box display={"flex"}>
      {tabs.map((tab) => (
        <CustomTab
          key={uuidv4()}
          isActive={isActive(tab.value)}
          tab={tab}
          changeTab={changeTab}
        />
      ))}
    </Box>
  );
};

const CustomTab = ({ tab, changeTab, isActive }) => {
  const onChange = useCallback(
    (event) => {
      changeTab(event, tab.value);
    },
    [changeTab, tab.value]
  );

  return (
    <Box display={"flex"} flexDirection={"column"} sx={{ cursor: "pointer" }}>
      <Box padding={"12px 16px"} onClick={onChange}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: 1.25,
            letterSpacing: "0.02857em",
            textTransform: "uppercase",
            minHeight: "48px",
            whiteSpace: "nowrap",
            color: isActive ? "#2a7090" : "black",
          }}
        >
          {tab.label}
        </Typography>
      </Box>
      {isActive && (
        <Box style={{ height: "2px", backgroundColor: "#2a7090" }}></Box>
      )}
    </Box>
  );
};

const BestByView = ({ dashboard, currentTab, handleTableRowClick }) => {
  const ctx = useContext(InsightsContext);

  const getActiveFilters = useCallback(() => {
    const currentState = ctx.get();

    return deepClone(currentState.insightsV1State.bestByActiveCheckbox) ?? [];
  }, [ctx]);

  const getDefaultRadio = useCallback(() => {
    const currentState = ctx.get();

    return currentState.insightsV1State.bestByRadioValue ?? "10_years_return";
  }, [ctx]);

  const [activeCheckboxes, setActiveCheckboxes] = useState<string[]>(
    getActiveFilters()
  );

  const [activeRadio, setActiveRadio] = useState<string>(getDefaultRadio());

  const factor = useMemo(() => {
    return getRawAnalytic(activeRadio);
  }, [activeRadio]);

  const sortByFactor = useCallback(() => {
    const filteredByMarket: any = [];
    const currentState = ctx.get();
    const currentMarket = currentState.currentTab;

    for (const strategy of dashboard) {
      // TODO da rimuovere assolutamente quando torna Sandro
      if (strategy?.info?.id !== 24280 && strategy?.info?.id !== 26334) {
        if (strategy?.info?.market === currentMarket) {
          if (activeCheckboxes.length > 0) {
            if (activeCheckboxes.indexOf(strategy.info.levels) !== -1) {
              filteredByMarket.push(deepClone(strategy));
            }
          } else {
            filteredByMarket.push(deepClone(strategy));
          }
        }
      }
    }

    filteredByMarket.sort((a, b) => {
      a = Object.values(a.priceAnalytics.stats[factor])?.[0];
      b = Object.values(b.priceAnalytics.stats[factor])?.[0];

      if (a > b) {
        return -1;
      } else if (a < b) {
        return 1;
      }

      return 0;
    });

    return filteredByMarket;
  }, [activeCheckboxes, ctx, dashboard, factor]);

  const strategies = useMemo(() => {
    const strategies = sortByFactor();

    const sliced = strategies.slice(0, 10);
    return sliced;
  }, [sortByFactor]);

  const checkboxes = useMemo(() => {
    return [
      { label: "Single Fundamental", id: "one" },
      { label: "Combined Fundamentals", id: "two" },
      { label: "Optimized", id: "three" },
    ];
  }, []);

  const radioBtns = useMemo(() => {
    return [
      { label: "10 Years Return", id: "10_years_return" },
      { label: "3 Years Return", id: "3_years_return" },
      { label: "YTD Return", id: "YTD_return" },
      { label: "Sharpe Ratio", id: "sharpe" },
    ];
  }, []);

  const updateCheckboxGlobalState = useCallback(
    (value: string[]) => {
      const globalState = deepClone(ctx.get());

      globalState.insightsV1State.bestByActiveCheckbox = value ?? [];

      ctx.set(globalState);
    },
    [ctx]
  );

  const onCheckboxChange = useCallback(
    (event, checked, id) => {
      if (checked) {
        setActiveCheckboxes((current) => {
          const updatedState = [...current, id];

          updateCheckboxGlobalState(updatedState);

          return updatedState;
        });
      } else {
        setActiveCheckboxes((current) => {
          const updatedState = current.filter((item) => item !== id);

          updateCheckboxGlobalState(updatedState);

          return updatedState;
        });
      }
    },
    [updateCheckboxGlobalState]
  );

  const onChangeRadio = useCallback(
    (id) => {
      setActiveRadio(id);

      const currentState = deepClone(ctx.get());

      const sorter = {
        field: getFactor(id),
        rev: false,
      };

      currentState.insightsV1State.bestByTableSorter = sorter;
      currentState.insightsV1State.bestByRadioValue = id;

      ctx.set(currentState);
    },
    [ctx]
  );

  const onTableRowClick = useCallback(
    (id) => {
      handleTableRowClick(id, currentTab);
    },
    [currentTab, handleTableRowClick]
  );

  return (
    <Box className={styles.main__card__content__tables} px={0} mt={0}>
      <Box display={"flex"} gap={1} flex={1}>
        <Box display={"flex"} flexDirection={"column"} gap={1}>
          <Card
            sx={{
              overflow: "visible",
              height: "fit-content",
            }}
          >
            <CardContent sx={{ display: "flex", p: 1 }}>
              <Box display={"flex"} flex={1} flexDirection={"column"}>
                <Box display={"flex"} flex={1} alignItems={"center"}>
                  <Typography
                    sx={{ color: "#2a7090", fontWeight: "bold" }}
                    gap={1}
                  >
                    Rank
                  </Typography>
                </Box>

                <Box display={"flex"} flexDirection={"column"}>
                  {radioBtns.map((radio) => {
                    return (
                      <FormControlLabel
                        key={uuidv4()}
                        classes={{ root: styles.formControlLabel }}
                        control={
                          <Radio
                            checked={activeRadio === radio.id}
                            size={"small"}
                            onChange={() => onChangeRadio(radio.id)}
                          />
                        }
                        label={radio.label}
                      />
                    );
                  })}
                </Box>
              </Box>
            </CardContent>
          </Card>
          <Card
            sx={{
              overflow: "visible",
              height: "fit-content",
            }}
          >
            <CardContent sx={{ display: "flex", p: 1 }}>
              <Box display={"flex"} flex={1} flexDirection={"column"}>
                <Box display={"flex"} flex={1} alignItems={"center"}>
                  <Typography
                    sx={{ color: "#2a7090", fontWeight: "bold" }}
                    gap={1}
                  >
                    Filter
                  </Typography>
                </Box>

                <Box display={"flex"} flexDirection={"column"}>
                  {checkboxes.map((checkbox) => {
                    return (
                      <FormControlLabel
                        key={uuidv4()}
                        classes={{ root: styles.formControlLabel }}
                        control={
                          <Checkbox
                            checked={activeCheckboxes.indexOf(checkbox.id) >= 0}
                            size={"small"}
                            onChange={(event, checked) =>
                              onCheckboxChange(event, checked, checkbox.id)
                            }
                          />
                        }
                        label={checkbox.label}
                      />
                    );
                  })}
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
        <BestByTable data={strategies} onTableRowClick={onTableRowClick} />
      </Box>
    </Box>
  );
};

type BestByTableProps = {
  data: Dashboard;
  onTableRowClick: Function;
};

const BestByTable = memo(({ data, onTableRowClick }: BestByTableProps) => {
  const ctx = useContext(InsightsContext);

  const benchmark = useMemo(
    () => data?.[0]?.info?.benchmark ?? "Benchmark",
    [data]
  );

  const getDefaultSorter = useCallback(() => {
    const globalState = ctx.get();

    const sorter = globalState.insightsV1State.bestByTableSorter;

    return {
      field: sorter.field,
      direction: sorter.rev === false ? "desc" : "asc",
    };
  }, [ctx]);

  const getAnalytic = useCallback(
    (obj: any, key: keyof typeof ANALYTICS_DICT) => {
      const analyticObj =
        obj?.priceAnalytics?.stats?.[ANALYTICS_DICT?.[key]] ?? null;

      if (analyticObj) {
        return (Object.values(analyticObj)?.[0] as number) ?? null;
      }

      return null;
    },
    []
  );

  const getName = useCallback((strategy) => {
    return strategy.info.label.join("</br>");
  }, []);

  const getType = useCallback((strategy) => {
    let type = "";

    if (strategy?.info?.type != null && strategy?.info?.type.length > 0) {
      type = strategy.info.type.join(" + ");
    }

    return type;
  }, []);

  const wrapData = useCallback(() => {
    const rows: {
      name: string;
      id: number | null;
      year_3_perf_H: number | null;
      year_3_perf_DIFF: number | null;
      year_10_perf_H: number | null;
      year_10_perf_DIFF: number | null;
      ytd: number | null;
      ytd_DIFF: number | null;
      sharpeRatio: number | null;
      type: string;
    }[] = [];

    const strategies = data;

    for (const strategy of strategies!) {
      rows.push({
        name: getName(strategy),
        id: strategy?.info?.id,
        type: getType(strategy),
        year_3_perf_H: getAnalytic(strategy, "year_3_perf_H"),
        year_3_perf_DIFF: getAnalytic(strategy, "year_3_perf_DIFF"),
        year_10_perf_H: getAnalytic(strategy, "year_10_perf_H"),
        year_10_perf_DIFF: getAnalytic(strategy, "year_10_perf_DIFF"),
        ytd: getAnalytic(strategy, "ytd"),
        ytd_DIFF: getAnalytic(strategy, "ytd_DIFF"),
        sharpeRatio: getAnalytic(strategy, "sharpeRatio"),
      });
    }

    return rows;
  }, [data, getAnalytic, getName, getType]);

  const formattedData = useMemo(() => wrapData(), [wrapData]);
  const formatter = useFormatter();

  const nameFormatter = useCallback(
    (cell) => {
      const data = cell.getData();
      const name = data["name"];

      //#region - highlighting the current strategy
      const currentState = ctx.get();

      let id = data?.["id"] ?? null;
      if (id) {
        let selectedStrategyId: string | number | null =
          currentState.selectedStrategy?.id ?? null;
        if (selectedStrategyId != null) {
          selectedStrategyId = parseInt(selectedStrategyId);
          if (id === selectedStrategyId) {
            const row = cell.getRow();
            row.getElement().style.backgroundColor = "rgba(255, 192, 1, 0.2)";
          }
        }
      }
      //#endregion

      const nameSplitted = name.split("__");

      if (nameSplitted?.[1] != null) {
        return `${nameSplitted[0]}</br>${nameSplitted[1]}`;
      }

      return nameSplitted[0];
    },
    [ctx]
  );

  const renderCell = useCallback(
    (cell) => {
      const value = cell.getValue();
      const field = cell.getField();

      if (field === "sharpeRatio") {
        return formatter.custom("number", {
          options: {
            notAvailable: {
              input: null,
              output: "",
            },
            decimals: 4,
          },
          output: "HTML",
          value: cell.getValue(),
        });
      } else {
        let innerHTML = "";

        innerHTML = formatter.custom("number", {
          options: {
            isPercentage: true,
            notAvailable: {
              input: null,
              output: "",
            },
          },
          output: "HTML",
          value: value,
          valueHelper: {
            normalizationThreshold: 1,
          },
        });

        return innerHTML;
      }
    },
    [formatter]
  );

  const prepareHeaderClickTapListener = useCallback(
    (eventCallback?: Function) => {
      return function headerClickTapListener(
        e: UIEvent,
        headerColumn: ColumnComponent
      ) {
        const sorters = headerColumn.getTable().getSorters();

        let actualDirection =
          headerColumn.getDefinition().headerSortStartingDir;
        if (
          sorters.length > 0 &&
          sorters[0].field === headerColumn.getField()
        ) {
          actualDirection = sorters[0].dir === "asc" ? "desc" : "asc";
        }

        if (eventCallback) {
          eventCallback({
            type: "sort",
            value: {
              direction: actualDirection,
              field: headerColumn.getField(),
            },
          });
        }
      };
    },
    []
  );

  const sorterCallback = useCallback(
    (event) => {
      const sorter = event.value;
      const newState = deepClone(ctx.get());

      newState.insightsV1State.bestByTableSorter = {
        field: sorter.field,
        rev: sorter.direction === "desc",
      };

      ctx.set(newState);
    },
    [ctx]
  );

  const columns: any = useMemo(
    () =>
      ctx.get().usedIn !== "RISE"
        ? [
            {
              field: "type",
              title: "Type",
              widthGrow: 1.5,
              hozAlign: "left",
              headerClick: prepareHeaderClickTapListener(sorterCallback),
            },
            {
              field: "name",
              title: "Selection Criteria",
              formatter: nameFormatter,
              widthGrow: 1.5,
              headerClick: prepareHeaderClickTapListener(sorterCallback),
            },
            {
              title: "10 YEARS RETURN",
              headerHozAlign: "center",
              columns: [
                {
                  field: "year_10_perf_H",
                  title: "Strategy",
                  formatter: renderCell,
                  sorter: "number",
                  headerClick: prepareHeaderClickTapListener(sorterCallback),
                },

                {
                  field: "year_10_perf_DIFF",
                  title: `Excess Return vs </br> ${benchmark}`,
                  headerHozAlign: "right",
                  sorter: "number",
                  headerClick: prepareHeaderClickTapListener(sorterCallback),
                  formatter: renderCell,
                },
              ],
            },
            {
              title: "3 YEARS RETURN",
              columns: [
                {
                  field: "year_3_perf_H",
                  title: "Strategy",
                  formatter: renderCell,
                  headerHozAlign: "right",
                  sorter: "number",
                  headerClick: prepareHeaderClickTapListener(sorterCallback),
                },

                {
                  field: "year_3_perf_DIFF",
                  title: `Excess Return vs </br> ${benchmark}`,
                  formatter: renderCell,
                  headerHozAlign: "right",
                  sorter: "number",
                  headerClick: prepareHeaderClickTapListener(sorterCallback),
                },
              ],
            },
            {
              title: "YTD RETURN",
              columns: [
                {
                  field: "ytd",
                  title: "Strategy",
                  formatter: renderCell,
                  headerHozAlign: "right",
                  sorter: "number",
                  headerClick: prepareHeaderClickTapListener(sorterCallback),
                },

                {
                  field: "ytd_DIFF",
                  title: `Excess Return vs </br> ${benchmark}`,
                  headerHozAlign: "right",
                  sorter: "number",
                  headerClick: prepareHeaderClickTapListener(sorterCallback),
                  formatter: renderCell,
                },
              ],
            },
            {
              title: "",

              columns: [
                {
                  field: "sharpeRatio",
                  title: "Sharpe Ratio",
                  formatter: renderCell,
                  headerHozAlign: "right",
                  sorter: "number",
                  headerClick: prepareHeaderClickTapListener(sorterCallback),
                },
              ],
            },
          ]
        : [
            {
              field: "type",
              title: "Type",
              widthGrow: 1.5,
              hozAlign: "left",
              headerClick: prepareHeaderClickTapListener(sorterCallback),
            },
            {
              field: "name",
              title: "Selection Criteria",
              formatter: nameFormatter,
              widthGrow: 1.5,
              headerClick: prepareHeaderClickTapListener(sorterCallback),
            },
            {
              title: "10 YEARS ANNUALIZED RETURN",
              headerHozAlign: "center",
              columns: [
                {
                  field: "year_10_perf_H",
                  title: "Strategy",
                  formatter: renderCell,

                  sorter: "number",
                  headerClick: prepareHeaderClickTapListener(sorterCallback),
                },

                {
                  field: "year_10_perf_DIFF",
                  title: `Excess Return vs </br> ${benchmark}`,
                  headerHozAlign: "right",
                  sorter: "number",
                  headerClick: prepareHeaderClickTapListener(sorterCallback),
                  formatter: renderCell,
                },
              ],
            },
          ],
    [
      benchmark,
      ctx,
      nameFormatter,
      prepareHeaderClickTapListener,
      renderCell,
      sorterCallback,
    ]
  );

  const tableEventsHandlres = useCallback(
    (event) => {
      if (event.type === "rowClick") {
        const data = event.value.getData();

        onTableRowClick(data.id, undefined);
      }
    },
    [onTableRowClick]
  );

  return (
    <Box className={styles.tableBox} gap={1} flex={1}>
      <Card className={styles.tableBox__card} sx={{ boxShadow: 3 }}>
        <CardContent className={styles.tableBox__card__content}>
          <Box display={"flex"} gap={1} flexDirection={"column"}>
            <Box display={"flex"} flexDirection={"column"}>
              <Typography>
                Click on the row to open the relative strategy.
              </Typography>
            </Box>
            <TrendratingTable
              columns={columns}
              data={formattedData}
              disableDefaultRowClick
              eventCallback={tableEventsHandlres}
              autoResize={false}
              sorting={getDefaultSorter()}
              options={{
                ajaxSorting: false,
              }}
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
});
