import { useCallback, useMemo } from "react";
import { deepClone } from "../deepClone";
import { useEnvironment } from "./useEnvironment";

export const handleUnknownNode = (taxonomy) => {
  if (taxonomy && typeof taxonomy !== "string") {
    return taxonomy;
  } else {
    return {
      id: taxonomy,
      name: "Unknown",
      parent: "none",
      rank: null,
      showInTree: true,
      showInWidget: 1,
      type: null,
    };
  }
};

export const useTaxonomyByType = (type: "stock" | "ETF") => {
  const environment = useEnvironment();
  const taxon = environment.get("setup").taxonomies;
  const taxonomies = useMemo(() => deepClone(taxon), [taxon]);
  const subjectType = type === "ETF" ? "ETF" : "security";
  const fieldsMap = environment.get("setup").taxonomyFields;
  const fieldX = type === "ETF" ? "etfgeo" : "country";
  const fieldY = type === "ETF" ? "etfclass" : "icb";
  const taxonomiesMapX = taxonomies[fieldsMap[subjectType][fieldX]];
  const taxonomiesMapY = taxonomies[fieldsMap[subjectType][fieldY]];

  const rootNodeX = Object.values<any>(taxonomiesMapX).find(
    (node) => node.parent == null
  )["id"];
  const rootNodeY = Object.values<any>(taxonomiesMapY).find(
    (node) => node.parent == null
  )["id"];

  const getTaxonomyById = (id: string, taxonomyField: string) => {
    const node =
      taxonomies?.[fieldsMap[subjectType][taxonomyField]]?.[id] ?? id;

    return handleUnknownNode(node);
  };

  const getNodeChildrenLevels = (id: string, taxonomyField: string) => {
    const levels: string[] = [];
    const taxonomyMap = taxonomies?.[fieldsMap[subjectType][taxonomyField]];

    const taxonomyAsList = Object.values<any>(taxonomyMap);

    const crawlTree = (nodeId) => {
      return taxonomyAsList
        .filter((taxonomy) => taxonomy.parent === nodeId)
        .map((childNode) => {
          levels.push(childNode.type);
          return crawlTree(childNode.id);
        });
    };

    crawlTree(id);

    return [...new Set(levels)].sort();
  };

  const advancedNodesSelector = (
    selectedValues: string[],
    taxonomyField: string,
    filterByShowInTree: boolean,
    validLevels?: string[],
    taxonomyType = type
  ) => {
    if (!selectedValues) {
      return [];
    }

    let taxonomyMap =
      taxonomies[
        fieldsMap[taxonomyType === "stock" ? "security" : "ETF"][taxonomyField]
      ];

    // Prepare the taxonomies by filtering it according to the optional params
    if (validLevels) {
      const taxonomiesFilteredByLevels = filterTaxonomiesLevels(
        validLevels,
        taxonomyMap,
        filterByShowInTree
      );
      const updatedTaxonomiesMap = taxonomiesFilteredByLevels.reduce(
        (prev, current) => ({ ...prev, [current.id]: current }),
        {}
      );

      taxonomyMap = updatedTaxonomiesMap;
    } else if (filterByShowInTree) {
      const updatedMap = {};

      Object.values<any>(taxonomyMap).forEach((node) => {
        if (node.showInTree) {
          updatedMap[node.id] = { ...node };
        }
      });

      taxonomyMap = updatedMap;
    }

    const treeMap = {};

    for (const [key, value] of Object.entries<any>(taxonomyMap)) {
      const childrenNumber = Object.values<any>(taxonomyMap).filter(
        (node) => node.parent === value.id
      ).length;

      treeMap[key] = {
        parent: value.parent,
        childrenNumber: childrenNumber === 0 ? 1 : childrenNumber,
        id: key,
      };
    }

    for (const value of selectedValues) {
      const leafRemained = treeMap?.[value]?.childrenNumber ?? null;

      if (!leafRemained) {
        return;
      }

      treeMap[value].childrenNumber = leafRemained - 1;

      const scaleLeaf = (val) => {
        let parentId = val;
        let parent = treeMap[parentId];

        parent["childrenNumber"] = parent["childrenNumber"] - 1;

        if (parent["childrenNumber"] === 0 && parent.parent != null) {
          scaleLeaf(parent.parent);
        }
      };

      if (
        treeMap[value].childrenNumber === 0 &&
        treeMap[value].parent != null
      ) {
        scaleLeaf(treeMap[value].parent);
      }
    }

    const result: any = [];
    const getSelectedValues = (parent = null) => {
      const filtered = Object.values<any>(treeMap).filter(
        (node) => node.parent === parent
      );

      filtered.forEach((child) => {
        if (child.childrenNumber <= 0) {
          result.push(child.id);
        } else {
          getSelectedValues(child.id);
        }
      });
    };

    getSelectedValues();
    return result;
  };

  const filterTaxonomiesLevels = (
    validNodesList,
    taxonomies,
    filterByShowInTree
  ) => {
    let list: any = Object.values(taxonomies);

    if (filterByShowInTree) {
      list = list.filter((node) => node.showInTree);
    }

    const sortedTaxonomyByLevelAsc = list.sort((a, b) => {
      if (a.type > b.type) {
        return 1;
      } else if (a.type < b.type) {
        return -1;
      }

      return 0;
    });

    const filteredListWithRightParents: any = [];
    const rootNode = sortedTaxonomyByLevelAsc[0];
    const validValues = [...validNodesList, rootNode.type];

    for (const node of sortedTaxonomyByLevelAsc) {
      if (node.id === rootNode.id) {
        filteredListWithRightParents.push(node);

        continue;
      }

      const isNodeLevelRight = validValues.indexOf(node.type) !== -1;

      if (isNodeLevelRight && node.parent != null) {
        const parent = taxonomies[node.parent];
        const isParentLevelRight = validValues.indexOf(parent.type) !== -1;

        if (isParentLevelRight) {
          filteredListWithRightParents.push(node);
        } else {
          const newParent = taxonomies?.[parent?.parent] ?? null;
          node["parent"] = newParent?.id ?? null;

          filteredListWithRightParents.push(node);
        }
      }
    }

    return filteredListWithRightParents;
  };

  const getChildrenByNode = (
    nodesIds: string[],
    taxonType: string,
    taxonField: string,
    showInTree: boolean,
    validLevels?: string[]
  ) => {
    const taxonMap = taxonomies[fieldsMap[taxonType][taxonField]];
    let list: any = Object.values(taxonMap);

    if (validLevels) {
      const listFilteredByLevels = filterTaxonomiesLevels(
        validLevels,
        taxonMap,
        showInTree
      );

      list = listFilteredByLevels;
    }

    let result: any = [];

    const navigateTree = (parentId: any = null) =>
      list
        .filter((element) => element.parent === parentId)
        .forEach((node) => {
          result.push(node);

          navigateTree(node.id);
        });

    for (const id of nodesIds) {
      navigateTree(id);
    }

    return result;
  };

  const getTaxonomyMap = useCallback(
    (type: "security" | "ETF" | "Stock", field: string) =>
      taxonomies[fieldsMap[type][field]],
    [fieldsMap, taxonomies]
  );

  return {
    taxonomiesMapX,
    taxonomiesMapY,
    rootNodeX,
    rootNodeY,
    fieldX,
    fieldY,
    getTaxonomyById,
    getNodeChildrenLevels,
    advancedNodesSelector,
    filterTaxonomiesLevels,
    getChildrenByNode,
    getTaxonomyMap,
  };
};
