import { useContext, useMemo, useState, useCallback } from "react";
import { FilterTaxonomies } from "../../../../../../../components/FilterTaxonomies/FilterTaxonomies";
import { deepClone } from "../../../../../../../deepClone";
import { useTaxonomyByType } from "../../../../../../../hooks/useTaxonomyByType";
import { FiltersContext } from "../../FilterRowContraints";

type AssetClassFilterProps = {
  label: string;
};

export function AssetClassFilter({ label }: AssetClassFilterProps) {
  const context = useContext(FiltersContext);
  const [panelValues, setPanelValues] = useState<string[]>([]);
  const [showCancelButton, setShowCancelButton] = useState(false);
  const taxonomy = useMemo(() => ({ type: "ETF", field: "etfclass" }), []);
  const { getTaxonomyMap, advancedNodesSelector } = useTaxonomyByType("ETF");
  const icbTaxonomy = useMemo(() => {
    return getTaxonomyMap(taxonomy.type as "ETF" | "security", taxonomy.field);
  }, [getTaxonomyMap, taxonomy.field, taxonomy.type]);
  const rootNode = useMemo(
    () => Object.values<any>(icbTaxonomy).find((taxon) => taxon.parent == null),
    [icbTaxonomy]
  );
  const validLevels = useMemo(
    () => ["1 Industry", "3 Sector", "4 Subsector"],
    []
  );

  const handleAssetClassSelection = useCallback(
    (filtersDirty) => {
      const segments = deepClone(filtersDirty[0].value.segments);
      const filters = advancedNodesSelector(
        segments,
        taxonomy.field,
        true,
        validLevels
      );

      const assetClassFilter = {
        type: "filters",
        value: {
          dimension: taxonomy.field,
          segments: filters,
        },
      };

      context?.updateAssetClass([assetClassFilter]);
    },
    [advancedNodesSelector, context, taxonomy.field, validLevels]
  );

  const activeValues = useMemo(() => {
    if (context && context.assetClass) {
      const values = context.assetClass;
      const taxonomiesIds: string[] = [];

      values.forEach((value) => {
        taxonomiesIds.push(...value.value.segments);
      });

      setPanelValues(taxonomiesIds);

      if (taxonomiesIds.length) {
        setShowCancelButton(true);
        if (taxonomiesIds.length > 3) {
          const firstSelected = icbTaxonomy?.[taxonomiesIds[0]]?.name ?? "";
          return [firstSelected, `+ ${taxonomiesIds.length - 1}`];
        } else {
          return taxonomiesIds.map((id) => icbTaxonomy?.[id]?.name ?? "");
        }
      }
    }

    setShowCancelButton(false);
    return rootNode?.name ? [rootNode.name] : [];
  }, [context, icbTaxonomy, rootNode]);

  const clearFitlers = useCallback(
    (e) => {
      e.stopPropagation();
      context?.updateAssetClass([]);
    },
    [context]
  );

  const columns = useMemo(
    () => [
      [
        {
          taxonomyId: "ETF_EQ",
          children: [
            [
              { taxonomyId: "ETF_EQ_SR" },
              { taxonomyId: "ETF_EQ_FO" },
              { taxonomyId: "ETF_EQ_SZ" },
            ],
          ],
        },
        { taxonomyId: "ETF_AL" },
      ],
      [
        {
          taxonomyId: "ETF_FI",
          children: [
            [
              { taxonomyId: "ETF_FI_BM" },
              { taxonomyId: "ETF_FI_CO" },
              { taxonomyId: "ETF_FI_GO" },
              { taxonomyId: "ETF_FI_MU" },
              { taxonomyId: "ETF_FI_SO" },
            ],
          ],
        },
      ],
      [
        { taxonomyId: "ETF_AA" },
        { taxonomyId: "ETF_CU" },
        {
          taxonomyId: "ETF_CO",
          children: [
            [
              { taxonomyId: "ETF_CO_ME" },
              { taxonomyId: "ETF_CO_AG" },
              { taxonomyId: "ETF_CO_EN" },
            ],
          ],
        },
      ],
    ],
    []
  );

  return (
    <FilterTaxonomies
      showIconCancel={showCancelButton}
      taxonomyToRender={taxonomy}
      updateFilters={handleAssetClassSelection}
      filtersValues={panelValues}
      selectedFilters={activeValues}
      label={label}
      validTreeNodes={validLevels}
      removeFilter={clearFitlers}
      columnsDefinition={columns}
    />
  );
}
