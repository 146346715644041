/* eslint-disable no-template-curly-in-string */
export const translation = {
  Back_to_login: "Back to login",
  Browser_not_supported: "Browser not supported",

  Combine_Systematic_Portfolios: "Combine Systematic Portfolios",

  Disclaimer: "Disclaimer",

  Email: "Email",
  Email_is_not_valid: "Email is not valid",
  Entering_You_agree_with_the_Trendrating:
    "Entering You agree with the Trendrating",

  Forgot_password: "Forgot password?",

  Http_error_504: "Server issue (504 Gateway)",

  Impersonate: "Impersonate",
  Impersonate_using_your_Trendrating_account:
    "Impersonate using your Trendrating account",
  Impersonating: "Impersonating ...",

  Login: "Login",
  Login_with_your_Trendrating_account: "Login with your Trendrating account",
  Logging_in: "Logging in...",

  mailto: "support@trendrating.net?subject=%5BLogin%5D%20Need%20help",

  Need_help: "Need help?",
  New_password: "New password",

  Password: "Password",
  Password_is_not_valid: "Password is not valid",

  Recover: "Recover",
  Recover_your_password: "Recover your password",
  Recovery_link_expired:
    "Recovery link expired.  Maybe you click on it 2 times.",
  Request_a_trial: "Request a trial",
  Retype_new_password: "Retype new password",

  Sending_email: "Sending email ...",
  Set: "Set",

  The_two_passwords_are_different: "The two passwords are different",
  Trendrating_disclaimer: "Trendrating disclaimer",

  We_sent_you_an_email_Please_check_your_mail_and_follow_instructions:
    "We sent you an email. Please, check your mail and follow instructions.",
  Wrong_email_and_or_password: "Wrong email and/or password.",

  // #region -------------------------------------------------------- 2020
  "1_Day": "1 Day",
  "1_Month": "1 Month",
  "1_Week": "1 Week",
  "1_d": "1d",
  "1_m": "1m",
  "1_m_Downgrades": "1m Downgrades",
  "1_m_Downgrades_percentage": "1m Downgrades %",
  "1_m_Upgrades": "1m Upgrades",
  "1_m_Upgrades_percentage": "1m Upgrades %",
  "1_w": "1w",
  "_%_positive_months": "% positive months",
  "_%_positive_quarters": "% positive quarters",
  "_%_positive_weeks": "% positive weeks",
  $name_is_$rebalance_strategy:
    "<strong>${name}</strong> is <em>${rebalance}</em> strategy",
  // #region ------------------------- A ---------------------------------
  avg: "avg",
  AB_percentage: "AB %",
  AB_changes: "AB% changes",
  Add_to: "Add to",
  Advanced: "Advanced",
  Advanced_strategy: "Advanced strategy",
  All_baskets: "All baskets",
  Allocation: "Allocation",
  An_error_occurred_while_generating_the_report:
    "An error occurred while generating the report",
  Analytic: "Analytic",
  Analytics: "Analytics",
  Analyze: "Analyze",
  Annualized: "Annualized",
  Any: "Any",
  Apply_a_smart_beta_strategy_and_increase_A_rated_positions_decrease_D_and_C:
    'Apply a smart beta strategy and increase <span class="rate rate--A">A</span> rated positions, decrease <span class="rate rate--D">D</span> and <span class="rate rate--C">C</span>',
  Area: "Area",
  Areas: "Areas",
  Auto_rebalance: "Auto-rebalance",
  Average: "Average",
  Average_yearly_drawdown: "Average yearly drawdown",
  alerts: "Alerts",
  analysis_instrument: "Security analysis",
  analysis_market: "Markets",
  analysis_list: "Portfolio analysis",
  any: "Any",
  approach: "approach",
  // #endregion ----------------------------------------------------------

  // #region ------------------------- B ---------------------------------
  Back: "Back",
  Back_to_your_Systematic_portfolios: "Back to your Systematic portfolios",
  Backtest_Combined_Strategies: "Backtest Combined Strategies",
  Backtesting: "Backtesting",
  Benchmark: "Benchmark",
  Beta: "Beta",
  Buy: "Buy",
  Buy_and_Sell: "Buy and Sell",
  Buy_and_sell_positions_in_order_to_match_the_alpha_strategy:
    "Buy and sell positions in order to match the alpha strategy",
  baskets: "Baskets",
  button_apply: "Apply",
  button_cancel: "Cancel",
  button_continue: "Continue",
  button_create: "Create",
  button_close: "Close",
  button_edit: "Edit",
  button_export: "Export",
  button_load: "Load",
  button_remove: "Delete",
  button_rename: "Rename",
  button_report: "Report",
  button_save: "Save",
  button_save_as: "Save as",
  button_show: "Show",
  // #endregion ----------------------------------------------------------

  // #region ------------------------- C ---------------------------------
  Cancel: "Cancel",
  Changes: "Changes",
  Chart: "Chart",
  Checking_relations: "Checking relations ...",
  Checking_template_data: "Checking template data...",
  Choose_the_optimization_methodology: "Choose the optimization methodology",
  Close: "Close",
  Combine_Strategies: "Combine Strategies",
  Combined_strategy: "Combined strategy",
  Compare: "Compare",
  Compare_to: "Compare to",
  Compare_Strategies: "Compare Strategies",
  Constituents: "Constituents",
  Contribution: "Contribution",
  Could_not_delete_item: "Could not delete <strong>${name}</strong>.",
  Could_not_unsubscribe_item: "Could not unsubscribe <strong>${name}</strong>.",
  Create_a_PDF_report: "Create a PDF report",
  Create_Advanced_Strategy: "Create Advanced Strategy",
  Create_Combined_Strategy: "Create Combined Strategy",
  Create_Momentum_Alpha: "Create Alpha Strategy",
  Create_Momentum_Macro_Rotation: "Create Macro Rotation Strategy",
  Create_Momentum_Smart_Beta: "Create Smart Beta Strategy",
  Create_basket: "Create basket",
  Create_portfolio: "Create portfolio",
  Create_strategy: "Create strategy",
  Combined_Systematic_Portfolios: "Combined Systematic Portfolios",
  Cumulative: "Cumulative",
  Currency: "Currency",
  Custom_Systematic_Portfolios: "Custom Systematic Portfolios",
  commodities: "commodities",
  commodity: "commodity",
  create_automatic_ranking:
    "Create your own ranking for your portfolios and investment universes.<br>Track your rankings to stay on top of new emerging trends.",
  create_automatic_ranking_arrow: "Start Now",
  create_systematic_portfolio:
    "Create your own systematic portfolio.<br/>Track your strategy to stay on top of new emerging trends.",
  create_systematic_portfolio_arrow: "Start Now",
  Cumulative_excess_return: "Cumulative excess return",
  currencies: "currencies",
  currency: "currency",
  // #endregion ----------------------------------------------------------

  // #region ------------------------- D ---------------------------------
  Data_is_loading_please_wait: "Data is loading, please wait ...",
  Date: "Date",
  Default: "Default",
  Delete: "Delete",
  Detailed: "Detailed",
  Difference: "Difference",
  Dispersion: "Dispersion",
  Downgraded_from: "Downgraded from",
  Downgraded_to_from: "Downgraded to ${new} from ${old}",
  Downgrades: "Downgrades",
  Downgrades_percentage: "Downgrades %",
  Drifting: "Drifting",
  data_visualization: "Data visualization",
  date: "Date",
  default_ranking: "Default ranking",
  dialog_remove: "Are you sure to delete <strong>${name}</strong>?",
  dialog_ranking_create_title: "Create ranking",
  dialog_ranking_delete_title: "Delete ranking",
  dialog_unsubscribe: "Are you sure to unsubscribe <strong>${name}</strong>?",
  downgrades: "Downgrades",
  // #endregion ----------------------------------------------------------

  // #region ------------------------- E ---------------------------------
  ETF: "ETF",
  ETFs: "ETFs",
  Edit: "Edit",
  Edit_$name: "Edit ${name}",
  Edit_Ranking: "Edit Ranking",
  Error_running_strategy: "Error running strategy",
  Error_while_saving_item_already_exists:
    "Error while saving. <strong>${name}</strong> already exists.",
  Export_buy_and_sell: "Export buy and sell",
  Export_orders_to_MS_Excel: "Export orders to MS Excel",
  email: "Email",
  // #endregion ----------------------------------------------------------

  // #region ------------------------- F ---------------------------------
  Filters: "Filters",
  Finder: "Finder",
  Fixed: "Fixed",
  Focus: "Focus",
  From_a_basket: "From a basket",
  feedback_delete_success: "<strong>${name}</strong> has been deleted.",
  feedback_length_too_short:
    "${property} must be long at least ${length} characters.",
  feedback_invalid_characters: '${property} cannot contain /,\\,:,*,?,",<,>,|',
  feedback_please_select_portfolio_basket:
    "Please select a Portfolio / Basket.",
  fieldset_ranking_criteria: "Set ranking criteria",
  fieldset_ranking_optional: "Optional",
  fieldset_ranking_target: "Rank the following",
  feedback_strategy_backtesting_no_fundamentals:
    "Fundamentals are only available for up to 10 years in the past.",
  feedback_the_basket_does_not_contain_securities_rated_A_or_B:
    "These measures are defined only for positive trends.<br/>The basket does not contain securities rated A or B.",
  feedback_the_portfolio_does_not_contain_securities_rated_A_or_B:
    "These measures are defined only for positive trends.<br/>The portfolio does not contain securities rated A or B.",
  from: "from",
  // #endregion ----------------------------------------------------------

  // #region ------------------------- G ---------------------------------
  Generating_report_please_wait: "Generating report, please wait ...",
  // #endregion ----------------------------------------------------------

  // #region ------------------------- H ---------------------------------
  Hedging: "Hedging",
  Hide_industries: "Hide ${name} industries",
  Hold: "Hold",
  Holdings: "Holdings",
  help: "Help",
  highlight: "Highlight",
  http_error_already_exists:
    "The same name / id is already used. Please change.",
  http_error_unknown: "An error occured. Try to reload the page.",
  // #endregion ----------------------------------------------------------

  // #region ------------------------- I ---------------------------------
  If_the_problem_persists_contact_the_Trendrating_Customer_Care:
    'If the problem persists, contact the <a href="mailto:support@trendrating.net?subject=Page%20not%20found">Trendrating Customer Care</a>',
  If_you_have_the_SMS_Premium_:
    "If you have the <strong>SMS + Premium</strong>, probably you switched product in another tab and the requested function is not available in the current one.",
  If_you_think_something_is_not_working_properly:
    "If you think something is not working properly",
  Implement_strategy: "Implement strategy",
  Index_weighting_based_on_momentum_factor:
    "Strategy weighting based on Trend Rating",
  Industry: "Industry",
  Industries: "Industries",
  Information_ratio: "Information ratio",
  Instruments: "Instruments",
  Instrument_type: "Instrument type",
  Investment_universe: "Investment universe",
  index: "index",
  indices: "indices",
  instrument: "Instrument",
  instrument_commodity: "Commodity",
  instrument_commodity_plural: "Commodities",
  instrument_currency: "Currency",
  instrument_currency_plural: "Currencies",
  instrument_etf: "ETF",
  instrument_etf_plural: "ETFs",
  instrument_index: "Index",
  instrument_index_plural: "Indices",
  instrument_sector: "Sector",
  instrument_sector_plural: "Sectors",
  instrument_status_expired: "Expired",
  instrument_status_unavailable: "Currently unavailable",
  instrument_stock: "Stock",
  instrument_stock_plural: "Stocks",
  // #endregion ----------------------------------------------------------

  // #region ------------------------- K ---------------------------------
  Key_facts: "Key facts",
  Key_ratios: "Key ratios",
  // #endregion ----------------------------------------------------------

  // #region ------------------------- L ---------------------------------
  Last_20_working_days: "Last 20 working days",
  Last_month: "Last month",
  Last_month_changes: "Last month changes",
  Last_week: "Last week",
  Last_week_changes: "Last week changes",
  Last_used_ranking: "Last used ranking",
  Load: "Load",
  Load_another_portfolio_or_basket: "Load another portfolio or basket",
  Logarithmic: "Logarithmic",
  last_week: "last week",
  listed_in: "listed in",
  loading_data: "Loading ...",
  loading_ranking: "Computing ranking ...",
  // #endregion ----------------------------------------------------------

  // #region ------------------------- M ---------------------------------
  Manage_subscriptions: "Manage subscriptions",
  Market: "Market",
  Markets: "Markets",
  Max_drawdown: "Max drawdown",
  Max_consecutive_negative_months: "Max consecutive negative months",
  Max_consecutive_negative_quarters: "Max consecutive negative quarters",
  Max_consecutive_negative_weeks: "Max consecutive negative weeks",
  Max_consecutive_positive_months: "Max consecutive positive months",
  Max_consecutive_positive_quarters: "Max consecutive positive quarters",
  Max_consecutive_positive_weeks: "Max consecutive positive weeks",
  max: "max",
  min: "min",
  Macro_allocation_based_on_momentum_factor: "Allocation based on Macro Trends",
  Momentum: "Momentum",
  Momentum_Alpha: "Alpha Strategy",
  Momentum_Macro_Rotation: "Macro Rotation Strategy",
  Momentum_Smart_Beta: "Smart Beta Strategy",
  Month: "Month",
  Monthly_standard_deviation: "Monthly standard deviation",
  More_information: "More information",
  Mkt_Cap: "Mkt. Cap.",
  mismatch_long_short_strategies_granularity:
    "Both strategies have different rebalance settings. Continue?",
  mismatch_long_short_strategies_granularity_title: "Granularity mismatch",
  missing_compare_strategies: "Please select two strategies to compare.",
  missing_compare_strategies_title: "No strategies selected",
  missing_long_short_strategies: "Please select two strategies to combine.",
  missing_long_short_strategies_title: "No strategies selected",
  missing_portfolio_basket: "Missing portfolio/basket",
  missing_ranking_against:
    "Missing <strong>Highlight</strong> portfolio/basket.",
  missing_ranking_target:
    "Missing <strong>Rank the following</strong> portfolio/basket.",
  movers: "Movers",
  // #endregion ----------------------------------------------------------

  // #region ------------------------- N ---------------------------------
  Name: "Name",
  Neutral_strategy: "Neutral strategy",
  Neutral_strategy_equal_weighted: "Neutral strategy equal weighted",
  New_basket: "New basket",
  New_portfolio: "New portfolio",
  Next_rebalance: "Next rebalance",
  No_changes: "No changes",
  No_options: "No options",
  No_strategies_available: "No strategies available",
  Numbers: "Numbers",
  name: "Name",
  no_data_to_display: "No data to display",
  Not_enough_stocks_for_dispersion: "Not enough stocks for dispersion.",
  number_of_instruments: "Number of instruments",
  // #endregion ----------------------------------------------------------

  // #region ------------------------- O ---------------------------------
  OK: "OK",
  One_way_yearly_turnover: "One way yearly turnover",
  Optimize: "Optimize",
  Optimized_portfolio: "Optimized portfolio",
  Optimize_$name: "Optimize ${name}",
  Or_you_can: "Or you can",
  Or_you_can_select_manually_which_security_to_swap:
    "Or you can select manually which security to swap",
  Overview: "Overview",
  Overwrite: "Overwrite",
  Overwrite_historical_portfolio_with_strategy_backtesting:
    "Overwrite historical portfolio with strategy backtesting",
  of: "of",
  // #endregion ----------------------------------------------------------

  // #region ------------------------- P ---------------------------------
  Page_not_found: "Page not found",
  Percentage: "Percentage",
  Percentages: "Percentages",
  Performance: "Performance",
  Performance_dispersion: "Performance Dispersion",
  Please_select_your_optimization_approach:
    "Please select your optimization approach",
  Point_in_time: "Point in time",
  Portfolio: "Portfolio",
  Portfolio_Basket_generic_error_:
    '<p>${message}<br /><br /> <a href="${link}">Back to Portfolios / Baskets</a>.</p>',
  Portfolio_Basket_not_found_:
    '<h1>Portfolio / Basket not found</h1><p>Probably you deleted it. <a href="${link}">Back to Portfolios / Baskets</a>.</p>',
  Print: "Print",
  Probably_you_deleted_it: "Probably you deleted it.",
  Products: "Solutions",
  password: "Password",
  portfolio_basket: "Portfolio / Basket",
  portfolios: "Portfolios",
  preferences: "Preferences",
  // #endregion ----------------------------------------------------------

  // #region ------------------------- Q ---------------------------------
  Quarter: "Quarter",
  // #endregion ----------------------------------------------------------

  // #region ------------------------- R ---------------------------------
  Rank: "Rank",
  Ranking: "Ranking",
  Rating_history: "Rating history",
  Rationale: "Rationale",
  Ratio: "Ratio",
  Region: "Region",
  Regions: "Regions",
  Relations: "<strong>${name}</strong> is used by:",
  Relative_performance: "Relative performance",
  Remove: "Delete",
  Reversals: "Reversals",
  Risk: "Risk",
  Run: "Run",
  ranking: "Ranking",
  rankings: "Rankings",
  rating_history: "rating history",
  // #endregion ----------------------------------------------------------

  // #region ------------------------- S ---------------------------------
  Save: "Save",
  Save_as_a_copy_with_a_new_name: "Save as a copy with a new name",
  Save_as: "Save as",
  Scale_to_benchmark: "Scale to benchmark",
  Search_ISIN_Ticker_Name: "Search ISIN, Ticker, Name",
  Search_only_in: "Search only in",
  Searching_for_alternatives: "Searching for alternatives",
  Sector: "Sector",
  Sectors: "Sectors",
  Securities: "Securities",
  See_our_user_guide: "See our user guide",
  Segment: "Segment",
  Select_basket: "Select basket",
  Select_report_type: "Select report type",
  Select_strategy: "Select strategy",
  Select_the_Portfolio: "Select the Portfolio",
  Sell: "Sell",
  Sell_$holding_and_buy_$alternative: "Sell ${holding} and buy ${alternative}",
  Sell_D_and_C_rated_positions_swapping_them_to_A_rated:
    'Sell <span class="rate rate--D">D</span> and <span class="rate rate--C">C</span> rated positions swapping them to <span class="rate rate--A">A</span> rated',
  Security_type_Common_Stock: "Security type: Common Stock",
  Set_benchmark: "Set benchmark",
  Set_name: "Set name",
  Sharpe_ratio: "Sharpe ratio",
  Show: "Show",
  Show_action_menu: "Show action menu",
  Show_changes_from: "Show changes from",
  Show_Industries: "Show Industries",
  Show_industries: "Show ${name} industries",
  Size: "Size",
  Sort_by: "Sort by",
  Sortino_ratio: "Sortino ratio",
  Spot: "Spot",
  Starting_portfolio: "Starting portfolio",
  Sterling_ratio: "Sterling ratio",
  Stocks_selection_based_on_momentum_factor:
    "Stocks selection based on Trend Ratings",
  Stop_tracking: "Stop tracking",
  Strategic_allocation: "Strategic allocation",
  Strategies: "Strategies",
  Strategy: "Strategy",
  Strategy_and_approach: "Strategy and approach",
  Strategy_builder: "Strategy builder",
  Strategy_not_found: "Strategy not found",
  Subscribed_Systematic_Portfolios: "Subscribed Systematic Portfolios",
  Subscribe: "Subscribe",
  Subscription: "Subscription",
  Swap: "Swap",
  Swap_$ticker_to_a_similar_$type_with_A_rating:
    'Swap ${ticker} to a similar ${type} with <strong class="rate rate--A">A</strong> rating',
  Switch_product: "Change solution",
  Systematic: "Systematic",
  Systematic_portfolio: "Systematic portfolio",
  Systematic_portfolio_not_found: "Systematic portfolio not found",
  save_columns_configuration: "Save columns configuration",
  screened_universe: "Screened universe",
  screening: "Screening",
  sector: "sector",
  sectors: "sectors",
  see_more: "See more",
  shared_objects: "Shared Objects",
  size: "Size",
  some_sectors: "Some sectors",
  stock: "stock",
  stocks: "stocks",
  strategies_compare: "Compare strategies",
  strategies_long_short: "Backtest strategy combination",
  subscribe: "subscribe",
  systematic_portfolios: "Systematic portfolios",
  // #endregion ----------------------------------------------------------

  // #region ------------------------- T ---------------------------------
  TCR: "TCR",
  TCR_1_Day_Chages: "1 Day Changes",
  TCR_1_Month_Chages: "1 Month Changes",
  TCR_1_Month_Reversal: "1 Month Reversal",
  TCR_1_Week_Chages: "1 Week Changes",
  TCR_1_Week_Reversal: "1 Week Reversal",
  TCR_AB_changes_upgrades_downgrades: "TCR and AB% changes, Up/Downgrades",
  TCR_alert: "TCR alert",
  Tactical_allocation: "Tactical allocation",
  The_portfolio_has_weight_equal_to_0_Please_set_weights_for_positions:
    "The portfolio has weight equal to 0. Please, set weights for positions",
  There_are_$D_D_rated_and_$C_C_rated_securities_that_match_$weight_of_the_portfolio:
    'There are ${D} <strong class="rate rate--D">D</strong> rated and ${C} <strong class="rate rate--C">C</strong> rated securities that match ${weight} of the portfolio.',
  There_are_$total_$type: "There are ${total} ${type}",
  There_are_no_instruments_in_selected_universe:
    "There are no instruments in the selected investment universe. Please review the investment universe settings.",
  There_are_no_trending_$etfClass_$etfGeo_listed_in_$where:
    "There are no trending ${etfClass} ${etfGeo}, listed in ${where}",
  There_are_no_trending_$marketcap_$type_in_$where_$what:
    "There are no trending ${marketcap} ${type} in ${where}, ${what}",
  There_is_$total_$type: "There is ${total} ${type}",
  Tracked_strategies: "Tracked strategies",
  Treynor_ratio: "Treynor ratio",
  those_curated_by_Trendating: "those curated by Trendating.",
  Timeframe: "Timeframe",
  Today: "Today",
  To_delete_item_please_edit_items_above:
    "To delete <strong>${name}</strong> please edit the items above.",
  To_unsubscribe_item_please_edit_items_above:
    "To unsubscribe <strong>${name}</strong> please edit the items above.",
  Top_Bottom: "Top / Bottom",
  Tracking_error: "Tracking error",
  Trend_analysis: "Trend analysis",
  Try_to_login_again: "Try to login again",
  Turnover: "Turnover",
  tcr: "TCR",
  ticker: "Ticker",
  title_export: "Export data of ${name}",
  title_ranking_create: "Create a new Automatic Ranking",
  title_ranking_edit: "Edit ${name}'s options and constraints",
  title_ranking_export:
    "Export ${name} in a Microsoft Excel compatible format (.csv)",
  title_ranking_remove: "Delete ${name} from your Automatic Rankings",
  title_ranking_show: "Show ${name}'s options and constraints",
  title_report: "Create a PDF report for ${name}",
  to: "to",
  today: "today",
  // #endregion ----------------------------------------------------------

  // #region ------------------------- U ---------------------------------
  Unavailable: "Unavailable",
  Undo_save: "Undo save",
  Universe_is_empty: "Universe is empty",
  Unknown_error: "Unknown error",
  Unsubscribe: "Unsubscribe",
  Upcoming_Rebalances: "Upcoming Rebalances",
  Upgrade_position_ratings: "Upgrade position ratings",
  Upgraded_from: "Upgraded from",
  Upgraded_to_from: "Upgraded to ${new} from ${old}",
  Upgrades: "Upgrades",
  Upgrades_Downwrades: "Upgrades/Downgrades",
  Upgrades_Downwrades_percentage: "Upgrades/Downgrades %",
  Upgrades_percentage: "Upgrades %",
  unknown: "#UNKNOWN",
  unknown_error: "Unknown error. Please, contact the Trendrating Support.",
  upgrades: "Upgrades",
  // #endregion ----------------------------------------------------------

  // #region ------------------------- V ---------------------------------
  Value: "Value",
  Visualization: "Visualization",
  VS: "VS",
  // #endregion ----------------------------------------------------------

  // #region ------------------------- W ---------------------------------
  Warning: "Warning",
  Week: "Week",
  Weights: "Weights",
  Weights_management_tooltip:
    "Fixed weights: the security weights remain the same until changed.\nDrifting weights: the security weights float over the time to reflect the prior day's closing prices",
  Whole_universe: "Whole universe",
  World: "World",
  World_wide: "World wide",
  what_s_new: "What's new",
  with: "with",
  white_list: "White list",
  wrong_email_and_or_password: "Wrong email and/or password.",
  // #endregion ----------------------------------------------------------

  // #region ------------------------- Y ---------------------------------
  Yearly_average: "Yearly average",
  You_still_not_created_any_portfolio_or_basket_You_can:
    "You still haven't created any portfolio or basket. You can",
  years: "years",
  you_did_not_save_any_ranking_yet: "You did not save any ranking yet.",
  // #endregion ----------------------------------------------------------

  // #endregion ----------------------------------------------------------

  // ------------------------------------------------------ commons labels
  c_action_analyze: "Analyze",
  c_action_apply: "Apply",
  c_action_compare: "Compare",
  c_action_delete: "Delete",
  c_action_duplicate: "Duplicate",
  c_action_edit: "Edit",
  c_action_equalize: "Equal weights",
  c_action_export: "Export",
  c_action_import: "Import from Excel",
  c_action_optimize: "Optimize",
  c_action_normalize: "100%",
  c_action_rank: "Rank",
  c_action_remove: "Remove",
  c_action_rename: "Rename",
  c_action_report: "Report",
  c_after_capping: "After capping",
  c_alerts: "Alerts",
  c_alerts_more: "more",
  c_alerts_confirmation: "Confirmation",
  c_alerts_downtrend: "Downtrend",
  c_alerts_reversal: "Reversal",
  c_alerts_title_more: "Click to see all alerts on the right panel",
  c_alerts_uptrend: "Uptrend",
  c_allocation: "Allocation",
  c_analytic: "Analytic",
  c_analytics: "Analytics",
  c_analytics_daily: "1 day",
  c_analytics_inception: "Inception",
  c_analytics_inception_total: "Inception",
  c_analytics_monthly: "1 month",
  c_analytics_quarterly: "3 months",
  c_analytics_weekly: "1 week",
  c_analytics_month_to_date: "MTD",
  c_analytics_quarter_to_date: "QTD",
  c_analytics_year_to_date: "YTD",
  c_analytics_yearly: "12 months",
  c_analytics_3_years: "3 Years",
  c_analytics_5_years: "5 Years",
  c_auto_rebalance: "Auto-rebalance",
  c_benchmark: "Benchmark",
  c_before_capping: "Before capping",
  c_button_cancel: "Cancel",
  c_button_close: "Close",
  c_button_create: "Create",
  c_button_delete: "Delete",
  c_button_edit: "Edit",
  c_button_import: "Import",
  c_button_next: "Next",
  c_button_previous: "Previous",
  c_button_rebalance: "Rebalance",
  c_button_rename: "Rename",
  c_button_save: "Save",
  c_button_set: "Set",
  c_button_yes: "Yes",
  c_comparison: "Comparison",
  c_constituent: "Constituent",
  c_constraint_list_name_length: "Name must be long at least 3 characters",
  c_constraint_list_name_allowed_characters:
    'Name cannot contain /,\\,:,*,?,",<,>,|',
  c_currency: "Currency",
  c_currency_local: "Local",
  c_date: "Date",
  c_date_create: "Created",
  c_date_edit: "Last edited",
  c_date_interval_from: "From",
  c_date_interval_to: "to",
  c_date_update: "Updated",
  c_dialog_delete: "Are you sure to delete <strong>${name}</strong>?",
  c_dialog_rename: "Rename <strong>${name}</strong> in",
  c_difference: "Difference",
  c_entitled: "Entitled",
  c_ex_ante: "Ex-ante",
  c_exposure: "Exposure",

  c_feedback_list_empty: "Portfolio is empty",
  c_feedback_list_delete_error: "Error deleting <strong>${name}</strong>.",
  c_feedback_list_delete_success: "<strong>${name}</strong> has been deleted.",
  c_feedback_list_duplicate_error:
    "Error duplicating <strong>${name}</strong>.",
  c_feedback_list_duplicate_success:
    "<strong>${name}</strong> has been duplicated.",
  c_feedback_list_loading: "Computing data ...",
  c_feedback_list_rename_error:
    "Error renaming <strong>${nameSource}</strong>. <strong>${nameTarget}</strong> already exists.",
  c_feedback_list_rename_success:
    "<strong>${nameSource}</strong> has been renamed as <strong>${nameTarget}</strong>.",

  c_feedback_no_alert: "There are no alerts ${timeframe}",
  c_feedback_no_baskets: "Click on <strong>Create basket</strong> to start.",
  c_feedback_no_data: "No data to be displayed",
  c_feedback_no_portfolios:
    "Click on <strong>Create portfolio</strong> to start.",

  c_feedback_systematic_product_ex_ante_list_computing:
    "Computing ex ante portfolio",
  c_feedback_systematic_product_ex_ante_list_refreshing:
    "Refreshing ex ante portfolio",
  c_feedback_systematic_product_export:
    "Error exporting data for systematic product <strong>${name}</strong>.",
  c_feedback_systematic_product_saving: "Saving systematic product",
  c_feedback_systematic_product_rebalancing: "Rebalancing",
  c_feedback_systematic_product_strategy_computing: "Computing strategy",
  c_feedback_systematic_product_strategy_deleted:
    "The strategy has been deleted.",

  c_header_events: "Relevant Events",
  c_header_events_in: "Relevant events in",
  c_holding: "Holding",
  c_holding_holds: "Holding Holds",

  // c_header_summary: "Summary",
  c_import_multiple_matches: "Instruments with multiple matches",
  c_import_unrecognized: "Unrecognized instruments",
  c_in: "in",
  c_in_segment: "in segment",
  c_inception_date: "Inception date",
  c_inception_value: "Inception value",
  c_instrument: "Instrument",
  c_label_no: "No",
  c_label_yes: "Yes",
  c_list_action_analyze: "Analyze",
  c_list_action_analyze_title: "Analyze ${name}",
  c_list_action_create: "Create ${listTag}",
  c_list_action_create_title: "Create a new ${listTag}",
  c_list_action_edit: "Edit",
  c_list_action_edit_title: "Edit ${name}",
  c_list_basket: "Basket",
  c_list_basket_monitoring: "Basket Monitoring",
  c_list_baskets: "Baskets",
  c_list_portfolio: "Portfolio",
  c_list_portfolio_monitoring: "Portfolio Monitoring",
  c_list_portfolios: "Portfolios",
  c_load: "Load",
  c_loading: "Loading ...",
  c_name: "Name",
  c_new: "New",
  c_new_high: "High",
  c_new_highs: "Highs",
  c_new_low: "Low",
  c_new_lows: "Lows",
  c_no_options: "No options",
  c_not_set: "Not set",
  c_off: "Off",
  c_on: "On",
  c_others: "Others",
  c_percentage_ab: "AB%",
  c_placeholder_add_instrument: "Add new holding",
  c_placeholder_lookup_instrument: "Search ISIN, Ticker, Name",
  c_placeholder_lookup_peer: "Market lookup",
  c_placeholder_set_benchmark: "Set benchmark",
  c_portfolio: "Portfolio",
  c_position_buy: "Buy",
  c_position_decrease: "Decrease",
  c_position_increase: "Increase",
  c_position_sell: "Sell",
  c_premium: "Premium",
  c_rank: "Rank",
  c_rebalance: "Rebalance",
  c_rebalance_05_days: "Weekly",
  c_rebalance_20_days: "Monthly",
  c_rebalance_60_days: "Quarterly",
  c_strategy: "Strategy",
  c_select_list: "Select a white list",
  c_select_strategy: "Select a strategy",
  c_tcr: "TCR",
  c_tcr_title: "Trend Capture Rating",
  c_notifications: "Notifications",
  c_notifications_duration: "Duration",
  c_notifications_magnitude: "Magnitude",
  c_smart_beta: "Smart beta",
  c_smart_beta_bottom: "Bottom",
  c_smart_beta_middle: "Middle",
  c_smart_beta_top: "Top",
  c_strategy_long: "Long",
  c_strategy_short: "Short",
  c_timeframe_month: "concerning last month",
  c_timeframe_today: "today",
  c_timeframe_week: "concerning last week",
  c_title_peer_adjacent: "Navigates through the adjacent top level peers",
  c_unknown: "#UNKNOWN",
  c_vs: "vs",
  c_weight: "Weight",

  // ------------------------------------------------------- landing pages
  commons_button_add: "Add",
  commons_button_cancel: "Cancel",
  commons_button_edit: "Edit",
  commons_button_rank: "Rank",
  commons_button_set: "Set",
  commons_icon_expand: "Expand chart",
  commons_no_data_to_display: "No data available",
  //'commons_one_of': 'of',

  common_active: "Active",
  common_all: "All",
  common_asset_class: "Asset class",
  common_basket: "Basket",
  common_baskets: "Baskets",
  common_button_set: "Set",
  common_button_cancel: "Cancel",
  common_currency_local: "Local",
  common_industry: "Industry",
  common_industries: "Industries",
  common_instrument: "Instrument",
  common_instruments: "Instruments",
  common_instrument_etf: "ETF",
  common_instrument_etfs: "ETFs",
  common_instrument_stock: "Stock",
  common_instrument_stocks: "Stocks",
  common_instrument_stocks_and_etfs: "Stocks and ETFs",
  common_investment_region: "Inv. Region",
  common_instrument_type_domestic: "Domestic",
  common_instrument_type_foreign: "Foreign",
  common_interval_max: "Max",
  common_interval_min: "Min",
  common_load: "Load",
  common_market: "Market",
  common_markets: "Markets",
  common_none: "None",
  common_not_set: "Not set",
  common_ordered_by: "ordered by",
  common_portfolio: "Portfolio",
  common_portfolios: "Portfolios",
  common_sector: "Sector",
  common_sectors: "Sectors",
  common_sort_by: "Sort by",
  common_sort_by_highest_marketcap: "Highest Mkt. Cap.",
  common_sort_by_lowest_marketcap: "Lowest Mkt. Cap.",
  common_unknown: "#UNKNOWN",
  common_years: "years",

  // -------------------------------------------------- default labels
  // ------------------------------- MUST be replace by commons labels
  default_apply: "Apply",
  default_cancel: "Cancel",
  default_delete: "Delete",
  default_label: "Default label",
  default_load: "Load",
  default_no_data: "No data to display",
  default_overwrite: "Overwrite",
  default_remove: "Remove",
  default_save: "Save",
  default_save_as: "Save as",
  default_sort: "Sort",

  // default template name
  default_screening: "Default",
  default_screening_cash: "Cash",
  default_screening_commodity: "Commodities",
  default_screening_currency: "Currencies",
  default_screening_etf: "ETFs",
  default_screening_index: "Indices",
  default_screening_sector: "Sectors",
  default_screening_stock: "Stocks",
  default_screening_expired: "Expired",

  // feedback messages
  error_exists_name: "<strong>${name}</strong> already exists",
  error_invalid_name:
    "The name is not valid. It must be at least 3 characters long.",
  error_undefined_instrument: "Please, select an instrument",
  error_undefined_list: "Please, select a list",
  success_erase: "<strong>${name}</strong> has been deleted successfully",
  success_save: "<strong>${name}</strong> has been saved successfully",

  // security property tags (duplicatated in trendrating-widgets)
  // TODO Allocation is also in c_allocation, because this is only a duplicate to be probably removed,
  // there is no rename from tag_data_allocation to c_allocation (it is also specific of trendrating-widgets)
  tag_data_allocation: "Allocation",
  tag_data_classification: "Classification",
  tag_data_market: "Market data",
  tag_data_momentum: "Trend",
  tag_data_fundamentals: "Fundamental (Stocks only)",
  tag_data_performance: "Performance",
  tag_data_others: "Others",

  // tooltips
  tooltip_screening_configure_fields: "Customize table fields",

  // --------------------------------------------------- favorites
  favorites_title: "Recent",
  // ------------------------------------------------ market focus
  market_focus_cardinality: "Stocks",
  market_focus_downgrades: "Downgrades",
  market_focus_info_1: "Last working day",
  market_focus_info_5: "Last 5 working days",
  market_focus_info_20: "Last 20 working days",
  market_focus_name: "Name",
  market_focus_percentage_AB: "AB%",
  market_focus_percentage_CD: "CD%",
  market_focus_percentage_downgrades: "% of downgrades",
  market_focus_percentage_upgrades: "% of upgrades",
  market_focus_rating: "TCR",
  market_focus_rating_title: "Trend Capture Rating",
  market_focus_upgrades: "Upgrades",
  markets: "Markets",
  markets_trends: "Markets trends",
  // -------------------------------------------------------- menu
  menu_account: "Account",
  menu_advisor: "Advisor",
  menu_alerts: "Alerts",

  menu_analysis_collection: "Portfolio analysis",
  menu_analysis_market: "Markets",
  menu_analysis_peer: "Peer analysis",
  menu_analysis_sector: "Sectors &amp; industries",
  menu_analysis_security: "Security analysis",

  menu_daily_report: "Daily Report",

  menu_general: "General",

  menu_strategies_index: "Indices",
  menu_strategies_strategy: "Strategies",

  menu_systematic_portfolios: "Systematic portfolios",

  menu_builder_index: "Index Builder",
  menu_builder_strategy: "Strategy Builder",

  menu_report: "Report",
  menu_report_disclaimer: "Report Disclaimer",
  menu_report_logo: "Report Logo",

  menu_screening: "Screening",

  menu_preferences_alerts_screening: "Alerts and Screening",
  menu_preferences_general: "Account",
  menu_preferences_screening: "Screening",
  menu_preferences_analysis_list: "Portfolio Analysis",
  menu_preferences_security_analysis: "Security Analysis",
  menu_preferences_analysis_market: "Markets",
  menu_preferences_alerts: "Alerts",
  menu_preferences_daily_report: "Mail Digest",
  menu_preferences_report: "Report",
  menu_preferences_shared_objects: "Subscriptions",

  menu_ranking: "Ranking",
  // app/modules/analysis-collection/analysis/overview/Information

  // app/pages/builder/Builder
  page_builder_fieldset_legend_allocation: "Allocation constraints",
  page_builder_fieldset_legend_backtesting: "Backtesting",
  page_builder_fieldset_legend_hedging: "Hedging",
  page_builder_fieldset_legend_holding: "Holdings constraints",
  page_builder_fieldset_legend_ranking: "Ranking rules",
  page_builder_fieldset_legend_selection: "Selection rules",
  page_builder_fieldset_legend_strategy: "Strategy",
  page_builder_fieldset_legend_universe: "Investment universe",
  page_builder_fieldset_legend_weight: "Weighting rules",

  page_builder_field_benchmark_label: "Benchmark",

  page_builder_field_cash_label: "Investment min",
  page_builder_field_cash_label_flex: "Flex",
  page_builder_field_cash_label_full: "Full",
  page_builder_field_cash_label_preview: "Invested",

  page_builder_field_currency_label: "Currency",

  page_builder_field_period_label: "Period",
  page_builder_field_period_from_label: "Period from",

  page_builder_field_hedging_advanced_label: "Advanced hedging",
  page_builder_field_hedging_dynamic_label: "Dynamic hedging",
  page_builder_field_hedging_full_label: "Full hedging",
  page_builder_field_hedging_instrument_label: "Instrument",
  page_builder_field_hedging_leverage_label: "Leverage",
  page_builder_field_hedging_rules_label: "Rules",
  page_builder_field_hedging_smart_label: "Smart hedging",

  page_builder_field_holdings_threshold: "Number of holdings",

  page_builder_field_inception_date_label: "Inception date",

  page_builder_field_inception_value_label: "Inception value",

  page_builder_field_index_label: "Index",
  page_builder_field_neutral_label: "Neutral strategy",

  page_builder_field_performance_label: "Performance",
  page_builder_field_performance_label_price: "Price",
  page_builder_field_performance_label_total_return: "Total return",

  page_builder_field_rebalance_label: "Rebalance",
  page_builder_field_rebalance_label_05_days: "Weekly",
  page_builder_field_rebalance_label_20_days: "Monthly",
  page_builder_field_rebalance_label_60_days: "Quarterly",

  page_builder_field_rotation_label: "Rotation rules",
  page_builder_field_rotation_by_label: "by",
  page_builder_field_rotation_factor_label: "Rotation factor",
  page_builder_field_rotation_rotate_label: "Rotate",
  page_builder_field_rotation_market_neutral_label: "Market neutral",
  page_builder_field_rotation_momentum_label: "Momentum",
  page_builder_field_rotation_momentum_growth_label: "Momentum growth",

  page_builder_field_smart_beta_label: "Smart beta",

  page_builder_field_weight_capping_peer_label: "capping",
  // 'page_builder_field_weight_capping_peer_level_label': 'Max weight on',
  // 'page_builder_field_weight_capping_peer_value_label': 'capped at',

  page_builder_field_weight_capping_security_label: "Constituents capping",

  page_builder_field_weighting_schema_label: "Schema",
  page_builder_field_weighting_schema_label_weight_equal: "Equal weighted",
  page_builder_field_weighting_schema_label_weight_market_cap:
    "Market cap weighted",

  page_builder_field_weighting_schema_existing_positions_label:
    "Existing positions",
  page_builder_field_weighting_schema_existing_positions_label_keep:
    "Keep weights",
  page_builder_field_weighting_schema_existing_positions_label_rebalance:
    "Rebalance weights",

  page_builder_universe_label_instrument_type: "Instrument type",

  page_builder_universe_label_eligibility: "Eligibility",
  page_builder_universe_label_eligibility_cardinality: "Number of instruments",
  page_builder_universe_label_screening: "Screener",
  page_builder_universe_label_selection: "Additional rules",
  page_builder_universe_label_white_list: "White list",
  page_builder_universe_label_white_list_label: "Select a basket or portfolio",
  // -------------------- app/modules/analysis-collection/analysis
  portfolio_analysis_holdings: "Holdings",
  portfolio_analysis_investment_ideas: "Investment ideas",
  portfolio_analysis_overview: "Overview",
  portfolio_analysis_ranking: "Ranking",
  portfolio_analysis_point_in_time: "Point in time",
  portfolio_analysis_trends: "Trend analysis",
  // ------------------------------------------ Widget CompareList
  compareList_header: "Selected securities",
  compareList_button_removeLabel: "Remove",
  compareList_button_removeTitle: "Remove ${security}",
  // ---------------------------------------- Widget DialogCompare
  dialogCompare_title: "Add to ${security}",
  dialogCompare_sizeLimitReached:
    "You can add up to ${size} securities. Limit reached.",
  // ------------------------------------------- Widget SearchPeer
  searchPeer_label_assetClass: "Asset class",
  searchPeer_label_market: "Market",
  searchPeer_label_sector: "Sector",
  // ------------------------------- Widget SecurityHistoryCompare
  securityHistoryCompare_button_compareTo: "+",
  securityHistoryCompare_button_compareTo_title: "Add securities",
  securityHistoryCompare_collapser_collapse: "Collapse",
  securityHistoryCompare_collapser_expand: "Expand",
  // ----------------------------------- Widget ColumnConfigurator
  columnConfigurator_dialog_title: "Configure table",
  // ------------------------------------- Widget TablePerformance
  tablePerformance_averageAvoidedGain: "average avoided gain",
  tablePerformance_averageAvoidedLoss: "average avoided loss",
  tablePerformance_averageRealizedGain: "average realized gain",
  tablePerformance_averageRealizedLoss: "average realized loss",
  tablePerformance_averageUpsidePotential: "average upside potential",
  tablePerformance_correct: "Correct",
  tablePerformance_ratings: "Ratings",
  tablePerformance_success: "Success",
  tablePerformance_wrong: "Wrong",
  // ------------------------------------- Widget TileAlternatives
  tileAlternatives_title: "Alternatives to",
  // ----------------------------------------- Widget TileSecurity
  tileSecurity_tabs_chart: "Chart",
  tileSecurity_tabs_rate: "Ratings",

  tileSecurity_title_3YearsChartLastRating: "3 years chart - last rating",
  tileSecurity_title_comparison: "Comparison",
  tileSecurity_title_history: "Historical trends of",
  tileSecurity_title_and: "and its closest peers",
  tileSecurity_title_indicators: "Indicators",
  tileSecurity_title_onlyForAAndB: "only for A and B rating",
  tileSecurity_title_performance: "Performance",
  tileSecurity_title_securityInformation: "Security information",
  tileSecurity_title_trend: "Trend",
  tileSecurity_title_versus: "vs",

  tileSecurity_property_price: "Price",
  tileSecurity_property_type: "Type",
  tileSecurity_property_market: "Market",
  tileSecurity_property_momentum: "Smart momentum",
  tileSecurity_property_sector: "Sector",
  tileSecurity_property_mktcap: "Market cap.",
  tileSecurity_property_rate: "Rating",
  tileSecurity_property_rateDate: "Rated on",
  tileSecurity_property_retracement: "Retracement",
  tileSecurity_property_performanceLast005Days: "Last week",
  tileSecurity_property_performanceLast020Days: "Last month",
  tileSecurity_property_performanceLast060Days: "Last 3 months",
  tileSecurity_property_performanceLast240Days: "Last 12 months",
  tileSecurity_property_performanceSince: "Since",
  tileSecurity_property_performance_ytd: "YTD",
  tileSecurity_property_performanceSinceRated: "Since rated",
  tileSecurity_property_upi: "Upside Potential Indicator",
  tileSecurity_property_upi_abbr: "UPI",
  tileSecurity_property_duration: "Duration",
  tileSecurity_property_magnitude: "Magnitude",
  // --------------- app/modules/analysis-collection/TrendAnalysis
  cross_frequencies: "Cross frequencies",
  distribution_duration: "Duration distribution",
  distribution_magnitude: "Magnitude distribution",
  duration_vs_performance: "Duration of trends vs performance",
  // ------------ app/modules/analysis-collection/analysis/ranking/Ranking
  portfolio_analysis_ranking_universe_cardinality:
    "Your investment universe contains ${cardinality} securities.",
  portfolio_analysis_ranking_universe_too_large:
    "Your investment universe is too large. It contains ${cardinality} securities. The limit is ${cardinalityThreshold}.",

  w_analysis_security_alternatives_empty:
    "To see more alternatives, please remove at least one constraint.",

  // ------------------------------- app/widgets/dataImporter/DataImporter
  w_data_importer_button_add_position: "Add position",
  w_data_importer_button_equalize: "Equal weights",
  w_data_importer_button_import: "Import from Excel",
  w_data_importer_button_normalize: "100%",

  // feedback
  w_feedback_create: "<strong>${name}</strong> has been created.",
  w_feedback_delete: "<strong>${name}</strong> has been deleted.",
  w_feedback_rebalance: "<strong>${name}</strong> has been rebalanced.",
  w_feedback_replace:
    "<strong>${target}</strong> has replaced <strong>${source}</strong>",
  w_feedback_update: "<strong>${name}</strong> has been updated.",
  w_feedback_update_backtesting:
    "Backtesting of <strong>${name}</strong> has been updated.",

  // preferences
  w_preferences_analysis_list_allocation_areas: "Areas",
  w_preferences_analysis_list_allocation_markets: "Markets",
  w_preferences_analysis_list_allocation_regions: "Regions",
  w_preferences_analysis_list_alerts_upgradedowngrade: "Uptrends / Downtrends",
  w_preferences_analysis_list_alerts_newlow: "New Lows",
  w_preferences_analysis_list_alerts_newhigh: "New Highs",
  w_preferences_analysis_list_alerts_notification: "Notifications",

  w_prefernces_alerts_screening_title:
    "Set specific markets to personalize your default on Alerts and Screening views",
  w_prefernces_screening_title:
    "Set specific markets to personalize your default on Screening view",

  w_preferences_shared_objects_information:
    "This section shows the documents that have been shared by Trendrating and that can be subscribed for use in your user account. These items are in read-only mode, you can make a copy in order to edit the content.",

  // systematic product
  w_systematic_product_allocation_date_navigator_option_last: "Last available",
  w_systematic_product_allocation_date_navigator_option_next: "Next rebalance",

  w_systematic_product_context_menu_allocation_add: "Insert allocation",
  w_systematic_product_context_menu_allocation_edit: "Edit allocation",
  w_systematic_product_context_menu_allocation_remove: "Remove allocation",
  w_systematic_product_context_menu_instrument_edit: "Edit instrument",
  w_systematic_product_context_menu_sort_by_instrument: "Sort by instrument",
  w_systematic_product_context_menu_sort_by_weight: "Sort by weight",

  // w_systematic_product_dialog_content_rebalance:
  //     "The ${strategy} will rebalance the underlying portfolio<br/><strong>${list}</strong>.<br/>Before rebalancing, please verify that the portfolio holdings and weights are up-to-date.",
  w_systematic_product_dialog_content_rebalance:
    'The ${strategy} will rebalance the underlying portfolio<br/><strong>${list}</strong>.<br/><span style="color: red;">Before rebalancing, please verify that the portfolio holdings and weights are up-to-date. To update the portfolio, import it into the historical portfolios section.</span>',
  w_systematic_product_dialog_content_remove_allocation:
    "Are you sure to delete allocation ${name}?",
  w_systematic_product_dialog_feedback_backtesting_date:
    "Please, select a date.",
  w_systematic_product_dialog_feedback_name:
    "Please, insert the name of the product.",
  w_systematic_product_dialog_feedback_name_duplicate:
    "A product with this name already exists: please change.",
  w_systematic_product_dialog_feedback_no_replacement:
    "Please, select an instrument",
  w_systematic_product_dialog_feedback_strategy: "Please, select a strategy.",
  w_systematic_product_dialog_feedback_white_list:
    "Please, select a white list.",
  w_systematic_product_dialog_field_cash:
    "100% cash until next rebalancing date",
  w_systematic_product_dialog_field_date: "Insert allocation at",
  w_systematic_product_dialog_field_deallocate: "Deallocate",
  w_systematic_product_dialog_field_deleted_historical_portfolio:
    "Delete historical holding portfolio",
  w_systematic_product_dialog_field_list: "White list",
  w_systematic_product_dialog_field_name: "Name",
  w_systematic_product_dialog_field_replace: "Replace",
  w_systematic_product_dialog_field_replace_with: "Replace with",
  w_systematic_product_dialog_field_strategy: "Strategy",
  w_systematic_product_dialog_field_strategy_allocation: "Strategy allocation",
  w_systematic_product_dialog_field_strategy_edit_info:
    "By changing the strategy, all the historical data and portfolios will be kept. The new strategy will be applied at the next rebalance.",
  w_systematic_product_dialog_field_rebalance: "Rebalance today",
  w_systematic_product_dialog_header_rebalance:
    "<strong>${name}</strong> is expired. What do you want to do?",
  w_systematic_product_dialog_legend_basic_info: "Basic information",
  w_systematic_product_dialog_legend_list: "Today's portfolio",
  w_systematic_product_dialog_legend_price_level: "Price level",
  w_systematic_product_dialog_title_create: "Create a systematic portfolio",
  w_systematic_product_dialog_title_edit: "Edit ${name}",
  w_systematic_product_dialog_title_delete: "Delete ${name}",
  w_systematic_product_dialog_title_rebalance: "Rebalance ${name}",

  w_systematic_product_list_is_empty:
    "Click on <strong>Create</strong> to start",
  w_systematic_product_list_item_summary_closing_date: "close of",
  w_systematic_product_overview_holdings_current: "Current holdings",
  w_systematic_product_overview_holdings_historical: "Historical holdings",
  w_systematic_product_rebalance_new_allocation: "New allocation",
  w_systematic_product_rebalance_next:
    "Rebalancing is scheduled for ${date} in ${days} days",
  w_systematic_product_rebalance_orders: "Rebalance orders",
  w_systematic_product_rebalance_overdue:
    "Rebalancing was to be done on ${date} ${days} days ago, please rebalance",
  w_systematic_product_rebalance_today: "Rebalancing is scheduled for today",
  w_systematic_product_rebalanced_today: "Rebalanced today",
  w_systematic_product_tab_chart: "Chart",
  w_systematic_product_tab_historical_lists: "Historical portfolios",
  w_systematic_product_tab_holdings: "Holdings",
  w_systematic_product_tab_overview: "Overview",
  // workflow
  w_workflow_action_back: "Back",
  w_workflow_action_back_systematic_product: "Back",
  w_workflow_action_back_tooltip_systematic_product: "Back",
  w_workflow_action_create: "Create",
  w_workflow_action_create_tooltip_systematic_product:
    "Create a new systematic portfolio",
  w_workflow_action_delete: "Delete",
  w_workflow_action_delete_tooltip_systematic_product:
    "Delete this systematic portfolio",
  w_workflow_action_edit: "Edit",
  w_workflow_action_edit_tooltip_systematic_product:
    "Edit <strong>${name}</strong>",
  w_workflow_action_export_allocation: "Export new allocation",
  w_workflow_action_export_allocation_tooltip_systematic_product:
    "Export new allocation",
  w_workflow_action_export_order: "Export rebalance orders",
  w_workflow_action_export_order_tooltip_systematic_product:
    "Export rebalance orders",
  w_workflow_action_import_allocation: "Import",
  w_workflow_action_import_allocation_tooltip_systematic_product:
    "Import allocations",
  w_workflow_action_rebalance_save: "Confirm",
  w_workflow_action_rebalance_save_tooltip_systematic_product: "Add rebalance",
  w_workflow_action_rebalance_preview: "Rebalance",
  w_workflow_action_rebalance_preview_tooltip_systematic_product:
    "Rebalance this systematic portfolio",
  w_workflow_action_reset: "Reset",
  w_workflow_action_save: "Save",
};
