import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import Modal from "../../../../../components/Modal/Modal";
import { useCallback, useState } from "react";

type ConfirmAutorebalanceDialogProps = {
  onConfirm: (value: boolean, params?: any) => void;
  onCancel: () => void;
};

export function ConfirmAutorebalanceDialog({
  onCancel,
  onConfirm,
}: ConfirmAutorebalanceDialogProps) {
  const [autorebalance, setAutorebalance] = useState(false);

  const onChangeRadio = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement>,
      value: string
    ): void | undefined => {
      setAutorebalance(value === "enable");
    },
    []
  );

  const confirm = useCallback(() => {
    onConfirm(autorebalance);
  }, [autorebalance, onConfirm]);

  return (
    <Modal
      buttonsEnalbed
      buttons={[
        {
          name: "Confirm",
          callback: confirm,
        },
        {
          name: "Cancel",
          callback: onCancel,
          variant: "cancel",
        },
      ]}
      closeIcon={false}
    >
      <Box display={"flex"} flexDirection={"column"}>
        <div className="tHelp-itemTips">
          <Box p={1} display={"flex"} flexDirection={"column"} gap={1}>
            <Typography>
              <span className="i-info"></span> <strong>Auto rebalance</strong>
            </Typography>
            <Typography>
              Trendrating provides the option to automatically rebalance
              portfolios, ensuring that updates occur on the rebalancing date
              without any manual intervention. This guarantees that the
              systematic strategy is applied accurately. However, with automatic
              rebalancing, you won’t have visibility into the buy and sell
              orders on the rebalancing day, as the system will have already
              executed them. This may make it more challenging to implement the
              strategy in your live portfolios.
            </Typography>
          </Box>
        </div>

        <Box display={"flex"} flexDirection={"column"} gap={1}>
          <Typography>Do you want to enable auto rebalance?</Typography>
          <RadioGroup onChange={onChangeRadio}>
            <FormControlLabel
              value="enable"
              control={<Radio />}
              label="I want to enable auto rebalance"
              checked={autorebalance}
            />
            <FormControlLabel
              value="disable"
              control={<Radio />}
              label="I want to rebalance manually myself"
              checked={!autorebalance}
            />
          </RadioGroup>
        </Box>
      </Box>
    </Modal>
  );
}
