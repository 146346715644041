import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useImmerReducer } from "use-immer";
import { deepClone } from "../../../../../deepClone";
import { prepareDataForEditorPreview, _valueGet } from "../../utils";
import EditorPreview from "../EditorPreview/EditorPreview";
import OptionRangeQuantile from "../OptionRangeQuantile/OptionRangeQuantile";
import { rStateReducer, rStateType } from "./utils";

import WizzOperators from "./WizzOperators";
import WizzProperties from "./WizzProperties";
import { useEnvironment } from "../../../../../hooks/useEnvironment";

type WizzardProps = {
  options: any;
  input: any;
  outterStateSetter: Function;
  onSelect: Function;
  onCancel: Function;
};

export const Wizzard = forwardRef(
  (
    { options, input, outterStateSetter, onSelect, onCancel }: WizzardProps,
    ref
  ) => {
    const initReducerState: rStateType = _valueGet(
      input,
      input["field"]["property"],
      input["field"]["function"],
      input["field"]["operator"],
      deepClone(options._optionsIndex),
      deepClone(options._options)
    );
    const [rState, dispatch] = useImmerReducer(rStateReducer, initReducerState);

    const builderGroupArr = useMemo(() => {
      const set: any = new Set();
      options._options.properties.forEach((item) => set.add(item.builderGroup));
      return Array.from(set);
    }, [options._options.properties]);

    useEffect(() => {
      // passing rState values to upper
      // component to set <Help/> State
      outterStateSetter({
        property: rState.field.property,
        operator: rState.field.operator,
      });
    }, [outterStateSetter, rState.field.property, rState.field.operator]);

    useEffect(() => {
      //! if rState is not reseted when input changes
      //! wizzard keeps the old state inside, since
      //! it's not unmounting from the dom.
      dispatch({
        type: "changeOperativeInput",
        payload: _valueGet(
          input,
          input["field"]["property"],
          input["field"]["function"],
          input["field"]["operator"],
          deepClone(options._optionsIndex),
          deepClone(options._options)
        ),
      });
    }, [dispatch, input, options._options, options._optionsIndex]);

    const [properties, setProperties] = useState<any>();
    // const [functions, setFunctions] = useState<any>();

    //#region -- getting ui contraints based on the product
    const environment = useEnvironment();
    const UI_CONSTRAINTS = useMemo(
      () =>
        environment.get("account").product?.configuration?.strategy_builder
          .form_advanced.uiOptions,
      [environment]
    );
    //#endregion

    const [operators, setOperators] = useState<any>();
    useEffect(() => {
      if (rState && options) {
        setProperties(_dataDisplayProperties(rState, options._options));
        if (rState["field"]["property"]) {
          // setFunctions(_dataDisplayFunctions(rState, options._optionsIndex, options._options));

          if (UI_CONSTRAINTS["contraintTypes"] != null) {
            const keys = Object.keys(UI_CONSTRAINTS["contraintTypes"]);
            setOperators(
              _dataDisplayOperators(
                rState,
                deepClone(options._optionsIndex),
                deepClone(options._options),
                keys
              )
            );
          } else {
            setOperators(
              _dataDisplayOperators(
                rState,
                deepClone(options._optionsIndex),
                deepClone(options._options),
                null
              )
            );
          }
        }
      }
    }, [UI_CONSTRAINTS, options, rState]);

    //!!
    //!!
    //!!-------------------------------------------------------
    //!!-------------------------------------------------------
    //!!  level of synchronization with the upper component
    useImperativeHandle(ref, () => ({
      getState: () => {
        const rStateCloned = deepClone(rState);
        return rStateCloned;
      },
    }));
    //!!-------------------------------------------------------
    //!!-------------------------------------------------------
    //!!
    //!!

    //?----------------------------------------------
    //? Why keyToReRender????----------------------
    /*?----------------------------------------------
  since editorPreview can be(is!) a trouble maker, we have to
  rerender the component in order to avoid problems with the state.
  if editorPreview doesn't rerender it'll keep a state inside itself
  and will push this state up when rState changes.
  */
    const [keyToReRender, setKeyToReRender] = useState(input["index"]);
    useEffect(() => {
      setKeyToReRender(input["index"]);
    }, [input]);
    //!React's key prop gives you the ability to control component instances.
    //!Each time React renders your components, it's calling your functions
    //!to retrieve the new React elements that it uses to update the DOM.
    //!If you return the same element types, it keeps those components/DOM nodes
    //!around, even if all* the props changed.
    //?----------------------------------------------
    //?----------------------------------------------

    const labelForRangeQuantile = useMemo(() => {
      if (rState["field"]["property"]) {
        const index =
          options._optionsIndex[rState["field"]["property"]]["property"];
        return options._options["properties"][index]["label"];
      }
      return null;
    }, [options._options, options._optionsIndex, rState]);

    const operatorForRangeQuantile = useMemo(() => {
      if (rState && rState["field"]["function"] != null && operators != null) {
        const funcID = rState["field"]["function"];
        let op = operators.filter((item) => item.item.functionID === funcID)[0];
        if (op == null) {
          return null;
        }
        op = op["item"];
        if (rState["editing"]) {
          op["widget"]["value"] =
            rState["field"]["operatorParams"]["augmentation"]["value"];
        }
        return op;
      }
      return null;
    }, [operators, rState]);

    const setOutterValueFromSlider = useCallback(
      (value) => {
        let _operator = deepClone(operatorForRangeQuantile);
        let _operatorParams = {
          augmentation: {
            value: value,
          },
          subject: _operator["functionID"],
        };
        dispatch({ type: "SET_OPERATOR_PARAMS", payload: _operatorParams });
      },
      [dispatch, operatorForRangeQuantile]
    );

    const setRuleButtonHandler = useMemo(() => {
      if (rState["errorAtInputValue"] === true) {
        return true;
      }
      const arrVals = Object.values(rState["field"]);
      for (let i = 0; i < arrVals.length; i++) {
        if (arrVals[i] == null) {
          return true;
        }
      }
      return false;
    }, [rState]);

    return (
      <div>
        <Box
          sx={{
            bgcolor: "transparent",
            display: "flex",
            gap: 2,
          }}
        >
          {rState && (
            <>
              <WizzProperties
                customStyle={{ maxWidth: "50%", flex: "4 1" }}
                properties={properties}
                rState={rState}
                propertyId={rState["field"]["property"]}
                options={deepClone(options)}
                dispatcher={dispatch}
                isEditing={rState["editing"]}
                sortedList={builderGroupArr}
              />
              {rState["showOperatorSection"] && (
                <div style={{ maxWidth: "30%", flex: "3 1" }}>
                  <WizzOperators
                    key={rState["field"]["property"]}
                    operators={operators}
                    dispatcher={dispatch}
                    operatorId={rState["field"]["operator"]}
                    isEditing={rState["editing"]}
                    showValueSection={rState["showValueSection"]}
                    rState={rState}
                    options={deepClone(options)}
                  />
                </div>
              )}

              {rState["showRangeQuantile"] && operatorForRangeQuantile && (
                <div style={{ maxWidth: "30%", flex: "3 1" }}>
                  <Card sx={{ height: "100%", boxShadow: 3 }}>
                    <CardContent>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        {labelForRangeQuantile}
                      </Typography>
                      <OptionRangeQuantile
                        labelForRangeQuantile={labelForRangeQuantile}
                        meta={operatorForRangeQuantile}
                        options={operatorForRangeQuantile["widget"]["options"]}
                        value={operatorForRangeQuantile["widget"]["value"]}
                        outterStateSetter={setOutterValueFromSlider}
                      />
                    </CardContent>
                  </Card>
                </div>
              )}

              {rState["field"]["property"] && rState["showValueSection"] && (
                <div style={{ maxWidth: "50%", flex: "3 1" }}>
                  <EditorPreview
                    title={labelForRangeQuantile}
                    key={keyToReRender}
                    dispatcher={dispatch}
                    data={prepareDataForEditorPreview(rState, deepClone(options))}
                  />
                </div>
              )}
            </>
          )}
        </Box>
        <Box sx={{ pt: 2 }}>
          <Card
            sx={{
              display: "flex",
              justifyContent: "end",
              width: "100%",
              boxShadow: 3,
            }}
          >
            <CardContent sx={{ "&:last-child": { pb: 2 } }}>
              <Button
                disabled={setRuleButtonHandler}
                variant="contained"
                type="button"
                onClick={() => onSelect()}
              >
                Set rule
              </Button>
              <Button
                sx={{
                  ml: 2,
                }}
                variant="tr_button_cancel"
                type="button"
                onClick={() => onCancel()}
              >
                Cancel
              </Button>
            </CardContent>
          </Card>
        </Box>
      </div>
    );
  }
);

const _dataDisplayHelperList = (items, itemsType, value) => {
  var item: any = null;
  let arr: any[] = [];
  for (let i = 0, length = items.length; i < length; i++) {
    item = items[i];
    arr.push({ label: item["label"], value: item.value, item: item });
  }
  return arr;
};
const _dataDisplayProperties = (value, _options) => {
  var properties = _options["properties"];
  return _dataDisplayHelperList(properties, "properties", value);
};

export const _dataDisplayOperators = (
  value,
  _optionsIndex,
  _options,
  UI_CONSTRAINTS: null | string[]
): any[] | Object => {
  var property = value["field"]["property"];
  var indexProperty = _optionsIndex[property]["property"];
  //give me all the possible operators:
  const arrPossibleFunctions = Object.keys(
    _optionsIndex[property]["functions"]
  );
  let OPS: any[] = [];
  arrPossibleFunctions.forEach((possibleFunc) => {
    if (possibleFunc in _optionsIndex[property]["functions"]) {
      const index = _optionsIndex[property]["functions"][possibleFunc];
      _options["operators"][indexProperty][index].forEach((item) => {
        //i need the function id in the operator obj
        item["functionID"] = possibleFunc;
        OPS.push(item);
      });
    }
  });

  //!------------------------
  //!------------------------
  if (UI_CONSTRAINTS != null) {
    UI_CONSTRAINTS.forEach((item) => {
      OPS = OPS.filter((op) => op.label !== item);
    });
  }
  //!------------------------
  //!------------------------

  return _dataDisplayHelperList(OPS, "operators", value);
};
