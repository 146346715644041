import { Card, CardContent } from "@mui/material";
import React, { useMemo } from "react";
import { WhatWherePie } from "../../../../../../../../../../../components/WhatWherePie/WhatWherePie";

type PieProps = {
  holdings: any;
  handlers: any;
  date;
  whereDrilldown?: string;
  whatDrilldown?: string;
};

export default function Pie({
  handlers,
  holdings,
  date,
  whereDrilldown,
  whatDrilldown,
}: PieProps) {
  const assetType = useMemo(() => {
    const constituentsTypes: any = [
      ...new Set(holdings.map((item) => item.type)),
    ];

    const isSingleType = constituentsTypes.length === 1;
    if (isSingleType) {
      return constituentsTypes?.[0];
    }

    return "Stock";
  }, [holdings]);

  return (
    <Card
      sx={{
        boxShadow: 3,
        width: "30%",
        height: "100%",
        display: "flex",
        minHeight: 0,
      }}
    >
      <CardContent
        sx={{ pb: "8px !important", p: 1, flex: 1, display: "flex" }}
      >
        <WhatWherePie
          holdings={holdings}
          isTcrMode={false}
          hasHorizontalLayout={false}
          autoResize={false}
          isPieClickable={true}
          handlers={handlers}
          alignChartOnTop={false}
          defaultWhat={whatDrilldown}
          defaultWhere={whereDrilldown}
          optionsByAssetType={{ enabled: true, assetType }}
          date={date}
        />
      </CardContent>
    </Card>
  );
}
