import {
  Box,
  Button,
  Card,
  CardContent,
  Input,
  Typography,
} from "@mui/material";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { deepClone } from "../../../../../../../deepClone";
import SimpleTextEditor from "../../../../../../../components/SimpleTextEditor/SimpleTextEditor";

type Props = {
  defaultDisclaimer: any;
  defaultLogo: any;
  value: {
    disclaimer: any;
    logo: any;
  };
  preferenceDispatcher: Function;
  saving: Function;
};

const Report = forwardRef(
  (
    {
      defaultDisclaimer,
      defaultLogo,
      value,
      preferenceDispatcher,
      saving,
    }: Props,
    ref
  ) => {
    const _cropperOptions = useMemo<any>(
      () => ({
        imageBox: ".preference-report__image-box_react",
        thumbBox: ".preference-report__thumb-box_react",
        spinner: ".preference-report__loading_react",
        imgSrc: null,
      }),
      []
    );
    const [cropper, setCropper] = useState<any>(null);

    const updateCropper = useCallback(
      (img) => {
        const temp = deepClone(_cropperOptions);
        temp.imgSrc = img;
        const cc = new window.cropbox(temp);
        setCropper(cc);
      },
      [_cropperOptions]
    );

    const loadLogo = useCallback(() => {
      const logo = value.logo ?? defaultLogo;

      updateCropper(logo);
    }, [defaultLogo, updateCropper, value.logo]);

    const onMount = useCallback(() => {
      loadLogo();
    }, [loadLogo]);

    useEffect(() => {
      onMount();
    }, [onMount]);

    const handleFileChange = (event) => {
      const file = event.target.files[0];
      // Verifica se il file è un'immagine
      if (file && file.type.startsWith("image/")) {
        // Esegui l'operazione desiderata con l'immagine
        const reader = new FileReader();
        reader.onloadend = () => {
          const dataURI = reader.result;
          // Utilizza il data URI come desideri
          updateCropper(dataURI);
        };
        reader.readAsDataURL(file);
      } else {
        alert("please, select an image!");
      }
    };

    const textEditorRef = useRef<any>(null);
    const getTexEditoValue = useCallback(() => {
      const widget = textEditorRef?.current;

      if (widget != null) {
        return widget.getEditorValue();
      }
      return null;
    }, []);

    useImperativeHandle(
      ref,
      () => {
        return {
          getValue: () => {
            var logo = cropper.getDataURL();
            let logoValue: any = null;
            if (logo !== defaultLogo) {
              logoValue = {
                base64: logo,
                url: null,
              };
            }
            console.log({
              disclaimer: textEditorRef?.current?.getEditorValue(),
              logo: logoValue,
            });
          },
        };
      },
      [cropper, defaultLogo]
    );

    const loadDefault = useCallback(() => {
      updateCropper(defaultLogo);
    }, [defaultLogo, updateCropper]);

    return (
      <Box display={"flex"} flexDirection={"column"} gap={2}>
        <Card sx={{ boxShadow: 3 }}>
          <CardContent
            sx={{
              pb: "16px !important",
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Typography variant="subtitle1">
              <strong> Logo </strong>
            </Typography>
            <Box position={"relative"}>
              <div className="preference-report__image-box_react">
                <div className="preference-report__thumb-box_react"></div>
                <div className="preference-report__loading_react">
                  Select an image from your computer
                  <br />
                  Recommended dimension: 265x50 pixels
                </div>
              </div>
            </Box>

            {/* buttons */}
            <Box display={"flex"} gap={2} justifyContent={"space-between"}>
              <Box justifyContent={"flex-start"}>
                <Button
                  onClick={() => {
                    document!.getElementById("fileInput")!.click();
                  }}
                >
                  Load a new logo
                </Button>
              </Box>

              <Box display={"flex"} gap={2}>
                <Button
                  onClick={() => {
                    if (cropper != null) {
                      cropper.zoomOut();
                    }
                  }}
                >
                  -
                </Button>
                <Button
                  onClick={() => {
                    if (cropper != null) {
                      cropper.zoomIn();
                    }
                  }}
                >
                  +
                </Button>
              </Box>

              <Box display={"flex"} gap={2}>
                <Button onClick={loadDefault}>Reset</Button>

                <Button
                  onClick={() => {
                    const logo = cropper.getDataURL();
                    preferenceDispatcher({
                      type: "SET_REPORT_LOGO",
                      payload: logo,
                      saving: saving,
                    });
                  }}
                >
                  Save
                </Button>
              </Box>
            </Box>
            {/* buttons */}
          </CardContent>
        </Card>
        <Input
          id="fileInput"
          sx={{ display: "none" }}
          type="file"
          onChange={handleFileChange}
        />
        <Card sx={{ boxShadow: 3 }}>
          <CardContent
            sx={{
              pb: "16px !important",
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Typography variant="subtitle1">
              <strong> Disclaimer </strong>
            </Typography>
            <Box position={"relative"}>
              <SimpleTextEditor
                initVal={value.disclaimer ?? defaultDisclaimer}
                ref={textEditorRef}
                minHeight={40}
              />
            </Box>
            <Box display={"flex"} justifyContent={"flex-end"} gap={2}>
              <Button
                onClick={() => {
                  const widget = textEditorRef?.current;
                  if (widget != null) {
                    widget.setEditorValue(defaultDisclaimer);
                  }
                }}
              >
                Reset
              </Button>
              <Button
                onClick={() => {
                  let content: string = getTexEditoValue();
                  content = content.replaceAll("<br>", "<br/>");

                  preferenceDispatcher({
                    type: "SET_REPORT_DISCLAIMER",
                    payload: content,
                    saving: saving,
                  });
                }}
              >
                Update disclaimer
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
    );
  }
);

export default Report;
