import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import InputNumber from "../../../../../../../../../components/InputNumber/InputNumber";

type Props = {
  min: any;
  max: any;
  dispatcher: Function;
};

export default function CostituentsCapping({ min, max, dispatcher }: Props) {
  const inputMinExists = useMemo(() => {
    if (min != null) {
      return true;
    }
    return false;
  }, [min]);

  const inputMaxExists = useMemo(() => {
    if (max != null) {
      return true;
    }
    return false;
  }, [max]);

  const [isSectionActive, setIsSectionActive] = useState({
    min: inputMinExists,
    max: inputMaxExists,
  });

  const handleMinSwitch = useCallback(
    (e) => {
      const value = e.target.checked;
      setIsSectionActive((current) => ({ ...current, min: value }));

      if (value === false) {
        dispatcher({ type: "DISABLE_MIN_CAPPING" });
      } else {
        dispatcher({ type: "ENABLE_MIN_CAPPING" });
      }
    },
    [dispatcher]
  );

  const handleMaxSwitch = useCallback(
    (e) => {
      const value = e.target.checked;
      setIsSectionActive((current) => ({ ...current, max: value }));

      if (value === false) {
        dispatcher({ type: "DISABLE_MAX_CAPPING" });
      } else {
        dispatcher({ type: "ENABLE_MAX_CAPPING" });
      }
    },
    [dispatcher]
  );

  const handleCappingMinValueChange = useCallback(
    (val) => {
      if (isSectionActive.min) {
        dispatcher({
          type: "SET_CAPPING_SECURITY_MIN",
          payload: val,
        });
      }
    },
    [dispatcher, isSectionActive.min]
  );

  const handleCappingMaxValueChange = useCallback(
    (val) => {
      if (isSectionActive.max) {
        dispatcher({
          type: "SET_CAPPING_SECURITY_MAX",
          payload: val,
        });
      }
    },
    [dispatcher, isSectionActive.max]
  );

  // const switchHandler = (e) => {
  //   setIsSectionActive(e.target.checked);
  //   if (e.target.checked === false) {
  //     dispatcher({ type: "SET_CAPPING_SECURITY_TO_NULL" });
  //   }
  //   if (e.target.checked === true && inputExists === false) {
  //     dispatcher({ type: "INIT_CAPPING_SECURITY" });
  //   }
  // };
  return (
    <Card sx={{ flex: 1 }}>
      <CardContent sx={{ pb: "16px !important" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
          gap={2}
        >
          <Typography>Constituents capping</Typography>
          {/* <FormControlLabel
            sx={{ width: "max-content", gap: 1 }}
            control={
              <Switch
                size="small"
                checked={isSectionActive}
                onChange={(e) => {
                  switchHandler(e);
                }}
              />
            }
            label={<Typography>Constituents capping</Typography>}
          /> */}

          <Box
            sx={{
              display: "flex",
              gap: 2,
            }}
          >
            <Box
              style={{ display: "flex", alignItems: "center" }}
              sx={{ gap: 1 }}
            >
              <FormControlLabel
                sx={{ width: "max-content", gap: 1 }}
                control={
                  <Switch
                    size="small"
                    checked={isSectionActive.min}
                    onChange={handleMinSwitch}
                  />
                }
                label={<Typography>Min</Typography>}
              />
              <InputNumber
                maxWidth={100}
                disableGutters
                // constraints={{ minVal: 0, maxVal: 100 }}
                initVal={min != null ? min : inputMinExists ? null : 0.01}
                isDisabled={!isSectionActive.min}
                isPercentage={true}
                setOuterState={handleCappingMinValueChange}
                isFloating={true}
              />
            </Box>

            <Box
              style={{ display: "flex", alignItems: "center" }}
              sx={{ gap: 1 }}
            >
              <FormControlLabel
                sx={{ width: "max-content", gap: 1 }}
                control={
                  <Switch
                    size="small"
                    checked={isSectionActive.max}
                    onChange={handleMaxSwitch}
                  />
                }
                label={<Typography>Max</Typography>}
              />
              <InputNumber
                maxWidth={100}
                disableGutters
                // constraints={{ minVal: 0, maxVal: 100 }}
                initVal={max != null ? max : inputMaxExists ? null : 0.2}
                isDisabled={!isSectionActive.max}
                isPercentage={true}
                setOuterState={handleCappingMaxValueChange}
                isFloating={true}
              />
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
