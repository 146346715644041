import { Box, Button, Card, CardContent } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useImmerReducer } from "use-immer";
import { deepClone } from "../../../../../../../../../deepClone";
import { useInputNumberValidation } from "../../../../../../../../../hooks/useInputNumberValidation";
import { useResizer } from "../../../../../../../../../hooks/useResizer";
import { AllocationReducer, initAllocationState } from "./AllocatoinReducer";
import Capping from "./Capping";
import CostituentsCapping from "./CostituentsCapping";
import Help from "./Help";
import Investment from "./Investment";

type AllocationControllerProps = {
  value: any;
  onCancel: Function;
  onClose: Function;
  isEtfUniverse: boolean;
};

export default function AllocationController({
  value,
  onCancel,
  onClose,
  isEtfUniverse,
}: AllocationControllerProps) {
  const [state, dispatcher] = useImmerReducer(
    AllocationReducer,
    value ?? initAllocationState
  );

  useEffect(() => {
    if (value) {
      dispatcher({ type: "SET_STATE", payload: value });
    }
  }, [dispatcher, value]);

  const containerRef = useRef<HTMLDivElement>(null);
  useResizer({
    ref: containerRef,
  });

  const { isStateValid, validationWatcher } = useInputNumberValidation({
    weightInCashMin: false,
    weightInCashMax: false,
  });

  const applyBtnDisabled = useMemo(() => {
    if (state != null && isStateValid) {
      if (JSON.stringify(state) !== JSON.stringify(value)) {
        return false;
      }
      return true;
    } else {
      return true;
    }
  }, [isStateValid, state, value]);

  return (
    <div ref={containerRef}>
      <Box sx={{ display: "flex", p: 2, gap: 2 }}>
        <Card sx={{ width: "80%", boxShadow: 3 }}>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              paddingBottom: "16px !important",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Investment
                key={"weightInCashMin"}
                valueFromOutside={{
                  min: state.weightInCashMin,
                  max: state.weightInCashMax,
                }}
                dispatcher={dispatcher}
                validationChecker={validationWatcher}
              />
              <CostituentsCapping
                key={"weightCappingSecurity"}
                min={state.weightCappingSecurity?.weightCappedMin}
                max={state.weightCappingSecurity?.weightCappedMax}
                dispatcher={dispatcher}
              />
              <Capping
                isEtfUniverse={isEtfUniverse}
                dispatcher={dispatcher}
                weightCappingPeer={state.weightCappingPeer}
                peerLevel={state.weightCappingPeer?.peerLevel}
                weightCappedMin={state.weightCappingPeer?.weightCappedMin}
                weightCappedMax={state.weightCappingPeer?.weightCappedMax}
                weightCappedMethod={state.weightCappingPeer?.weightCappedMethod}
              />
            </Box>
            <Controls
              applyBtnDisabled={applyBtnDisabled}
              onClose={onClose}
              state={state}
              onCancel={onCancel}
            />
          </CardContent>
        </Card>
        <Help />
      </Box>
    </div>
  );
}

type ControlsType = {
  onCancel: Function;
  onClose: Function;
  state: any;
  applyBtnDisabled: boolean;
};
const Controls = ({
  onCancel,
  onClose,
  state,
  applyBtnDisabled,
}: ControlsType) => {
  const handleApplyAllocationRules = useCallback(() => {
    if (
      state.weightCappingSecurity?.weightCappedMax == null &&
      state.weightCappingSecurity?.weightCappedMin == null
    ) {
      let cloned = deepClone(state);
      cloned["weightCappingSecurity"] = null;
      onClose(cloned);
    } else {
      onClose(state);
    }
  }, [onClose, state]);

  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
      <Button
        disabled={applyBtnDisabled}
        onClick={handleApplyAllocationRules}
        type="button"
      >
        Apply rule
      </Button>
      <Button
        sx={{
          ml: 2,
        }}
        variant="tr_button_cancel"
        onClick={() => {
          onCancel();
        }}
      >
        Cancel
      </Button>
    </Box>
  );
};
