export type AllocationType = {
  weightInCashMin: number | null;
  weightInCashMax: number | null;

  weightCappingPeer: null | {
    peerLevel: string | null;
    // weightCappedMin: any;
    weightCappedMax: number | null;
    weightCappedMethod: any;
  };
  weightCappingSecurity: {
    weightCappedMax: number | null;
    weightCappedMin: number | null;
  };
};
export const initAllocationState = {
  weightInCashMin: null,
  weightInCashMax: null,
  weightCappingPeer: null,
  weightCappingSecurity: {
    weightCappedMax: null,
    weightCappedMin: null,
  },
};
type AllocationActionType = {
  payload: any;
  type:
    | "SET_STATE"
    | "SET_WEIGHT_IN_CASH_MIN"
    | "DISABLE_MIN_CAPPING"
    | "ENABLE_MIN_CAPPING"
    | "DISABLE_MAX_CAPPING"
    | "ENABLE_MAX_CAPPING"
    | "SET_CAPPING_SECURITY_MAX"
    | "SET_CAPPING_SECURITY_MIN"
    | "INIT_CAPPING_PEER"
    | "SET_CAPPING_PEER_TO_NULL"
    | "SET_CAPPING_PEER_PEERLEVEL"
    | "SET_CAPPING_PEER_CAPPEDMETHOD"
    | "SET_CAPPING_PEER_MIN"
    | "SET_WEIGHT_IN_CASH_MAX"
    | "SET_CAPPING_PEER_MAX";
};
export function AllocationReducer(
  draft: AllocationType,
  action: AllocationActionType
) {
  switch (action.type) {
    case "SET_STATE":
      return action.payload;
    case "SET_WEIGHT_IN_CASH_MIN":
      draft.weightInCashMin = action.payload;
      break;
    case "SET_WEIGHT_IN_CASH_MAX":
      draft.weightInCashMax = action.payload;
      break;
    case "DISABLE_MIN_CAPPING":
      if (
        draft.weightCappingSecurity != null &&
        "weightCappedMin" in draft.weightCappingSecurity
      ) {
        draft.weightCappingSecurity.weightCappedMin = null;
      } else {
        draft.weightCappingSecurity = {
          weightCappedMax: null,
          weightCappedMin: null,
        };
      }
      break;
    case "DISABLE_MAX_CAPPING":
      if (
        draft.weightCappingSecurity != null &&
        "weightCappedMax" in draft.weightCappingSecurity
      ) {
        draft.weightCappingSecurity.weightCappedMax = null;
      } else {
        draft.weightCappingSecurity = {
          weightCappedMax: null,
          weightCappedMin: null,
        };
      }
      break;
    case "ENABLE_MIN_CAPPING":
      if (
        draft.weightCappingSecurity != null &&
        "weightCappedMin" in draft.weightCappingSecurity
      ) {
        draft.weightCappingSecurity.weightCappedMin = 0.01;
      } else {
        draft.weightCappingSecurity = {
          weightCappedMax: null,
          weightCappedMin: 0.01,
        };
      }
      break;
    case "ENABLE_MAX_CAPPING":
      if (
        draft.weightCappingSecurity != null &&
        "weightCappedMax" in draft.weightCappingSecurity
      ) {
        draft.weightCappingSecurity.weightCappedMax = 0.2;
      } else {
        draft.weightCappingSecurity = {
          weightCappedMax: 0.2,
          weightCappedMin: null,
        };
      }
      break;
    case "SET_CAPPING_SECURITY_MAX":
      if (draft.weightCappingSecurity) {
        draft.weightCappingSecurity!.weightCappedMax = action.payload;
      }
      break;
    case "SET_CAPPING_SECURITY_MIN":
      if (draft.weightCappingSecurity) {
        draft.weightCappingSecurity.weightCappedMin = action.payload;
      }
      break;
    case "INIT_CAPPING_PEER":
      draft.weightCappingPeer = {
        peerLevel: action.payload.peerLevel ?? "Country",
        weightCappedMax: action.payload.weightCappedMax ?? null,
        weightCappedMethod: action.payload.weightCappedMethod ?? null,
      };
      break;
    case "SET_CAPPING_PEER_TO_NULL":
      draft.weightCappingPeer = null;
      break;
    case "SET_CAPPING_PEER_PEERLEVEL":
      if (draft.weightCappingPeer) {
        draft.weightCappingPeer!.peerLevel = action.payload;
      }
      break;
    case "SET_CAPPING_PEER_CAPPEDMETHOD":
      if (draft.weightCappingPeer) {
        draft.weightCappingPeer!.weightCappedMethod = action.payload;
      }
      break;
    case "SET_CAPPING_PEER_MAX":
      if (draft.weightCappingPeer) {
        draft.weightCappingPeer!.weightCappedMax = action.payload;
      }
      break;
    default:
      break;
  }
}
